@import "eco_variable";

.icon-pen {
  width: 40px;
  height: 40px;
  background-image: url($ic-pen);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-back {
  cursor: pointer;
  width: 20px;
  height: 18px;
  background-image: url($ic-back);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
  @media (max-width: 767px) {
    width: 16px;
    height: 14px;
  }
}

.icon-deal {
  width: 50px;
  height: 50px;
  background-image: url($ic-deal);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-happy {
  width: 50px;
  height: 50px;
  background-image: url($ic-happy);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-connected {
  width: 50px;
  height: 50px;
  background-image: url($ic-connected);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-pen {
  width: 50px;
  height: 50px;
  background-image: url($ic-pen);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-google {
  width: 24px;
  height: 24px;
  background-image: url($ic-google);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-notif {
  width: 18px;
  height: 22px;
  @media (max-width: 767px) {
    width: 28px;
    height: 26px;
  }
  background-image: url($ic-notif);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-time {
  width: 16px;
  height: 16px;
  background-image: url($ic-time);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-chat {
  width: 18px;
  height: 18px;
  background-image: url($ic-chat);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-show {
  width: 18px;
  height: 18px;
  background-image: url($ic-show);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-edit {
  width: 18px;
  height: 18px;
  background-image: url($ic-edit);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-add {
  width: 24px;
  height: 24px;
  background-image: url($ic-add);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-image {
  width: 24px;
  height: 24px;
  background-image: url($ic-image);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-swap {
  width: 16px;
  height: 16px;
  background-image: url($ic-swap);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-setting {
  width: 16px;
  height: 16px;
  background-image: url($ic-setting);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-logout {
  width: 16px;
  height: 16px;
  background-image: url($ic-logout);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}

.icon-add-o {
  width: 32px;
  height: 32px;
  background-image: url($ic-add-o);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-trash-o {
  width: 32px;
  height: 32px;
  background-image: url($ic-trash-o);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-edit-o {
  width: 32px;
  height: 32px;
  padding: 8.5px;
  border: 1.5px solid #bfbfbf;
  border-radius: 50%;
  background-image: url($ic-edit-o);
  background-repeat: no-repeat;
  background-position-x: 6px;
  background-position-y: 6px;
  background-size: 60%;
  display: inline-block;
  vertical-align: middle;
}
.icon-line-call {
  width: 24px;
  height: 24px;
  background-image: url($ic-line-call);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-line-location {
  width: 24px;
  height: 24px;
  background-image: url($ic-line-location);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-line-message {
  width: 24px;
  height: 24px;
  background-image: url($ic-line-message);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-delete-line {
  width: 20px;
  height: 20px;
  background-image: url($ic-delete-line);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-profile {
  width: 24px;
  height: 24px;
  background-image: url($ic-profile);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-public {
  width: 19px;
  height: 18px;
  background-image: url($ic-public);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
  &.off {
    background-image: url($ic-public-off);
  }
}

.icon-private {
  width: 19px;
  height: 18px;
  background-image: url($ic-public-off);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}

.icon-read {
  width: 13px;
  height: 7px;
  background-image: url($ic-read);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}

.icon-unread {
  width: 8px;
  height: 6px;
  background-image: url($ic-unread);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-send {
  width: 24px;
  height: 24px;
  background-image: url($ic-send);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-document {
  width: 16px;
  height: 16px;
  background-image: url($ic-document);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}

.icon-document-blue {
  width: 12px;
  height: 15px;
  background: url($ic-document-blue);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-image {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
}
.icon-filter {
  width: 24px;
  height: 24px;
  background-image: url($ic-filter);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-doc {
  width: 18px;
  height: 18px;
  background-image: url($ic-doc);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-clock-white {
  width: 16px;
  height: 16px;
  background-image: url($ic-clock-white);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}

.icon-page {
  width: 16px;
  height: 16px;
  background-image: url($ic-page);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}

.icon-menu {
  width: 24px;
  height: 24px;
  background-image: url($ic-menu);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}

.icon-filter2 {
  width: 18px;
  height: 17px;
  background-image: url($ic-filter2);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}

.icon-download {
  width: 24px;
  height: 24px;
  background-image: url($ic-download);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-filter3 {
  width: 20px;
  height: 20px;
  background-image: url($ic-filter3);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-switch {
  width: 14px;
  height: 16px;
  background-image: url($ic-switch);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}

.icon-info {
  width: 24px;
  height: 24px;
  background-image: url($ic-info);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-info-yellow {
  width: 32px;
  height: 32px;
  background-image: url($ic-info-yellow);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
.icon-plus {
  width: 58px;
  height: 58px;
  background-image: url($ic-plus);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
  @media (max-width: 767px) {
    width: 16px;
    height: 14px;
  }
}

.icon-attachment {
  width: 14px;
  height: 22px;
  background-image: url($ic-attachment);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.icon-download-green {
  width: 14px;
  height: 19px;
  background-image: url($ic-download-green);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.icon-edit-pencil {
  width: 24px;
  height: 24px;
  background-image: url($ic-pen-edit);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.icon-edit-pencil-blue {
  width: 20px;
  height: 20px;
  background-image: url($ic-pen-edit-blue);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.icon-show-blue {
  width: 18px;
  height: 18px;
  background-image: url($ic-eye-blue);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}

.icon-caret-right {
  width: 18px;
  height: 18px;
  background-image: url($ic-caret-right);
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  vertical-align: middle;
}

.icon-ac-link {
  width: 18px;
  height: 18px;
  background-image: url($ic-link);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}

.icon-ac-delete {
  width: 18px;
  height: 18px;
  background-image: url($ic-trash);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}

.icon-ac-edit {
  width: 18px;
  height: 18px;
  background-image: url($ic-edit);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
}
