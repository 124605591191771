.client-sidebar {
  position: fixed;
  width: 265px;
  height: 100vh;
  background-color: white;
  .sidebar-header {
    display: flex;
    align-items: center;
    height: 120px;
    padding-left: 33px;
    img {
      height: 60px;
    }
  }
  .menu-item {
    cursor: pointer;
    margin: 0px 0px 5px 0px;
    color: $grey-6;
    font-family: $robotosans;
    font-size: 14px;
    display: flex;
    align-items: center;
    height: 36px;
    &.active {
      color: $primary;
      font-weight: 700;
      background-color: $sidebar-active;
      .sidebar-icon {
        padding-left: 27px;
        border-left: 3px solid $primary;
      }
    }
    .sidebar-icon {
      padding-left: 30px;
    }
    img {
      height: 24px;
      max-width: 18px;
      margin-right: 12px;
    }
  }
}
