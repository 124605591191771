@import "eco_variable";
.auth-page {
  min-height: 100vh;
  background-color: $white;
  width: 100%;
}
.header-visitor {
  @media (max-width: 767px) {
    display: none;
  }
}

.auth-wrapper {
  background-color: $white;
  height: auto;
  padding: 0 0;
  @media (max-width: 767px) {
    padding-top: 70px;
    width: 100%;
    max-width: 100% !important;
  }
  .register-step {
    margin: 65px 0px 10px 0px;
    font-size: 16px;
    font-weight: 600;
    font-family: $opensans;
    padding-bottom: 20px;
    border-bottom: 1px solid $border-card;
    width: 100%;
    .step {
      cursor: default;
      width: 50%;
      display: flex;
      align-items: center;
      .number {
        margin-right: 16px;
        background-color: $grey-light;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
      }
      .text {
        width: calc(100% - 40px);
      }
      &.active {
        .text {
          color: $title-color;
        }
        .number {
          background-color: $primary;
          span {
            color: #ffffff;
          }
        }
      }
    }
  }
  .p-grid {
    max-width: 1280px !important;
    margin: 0 auto !important;
    @media (max-width: 1281px) {
      max-width: 1024px !important;
    }
  }
  .benefit {
    @media (max-width: 767px) {
      display: none;
    }
  }
  .form {
    @media (max-width: 767px) {
      width: 100vw;
      max-width: 100% !important;
    }
  }
  .image {
    @media (max-width: 767px) {
      display: none;
    }
  }
  .iso-login {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 1300px) {
      max-width: 50vw;
    }
    @media (max-width: 767px) {
      display: none !important;
    }
    img {
      max-width: 100%;
    }
  }
  &.login {
    height: calc(100vh - 70px);
    @media (max-width: 767px) {
      .container {
        background-color: white;
      }
    }
  }
  .auth-content {
    @media (max-width: 767px) {
      display: none;
    }
    height: calc(100vh - 70px);
    position: relative;
    &.left {
      background-color: $white;
      &::after {
        content: "";
        width: 1000px;
        height: 100%;
        background-color: #ffffff;
        position: absolute;
        top: 0;
        left: -1000px;
      }
    }
    .box-content {
      position: relative;
      z-index: 1;
      padding-top: 40px;
      padding-right: 40px;
      h1 {
        font-size: 32px;
        font-weight: 600;
        color: $title-color;
        margin-bottom: 20px;
      }
      p {
        font-size: 16px;
        color: $title-color;
      }
      ul {
        list-style: none;
        padding-left: 0;
        margin-top: 50px;
        li {
          position: relative;
          color: $title-color;
          font-size: 14px;
          font-weight: 600;
          padding-left: 70px;
          margin-bottom: 50px;
          span {
            position: absolute;
            top: -5px;
            left: 0;
          }
        }
      }
    }
  }
}
.auth-form {
  padding-top: 40px;
  background-color: #ffffff !important;
  @media (max-width: 767px) {
    width: 100%;
    max-width: 100% !important;
    background-color: #ffffff !important;
    height: 100% !important;
  }
  &.select-role {
    min-height: calc(100vh - 85px);
    padding-top: 0px;
    .p-button {
      width: 194px;
    }
  }
  &.auth-register {
    padding-top: 0px;
  }
  .role-card {
    display: flex;
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      h4 {
        font-size: 14px !important;
      }
      img {
        height: 79px;
        width: 79px;
      }
      .p-button {
        width: 100%;
      }
      .role-card-image {
        margin: 0px 0px 13px 0px !important;
      }
    }
    padding: 34px;
    border: 1px solid $border-card;
    margin-bottom: 30px;
    border-radius: 12px;
    .role-card-image {
      height: 121px;
      width: 121px;
      min-width: 121px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: $grey-3;
      margin-right: 20px;
    }
    img {
      height: 82px;
      width: 82px;
    }
    h4 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 30px;
      font-family: $opensans;
    }
  }

  .form-reg {
    width: 100%;
    max-width: 368px;
    @media (max-width: 767px) {
      width: 100% !important;
      max-width: 100% !important;
      background-color: #ffffff !important;
    }
    .loading {
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 36px !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &.register {
      @media (max-width: 767px) {
        width: 100% !important;
      }
      img {
        height: 70px;
      }
      .p-button {
        img {
          height: 40px;
        }
      }
      .logo {
        @media (max-width: 767px) {
          display: none;
        }
      }
    }
    .iris-logo {
      @media (min-width: 768px) {
        display: none;
      }
      img {
        height: 50px;
      }
    }
    .client-login-header {
      &.reset-password {
        display: flex;
        flex-direction: column-reverse;
        h2 {
          @media (max-width: 767px) {
            display: block;
          }
        }
      }
    }
    img {
      margin-bottom: 10px;
    }
    .logo {
      img {
        height: 70px;
      }
    }
    h2 {
      color: $title-color;
      font-size: 32px;
      line-height: 43px;
      font-weight: 600;
      margin-bottom: 20px;
      @media (max-width: 767px) {
        text-align: center;
        font-size: 24px;
        margin-bottom: 0;
        margin-top: 20px;
      }
    }
    p {
      color: $grey-1;
      padding: 0 30px;
      &.note-auth {
        padding: 0;
        color: #808080;
      }
    }

    .p-field {
      margin-top: 120px;
      &.otp-code {
        @media (max-width: 767px) {
          background-color: white;
        }
        .otp-wrapp {
          display: flex;
          justify-content: space-between;
          margin-bottom: 6px;

          .p-inputtext {
            border-radius: 0 !important;
            border-top: none;
            border-left: none;
            border-right: none;
            border-width: 2px;
            width: 39px;
            height: 58px;
            font-size: 48px;
            font-weight: 600;
            padding: 8px 0 !important;
            margin: 0 2.5px 5px;
            line-height: 58px;
            &:focus {
              outline: 0 none !important;
              box-shadow: none;
            }
            @media (max-width: 767px) {
              font-size: 24px !important;
              width: 5vw !important;
            }
          }
        }
      }
    }
    .form-box {
      .p-field {
        margin-top: 0;
      }
    }
    .p-button {
      width: 100%;
      text-transform: uppercase;
      margin-top: 12px;
    }
    .footer-form {
      border-top: 1px solid $border-card;
      padding: 20px 0;
      @media (max-width: 767px) {
        background-color: white;
      }
    }
    .text-separator {
      position: relative;
      padding: 10px 0;
      margin: 20px 0;
      text-align: center;
      &::before {
        content: "";
        width: 100%;
        height: 1px;
        background-color: $border-card;
        position: absolute;
        top: 50%;
        left: 0;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        transform: translateY(-50%);
      }
      span {
        display: inline-block;
        font-size: 16px;
        font-weight: 600;
        line-height: 21px;
        text-transform: uppercase;
        color: $grey-light;
        position: relative;
        padding: 0 5px;
        background-color: $white;
        z-index: 1;
      }
    }
    .footer-form {
      border-top: 1px solid $border-card;
      padding-top: 15px;
      margin-top: 30px;
      text-align: center;
    }
  }
}
.auth-admin {
  .auth-admin-content {
    display: flex;
    padding: 10% 0;
    background-color: #e5e5e5;
    height: calc(100vh - 102px);
    @media (max-width: 1600px) {
      padding: 7% 0;
    }
    .content-wrapp {
      width: 50%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      .login-form {
        border-radius: 10px;
        background-color: $white;
        padding: 50px 40px 45px 40px;
        width: 100%;
        max-width: 470px;
        .card {
          border: none;
          border-radius: 0;
          label {
            display: none;
          }
          .p-field {
            margin-bottom: 24px;
          }
        }
        h2 {
          font-size: 24px;
          line-height: 28px;
          font-weight: 500;
          color: $black;
          margin-bottom: 40px;
        }
        .p-button {
          width: 100%;
          margin-top: 40px;
          text-transform: uppercase;
        }
        > p {
          text-align: center;
          margin-top: 40px;
          color: $grey-2;
          > a {
            color: $primary !important;
            font-weight: 700;
          }
          &.text-note {
            text-align: left;
            margin-bottom: 50px;
          }
        }
        .counter {
          text-align: center;
          display: block;
          font-size: 14px;
          line-height: 16px;
          font-weight: 700;
          color: $grey-2;
        }
      }
      .form-group {
        label {
          font-weight: 500;
          color: $black;
        }
        .form-control {
          background-color: #eef1f5;
          height: 70px;
          border-color: #eef1f5;
          &.is-invalid {
            background-color: #ffeded;
            border-color: #ffeded;
            color: #ea4946;
          }
        }
        .invalid-feedback {
          color: #898989;
          text-align: right;
        }
      }
      .p-button {
        height: 50px;
        padding-left: 30px;
        padding-right: 30px;
        width: 40% !important;
        justify-content: center !important;
        &.p-button-outlined {
          > span {
            display: flex !important;
            justify-content: center;
          }
        }
        > span {
          display: none;
        }
      }
    }
  }
}
.button-tab-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 0 30px;
  .tab-auth {
    width: 50%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid transparent;
    background-color: transparent;
    color: $primary;
    font-size: 16px;
    font-weight: 600;
    &.active {
      border-bottom: 2px solid $primary;
    }
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
}

.forgot-text {
  width: 100%;
  margin: 0 0 10px;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 500;
}
.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
  top: 0 !important;
  font-size: 14px !important;
  color: #2d2d2d;
  background-color: #ffffff;
  padding: 0 5px !important;
  font-weight: 400;
}
.p-inputtext:enabled:focus {
  box-shadow: none !important;
}

.company-name {
  .p-autocomplete-items {
    padding: 0 !important;
    overflow: hidden;
    .p-autocomplete-item {
      > p {
        font-size: 14px;
        margin-bottom: 0;
        padding: 0;
        color: #2d2d2d;
      }
    }
  }
}
.business-dropdown {
  .p-dropdown {
    width: 100% !important;
    max-width: unset !important;
    height: 50px;
  }
}
.dropdown-full {
  .p-dropdown {
    max-width: unset !important;
  }
}

.client-login-header {
  .iris-logo {
    @media (max-width: 500px) {
      display: none;
      img {
        display: none;
      }
    }
  }
}

.register-title {
  img {
    @media (max-width: 500px) {
      display: none;
    }
  }
  h2 {
    @media (max-width: 500px) {
      margin-top: 20px;
      margin-bottom: 0 !important;
    }
  }
}
