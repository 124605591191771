//
// Topbar
//

.topbar {
  display: flex;
  align-items: stretch;
  padding: 0;
  @media (max-width: 767px) {
    padding: 0px 0px 0px 15px !important;
    max-width: 100vw;
  }
  // Topbar Item
  .topbar-item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .topbar-hr {
      content: "";
      height: 30px;
      width: 2px;
      background-color: #dee2e6;
      margin: 0 8px;
    }
    &.client {
      @media (max-width: 767px) {
        width: 100%;
        .mobile-nav {
          display: flex !important;
        }
        h4,
        .icon-menu,
        .icon-back {
          display: block !important;
        }
        h4 {
          margin-bottom: 0px;
        }
      }
      h4,
      .icon-menu,
      .icon-back {
        display: none;
      }
    }
    .btn {
      span {
        color: $title-color;
        font-size: 14px;
      }
      .symbol {
        .symbol-label {
          border-radius: 100%;
          overflow: hidden;
          img {
            max-width: 100%;
          }
        }
      }
    }
    .dropdown {
      position: relative;
      .btn.btn-success:not(:disabled):not(.disabled):active:not(.btn-text),
      .btn.btn-success:not(:disabled):not(.disabled).active,
      .show > .btn.btn-success.dropdown-toggle,
      .show .btn.btn-success.btn-dropdown {
        background-color: transparent;
        border-color: transparent;
      }
      .dropdown-toggle {
        background-color: transparent;
        border-color: transparent;
        position: relative;
        &:hover {
          background-color: transparent !important;
          border-color: transparent !important;
        }
      }
      &.has-noty {
        &::before {
          content: "";
          width: 6px;
          height: 6px;
          border-radius: 100%;
          background-color: $danger;
          position: absolute;
          top: 8px;
          right: 21px;
          z-index: 1;
          @media (max-width: 767px) {
            top: 12px;
            right: 15px;
          }
        }
      }
      .dropdown-toggle.nav-link:after,
      .dropdown-toggle.btn:after {
        display: none;
      }
      .dropdown-menu {
        min-width: inherit;
        width: 241px;
        // left: -200px !important;
        .title-menu {
          padding: 10px;
          font-size: 12px;
        }
        .dropdown-item {
          white-space: inherit;
          &.active {
            background-color: $primary-rgba;
          }
          .notif-box {
            .date {
              color: $grey-6;
              margin-bottom: 10px;
              font-size: 12px;
              display: block;
            }
            .title-box {
              font-size: 12px;
              font-weight: 600;
              color: $title-color;
              display: block;
            }
            .desc {
              color: $title-color;
              font-size: 12px;
            }
          }
        }
        &.dropdown-noty {
          height: 320px;
          overflow-y: auto;
          position: absolute;
          // left: 0px !important;
          @media (max-width: 767px) {
            transform: translate3d(-201px, 45px, 0px) !important;
          }
          .empty-notification {
            height: 230px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            img {
              height: 100px;
              margin-bottom: 15px;
            }
            span {
              margin-left: 10px !important;
            }
          }
        }
      }
      &.user {
        position: relative;
        .dropdown-toggle {
          width: fit-content;
          text-align: center;
          position: relative;
          padding-right: 10px;
          padding-left: 10px;
          max-width: 200px;
        }
        @media (max-width: 767px) {
          display: none;
        }
        .dropdown-toggle {
          span {
            color: $grey-6;
          }
        }
        .dropdown-menu {
          // left: -80px !important;
          right: 0 !important;
          .dropdown-item {
            color: $grey-6;
            svg {
              vertical-align: middle;
              margin-right: 8px;
              g {
                [fill] {
                  fill: $grey-6;
                }
              }
            }
          }
        }
      }
      &.notification {
        width: 40px;
        position: relative;
        text-align: center;
        .dropdown-menu {
          right: 0 !important;
        }
      }
    }
  }

  // Topbar Dropdown
  .dropdown {
    display: flex;
    align-items: stretch;
  }

  .logout {
    margin-left: -23px;
  }
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
  .topbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: all 0.3s ease;
    justify-content: flex-end;
    padding: 0 get($page-padding, tablet-and-mobile);
    height: get($header-topbar-config, tablet-and-mobile, height);
    background-color: get($header-topbar-config, tablet-and-mobile, bg-color);
    box-shadow: get($header-topbar-config, tablet-and-mobile, shadow);
    border-top: 1px solid transparent;

    // Fixed Mobile Header
    .header-mobile-fixed & {
      position: fixed;
      z-index: get($header-config, tablet-and-mobile, fixed, zindex) - 1;
    }

    // Topbar Shown Mode
    .topbar-mobile-on & {
      z-index: 2;
      transition: all 0.3s ease;
      border-top: 1px solid
        get($header-topbar-config, tablet-and-mobile, border-color);
      margin-top: get($header-config, tablet-and-mobile, default, height);
    }

    // Fixed Mobile Header & Topbar Shown Modes
    .topbar-mobile-on.header-mobile-fixed & {
      z-index: get($header-config, tablet-and-mobile, fixed, zindex) - 1;
      margin-top: get($header-config, tablet-and-mobile, fixed, height);
    }
  }
}

// Mobile View
@include media-breakpoint-down(xs) {
  .topbar {
    .topbar-item {
      .btn.btn-icon {
        height: 35px;
        width: 35px;
      }
    }
  }
}
