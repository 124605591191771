@import "eco_variable";
.p-field {
  margin-bottom: 20px !important;
  label {
    &.dark {
      font-size: 16px;
      color: $title-color;
    }
  }
}
.p-inputtext {
  border-radius: 10px !important;
  border-color: $grey-1;
  width: 100%;
  height: 50px;
  font-size: 14px !important;
  color: #2d2d2d !important;
  font-weight: 500 !important;
  @media (max-width: 500px) {
    height: 40px;
    font-size: 12px !important;
  }
  &.p-rounded {
    border-radius: 100%;
  }
  &:focus {
    outline: 0 none !important;
    box-shadow: none;
  }
  &.p-inputtextarea {
    height: auto;
  }
  &.half {
    max-width: 419px;
  }
  &.sm-radius {
    border-radius: 6px !important;
  }
  &.disabled {
    background-color: $border-card;
    border-color: $border-card;
    color: $grey-6 !important;
  }
  &:read-only {
    background-color: #f4f4f4 !important;
  }
}
.p-input-icon-left > i,
.p-input-icon-right > i {
  z-index: 1;
}
.p-checkbox {
  .p-checkbox-box {
    &.p-checkbox-box.p-highlight {
      background-color: $primary;
      border-color: $primary;
      &:hover {
        background-color: $primary !important;
        border-color: $primary !important;
      }
    }
  }
  &:not(.p-checkbox-disabled) {
    .p-checkbox-box {
      &.p-highlight {
        &:hover {
          background-color: $primary !important;
          border-color: $primary !important;
        }
      }
    }
  }
  .p-field-checkbox {
    label {
      margin-top: 33px !important;
    }
  }
}
.css-1okebmr-indicatorSeparator {
  display: none;
}
//
// Dropzone
//

// Base
.dropzone {
  min-height: auto;
}

.dropzone.dropzone-default {
  padding: 20px;
  text-align: center;
  cursor: pointer;
  border: 2px dashed $gray-200;
  @include border-radius($border-radius);

  .dropzone-msg-title {
    color: $dark-75;
    margin: 0 0 5px 0;
    padding: 0;
    font-weight: 500;
    font-size: 1.2rem;
  }

  .dropzone-msg-desc {
    color: $text-muted;
    font-weight: 400;
    font-size: 1rem;
  }

  .dz-preview {
    .dz-image {
      @include border-radius($border-radius);
    }
  }

  .dz-remove {
    color: $dark-50;
    font-size: 1px;
    font-weight: 500;
    transition: $transition-link;

    &:hover {
      transition: $transition-link;
      color: $primary;
    }
  }

  // State colors
  @each $name, $color in $theme-colors {
    &.dropzone-#{$name} {
      border-color: $color;
    }
  }
}

// Multipe upload
.dropzone.dropzone-multi {
  border: 0;
  padding: 0;

  .dz-message {
    display: none;
  }

  .dropzone-panel {
    .dropzone-upload,
    .dropzone-remove-all {
      display: none;
    }
  }

  .dropzone-item {
    display: flex;
    align-items: center;
    margin-top: 0.75rem;
    @include border-radius($border-radius);
    padding: 0.5rem 1rem;
    background-color: $gray-100;

    .dropzone-file {
      flex-grow: 1;

      .dropzone-filename {
        font-size: 0.9rem;
        font-weight: 500;
        color: $gray-600;
        text-overflow: ellipsis;
        margin-right: 0.5rem;

        b {
          font-size: 0.9rem;
          font-weight: 500;
          color: $text-muted;
        }
      }

      .dropzone-error {
        margin-top: 0.25rem;
        font-size: 0.9rem;
        font-weight: 400;
        color: $danger;
        text-overflow: ellipsis;
      }
    }

    .dropzone-progress {
      width: 15%;

      .progress {
        height: 5px;
        @include transition;
      }
    }

    .dropzone-toolbar {
      margin-left: 1rem;
      display: flex;
      flex-wrap: nowrap;

      .dropzone-start,
      .dropzone-cancel,
      .dropzone-delete {
        height: 25px;
        width: 25px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: $transition-link;

        i {
          transition: $transition-link;
          font-size: 0.8rem;
          color: $gray-600;
        }

        &:hover {
          transition: $transition-link;
          i {
            color: $primary;
          }
        }
      }

      .dropzone-start {
        transition: $transition-link;
      }
    }
  }
}

.p-calendar {
  max-width: 419px;
  display: block !important;
  position: relative;
  .p-inputtext {
    width: 100% !important;
  }
  .p-button {
    position: absolute;
    top: 6px;
    right: 0;
    background-color: transparent !important;
    border: none !important;
    &:hover,
    &:focus {
      background-color: transparent !important;
      border: none !important;
      outline: 0 none !important;
    }
    .pi {
      color: $gray-600 !important;
      font-size: 18px;
    }
  }
}
.file-upload {
  border-radius: 10px !important;
  border: 1px solid $grey-1;
  width: 100%;
  max-width: 419px;
  height: 50px;
  display: block;
  padding-right: 90px;
  position: relative;
  &.md {
    height: 40px;
  }
  .file-name {
    color: #495057;
    font-weight: 400;
    width: 100%;
    height: 40px;
    padding: 10px 0px 10px 10px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 6px;
    &.clickabel {
      color: $primary;
      cursor: pointer;
      font-weight: 500;
    }
  }
  .file-button {
    cursor: pointer;
    width: 112px;
    height: 40px;
    display: block;
    padding: 10px;
    text-align: center;
    position: absolute;
    top: 6px;
    right: 0;
    color: $primary;
    font-weight: 600;
  }
}

// Remove increment indicator in input type number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// end of remove increment

.portfolio-list {
  .count-folio {
    font-weight: 500;
    color: $title-color;
  }
  .folio-name {
    font-weight: 600;
    color: $title-color;
  }
  .folio-year {
    font-weight: 400;
    color: $grey-6;
  }
  .show-brief {
    font-weight: 600;
  }
  .folio-box {
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 15px;
    &:last-child {
      border-bottom: unset;
      margin-bottom: 0px;
    }
  }
}

.text-input-info {
  font-size: 12px;
  color: #808080;
  font-weight: 500;
}
