// replace font family
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap");
$opensans: "Open Sans", sans-serif;
$robotosans: "Roboto", sans-serif;
$montserrat: "Montserrat", sans-serif;
$font-family-base: $robotosans;

// replace color
$white: #ffffff;
$primary-color: #0099ff;
$primary-dark: #006bb3;
$primary: #0099ff;
$primary-1: #ddfce6;
$primary-2: #e8fcf4;
$primary-3: #b6edc7;
$primary-light: #e5f5ff;
$secondary: #1f7351;
$danger: #ea4946;
$warning: #e39e39;
$black: #000000;
$info: #418bc4;
$info-1: #d7edff;
$title-color: #2d2d2d;
$grey-light: #bfbfbf;
$border-card: #eaeaea;
$action-table: #f3f6f9;
$sidebar-active: #d9f0fe;

$desc-color: #3d3d3d;
$dark-blue-color: #006bb3;
$msg-bg: #cfe6f6;

// replce color btn
$btn-secondary-color: $white;
$status-new: #0ccae8;
$status-new-02: rgba(12, 202, 232, 0.2);
$status-new-03: #cef4fa;
$status-joined: #f2f2f2;
$status-rejected: #f6cdd4;

$grey-1: #c4c4c4;
$grey-2: #979797;
$grey-3: #f4f4f4;
$grey-4: #e5eaee;
$grey-5: #898989;
$grey-6: #808080;
$grey-7: #d4d4d4;

$bg-footer: #002036;
$bg-footer2: #093553;
$bg-auth: #fafafa;

$buttonBg: $primary;
$bg-login: #9cd6bf;

$primary-rgba: rgba(0, 153, 255, 0.1);
$info-rgba: rgba(0, 153, 255, 0.1);
$sent-rgba: rgba(191, 191, 191, 0.2);
$rev-rgba: rgba(251, 10, 42, 0.1);
$load-rgba: rgba(0, 0, 0, 0.15);
$text-rev: #fb0a2a;

// status color
$waiting: #fc8f00;
$bg-waiting: rgba(253, 200, 0, 0.2);
$rejected: #fb0a2a;
$bg-rejected: rgba(251, 10, 42, 0.2);
$verified: #17ad49;
$bg-verified: rgba(23, 173, 73, 0.2);

//icons
$ic-phone: "../icons/ic-phone.png";
$ic-email: "../icons/ic-mail.png";
$ic-instagram: "../icons/ic-instagram.png";
$ic-whatsapp: "../icons/ic-whatsapp.png";
$ic-chat: "../icons/ic-chat.png";
$ic-file: "../icons/ic-file.png";
$ic-cart: "../icons/ic-cart.png";
$ic-file-o: "../icons/ic-file-o.png";
$ic-close: "../icons/ic-close.png";
$ic-restored: "../icons/ic-restored.png";
$ic-delete: "../icons/ic-delete.png";
$ic-check-read: "../icons/ic-check-read.png";
$ic-emoticon: "../icons/ic-emoticon.png";
$ic-send: "../icons/ic-send.png";
$ic-filter: "../icons/ic-filter.png";
$ic-sort: "../icons/ic-sort.png";
$ic-priority: "../icons/icons-priority.png";
$ic-priority-grey: "../icons/grey-icons-priority.png";
$ic-arrow-back: "../icons/ic-arrow-back.png";
$ic-arrow-step: "../icons/arrow-step.png";
$ic-bx-plus: "../icons/ic-bx-plus.png";
$ic-close-lg: "../icons/ic-close-lg.png";
$ic-step-arrow: "../icons/arrow-step.png";
$ic-delete-line: "../icons/ic-delete-line.svg";
$ic-doc: "../icons/icon-doc.svg";
$bg-iso-blue: "../../../app/assets/img/iso-blue.svg";
$bg-iso-green: "../../../app/assets/img/iso-green.svg";
$bg-iso-yellow: "../../../app/assets/img/iso-yellow.svg";
$bg-iso-cyan: "../../../app/assets/img/iso-cyan.svg";
$iso-rocket: "../../../app/assets/img/iso-rocket.svg";
$ic-image: "../../../app/assets/img/ic-image.svg";
$ic-add-o: "../../../app/assets/img/add-o.svg";
$ic-filter2: "../../../app/assets/img/ic-filter.svg";
$ic-filter3: "../../../app/assets/img/icon-filter2.svg";
$ic-switch: "../../../app/assets/img/icon-switch.svg";

$iso-login: "../icons/iso-login.svg";
$notif-ballon: "../images/notif-ballon.png";
$ic-pen: "../../../app/assets/img/ic_pen-fancy.svg";
$ic-back: "../../../app/assets/img/arrow-back.svg";
$ic-deal: "../../../app/assets/img/deal.svg";
$ic-happy: "../../../app/assets/img/happy.svg";
$ic-connected: "../../../app/assets/img/connected.svg";
$ic-pen: "../../../app/assets/img/pen.svg";
$ic-google: "../../../app/assets/img/icon_google.svg";
$ic-notif: "../../../app/assets/img/notification.svg";
$ic-time: "../../../app/assets/img/time-slot.svg";
$ic-chat: "../../../app/assets/img/icon-chat.svg";
$ic-show: "../../../app/assets/img/icon-show.svg";
$ic-edit: "../../../app/assets/img/icon-edit.svg";
$ic-add: "../../../app/assets/img/icon-add.svg";
$ic-logout: "../../../app/assets/img/Logout.svg";
$ic-setting: "../../../app/assets/img/Setting.svg";
$ic-swap: "../../../app/assets/img/Swap.svg";
$ic-trash-o: "../../../app/assets/img/ic-trash-o.svg";
$ic-edit-o: "../../../app/assets/img/ic-edit-o.svg";
$ic-line-call: "../../../app/assets/img/ic-line-call.svg";
$ic-line-location: "../../../app/assets/img/ic-line-location.svg";
$ic-line-message: "../../../app/assets/img/ic-line-message.svg";
$ic-profile: "../../../app/assets/img/icon-profile.svg";
$ic-public: "../../../app/assets/img/icon-public.svg";
$ic-public-off: "../../../app/assets/img/icon-public-off.svg";
$ic-read: "../../../app/assets/img/icon-read.svg";
$ic-unread: "../../../app/assets/img/icon-unread.svg";
$ic-send: "../../../app/assets/img/icon-send.svg";
$ic-document: "../../../app/assets/img/icon-document.svg";
$ic-document-blue: "../../../app/assets/img/Document.svg";
$ic-image: "../../../app/assets/img/icon-image.svg";
$ic-filter: "../../../app/assets/img/icon-filter.svg";
$ic-clock-white: "../../../app/assets/img/clock-white.svg";
$ic-page: "../../../app/assets/img/icon-page.svg";
$ic-menu: "../../../app/assets/img/icon-menu.svg";
$ic-download: "../../../app/assets/img/download-blue.svg";
$ic-info: "../../../app/assets/img/icon-info.svg";
$ic-info-yellow: "../../../app/assets/img/icon-info-yellow.svg";
$ic-plus: "../../../app/assets/img/icon-plus.svg";
$ic-attachment: "../../../app/assets/img/icon-attachment.svg";
$ic-download-green: "../../../app/assets/img/icon-download.svg";
$ic-pen-edit: "../../../app/assets/img/pencil-grey-icon.svg";
$ic-pen-edit-blue: "../../../app/assets/img/ic-pencil-blue.svg";
$ic-eye-blue: "../../../app/assets/img/ic-eye-blue.svg";
$ic-caret-right: "../../../app/assets/img/icon_caret_right.svg";
$ic-link: "../../../app/assets/img/link-icon.svg";
$ic-trash: "../../../app/assets/img/trash.svg";

$shadow-1: 0px 2px 8px 2px rgba(0, 0, 0, 0.1);
$shadow-2: 0px 4px 8px rgba(0, 0, 0, 0.1);
$shadow-3: 0px 3px 5px -1px rgba(82, 82, 82, 0.73);
$shadow-4: 0px 4px 8px rgba(0, 0, 0, 0.15);
$shadow-5: 8px 4px 8px 0px rgba(0, 0, 0, 0.1);
$shadow-6: 0 10px 8px -8px rgba(0, 0, 0, 0.1);
