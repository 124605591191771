@import "eco_variable";

.header-auth {
  background-color: $white;
  padding: 23px 0;
  .back {
    font-size: 16px;
    color: $title-color;
    p {
      font-size: 16px;
      @media (max-width: 767px) {
        display: none;
      }
    }
  }
  @media (max-width: 767px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  &.reset-password {
    min-height: 70px;
  }
  &.select-role,
  &.visitor {
    @media (min-width: 768px) {
      display: none;
    }
    @media (max-width: 768px) {
      padding: 0px;
    }
  }
  .p-grid {
    max-width: 1280px !important;
    margin: 0 auto !important;
    @media (max-width: 1281px) {
      max-width: 1024px !important;
    }
  }
}

.menu-auth {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 1;
  .card {
    .p-menubar {
      background-color: $white;
      height: 70px;
      padding: 0 70px;
      .p-menubar-start {
        img {
          height: 55px;
        }
      }
    }
    .p-menubar-root-list {
      margin-left: auto;
      .p-menuitem {
        .p-menuitem-link {
          color: $primary;
          font-size: 20px;
          .p-menuitem-text {
            color: $primary;
          }
        }
      }
    }
  }
}

.menu-mobile {
  position: relative;
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
  button {
    height: 30px;
    width: 40px;
    font-size: 24px;
  }
  &.landing {
    .menu-list-mobile {
      top: 50px;
      padding-top: 10px;
    }
  }
  .menu-list-mobile {
    width: 100%;
    position: fixed;
    height: 100vh;
    top: 80px;
    left: 0;
    background-color: #fff;
    flex-direction: column;
    padding: 20px 0;
    border-bottom: 1px solid #f4f4f4;
    z-index: 30;
    &.client {
      padding: 0px;
      top: 50px;
    }
    &.show {
      display: flex;
    }
    &.hide {
      display: none;
    }
    > a {
      display: block;
      font-size: 14px;
      padding: 10px 20px;
      width: 100%;
      font-weight: 600;
      span {
        color: #808080;
        &.active {
          color: #0099ff;
        }
      }
    }
  }
}
