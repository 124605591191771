a.button-style,
.button-style {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 170px;
  width: fit-content;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 10px !important;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  border: 1px solid #007bff;
  background-color: #007bff;
  @media (max-width: 500px) {
    height: 38px;
    font-size: 12px;
    min-width: 100px;
  }
  &:hover,
  &:active,
  &:focus {
    color: #fff;
    background-color: #0261c7;
    border-color: #0261c7;
  }
  &.fit {
    min-width: unset;
    max-height: 40px;
    height: auto;
    width: fit-content;
    padding: 5px 15px;
  }
  &.button-table {
    height: 35px !important;
  }
  &:disabled {
    opacity: 0.6;
  }
  &.rounded-bordered {
    border-radius: 25px !important;
    background-color: #fff !important;
    border-color: #007bff;
    color: #007bff;
    &:hover,
    &:active,
    &:focus {
      background-color: #f4f4f4 !important;
      border-color: #007bff;
      color: #007bff !important;
    }
    &.grey {
      color: #808080;
      border-color: #808080;
      &:hover,
      &:active,
      &:focus {
        color: #808080;
        border-color: #808080;
      }
    }
    &.danger {
      color: #fb0a2a;
      border-color: #fb0a2a;
      &:hover,
      &:active,
      &:focus {
        color: #fb0a2a !important;
        border-color: #fb0a2a !important;
      }
    }
    &:hover,
    &:active,
    &:focus {
      background-color: #fff !important;
      border-color: #007bff;
      color: #007bff;
    }
  }
  &.rounded {
    border-radius: 25px !important;
  }
  &.bordered {
    background-color: #fff !important;
    color: #007bff;
    &.grey {
      color: #808080;
      border-color: #808080;
      &:hover,
      &:active,
      &:focus {
        color: #808080;
        border-color: #808080;
      }
    }
    &.danger {
      color: #fb0a2a;
      border-color: #fb0a2a;
      &:hover,
      &:active,
      &:focus {
        color: #fb0a2a;
        border-color: #fb0a2a;
      }
    }
  }
  &.grey {
    color: #808080;
    background-color: #ebedf3;
    border-color: #ebedf3;
    &:hover,
    &:active,
    &:focus {
      color: #808080;
      background-color: #ebedf3;
      border-color: #ebedf3;
    }
  }
  &.danger {
    color: #fff;
    background-color: #fb0a2a;
    border-color: #fb0a2a;
    &:hover,
    &:active,
    &:focus {
      color: #fff;
      background-color: #fb0a2a;
      border-color: #fb0a2a;
    }
  }
  &.login-user {
    width: 100%;
    height: 50px;
  }
}
.p-inputtext {
  border: 1px solid #979797;
  &:active,
  &:focus,
  &:visited,
  &:hover {
    border-color: #ced4da;
  }
  &.search-inputtext {
    width: 250px;
    height: 40px;
    margin-right: 20px;
    margin-bottom: 10px;
    color: #898989;
    border: 1px solid #979797;
    @media (max-width: 767px) {
      width: 100%;
      margin-right: 0;
    }
    &:active,
    &:focus,
    &:visited,
    &:hover {
      border-color: #ced4da !important;
    }
  }
}

.filter-field {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  @media (max-width: 767px) {
    width: 100%;
  }
  > p {
    font-size: 14px;
    > span {
      font-weight: 600;
    }
  }
}

.p-dropdown {
  height: 40px;
  width: 100%;
  .p-dropdown-label {
    &.p-placeholder,
    &.p-inputtext {
      height: 40px;
      line-height: 25px;
    }
  }
  &:active,
  &:focus,
  &:visited,
  &:hover,
  &.p-focus,
  &.p-inputwrapper-focus {
    border-color: #ced4da;
    outline: unset;
    box-shadow: unset;
  }
  &:not(.p-disabled) {
    &.p-focus {
      border-color: #ced4da;
      outline: unset;
      box-shadow: unset;
    }
  }
  &.full-width {
    max-width: unset !important;
    width: 100%;
  }
  &.date-filter {
    width: 150px;
  }
}

.button-style-dropdown {
  height: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 170px !important;
  width: fit-content;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 10px !important;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  color: #fff !important;
  border: 1px solid #007bff !important;
  background-color: #007bff !important;
  &:hover,
  &:active,
  &:focus {
    color: #fff !important;
    background-color: #0261c7 !important;
    border-color: #0261c7 !important;
  }
}

a.button-icon,
.button-icon {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px !important;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  color: #007bff;
  border: 0px solid #fff;
  background-color: #fff;
  img {
    height: 24px;
    margin-right: 8px;
  }
  &:hover,
  &:active,
  &:focus {
    color: #007bff;
    border-color: #fff;
    background-color: #fff;
  }
  &.fit {
    min-width: unset;
    height: auto;
    width: fit-content;
    padding: 5px 15px;
  }
  &:disabled {
    opacity: 0.6;
  }
  &.bordered {
    border-width: 1px !important;
  }
  &.grey {
    color: #808080;
    border-color: #808080;
    &:hover,
    &:active,
    &:focus {
      color: #808080;
      border-color: #808080;
    }
  }
  &.danger {
    color: #fb0a2a;
    border-color: #fb0a2a;
    &:hover,
    &:active,
    &:focus {
      color: #fb0a2a;
      border-color: #fb0a2a;
    }
  }
}
