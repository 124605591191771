.service-page,
.product-page {
  background-color: #f4f4f4;
  max-width: 100%;
  .service-module {
    border-radius: 10px;
    background-color: #fff;
    width: 100%;
    overflow: hidden;
    position: relative;
    .title-module {
      color: $title-color;
      font-size: 24px;
      font-weight: 600;
      border-bottom: 1px solid $grey-3;
      padding: 20px 30px;
      @media (max-width: 767px) {
        padding: 10px 25px;
      }
    }
    .body-module {
      width: 100%;
      padding: 20px 30px;
    }
    .list-services {
      width: 100%;
      border-bottom: 1px solid $grey-3;
      h3 {
        font-family: $robotosans;
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 15px;
      }
    }
    .cards-list {
      display: flex;
      flex-wrap: wrap;
      padding: 15px 0 25px;
      margin: -15px;
      width: 100%;
      @media (max-width: 767px) {
        margin: 0;
        width: 100%;
      }
      .empty-note {
        font-size: 16px;
      }
    }
    .service-cards {
      margin: 15px;
      padding: 20px;
      border: 1px solid $border-card;
      border-radius: 10px;
      width: calc(33.333% - 30px);
      max-width: 335px;
      height: 450px;
      position: relative;
      @media (max-width: 767px) {
        width: 100%;
        margin: 10px 0;
        height: auto;
        max-width: unset;
      }
      .image-cards {
        background-color: $grey-3;
        height: 250px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        overflow: hidden;
        position: relative;
        @media (max-width: 767px) {
          height: 180px;
          width: 100%;
        }
        img {
          position: absolute;
          height: 250px;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          min-width: 100%;
          @media (max-width: 767px) {
            height: 180px;
            min-width: 100%;
          }
        }
      }
      .cards-content {
        font-family: $opensans;
        .content-title {
          font-size: 16px;
          font-weight: 600;
          color: $title-color;
          margin-bottom: 5px;
        }
        .subtitle-card {
          font-size: 14px;
          color: $grey-6;
          margin-bottom: 8px;
        }
        .desc-card {
          font-size: 14px;
          font-weight: 400;
          color: $title-color;
          margin-bottom: 30px;
          overflow-wrap: break-word;
        }
        span {
          font-weight: 700;
          cursor: pointer;
        }
        button {
          display: flex;
          justify-content: center;
          border-radius: 62px !important;
          font-size: 14px;
          font-weight: 600 !important;
          width: calc(100% - 40px);
          @media (max-width: 767px) { 
            width: 100%;
          }
        }
      }
      .service-card-footer {
        position: absolute;
        bottom: 30px;
        @media (max-width: 767px) {
          position: relative;
          bottom: initial;
        }
      }
    }
    .popup-service-filter {
      &.d-none {
        display: none;
      }
      background-color: white;
      position: absolute;
      top: 302px;
      left: 309px;
      height: 247px;
      width: 234px;
      padding: 30px 20px 15px 20px;
      box-shadow: $shadow-3;
      border-radius: 6px;
      font-size: 14px;
      .MuiFormLabel-root {
        font-size: 14px;
        font-family: $opensans !important;
        font-weight: 600;
        color: $title-color;
      }
      .MuiFormControlLabel-root {
        margin-bottom: -3px;
      }
      .MuiTypography-body1 {
        font-family: $opensans !important;
      }
      button {
        width: 194px;
        border-radius: 100px;
        font-family: $robotosans;
        font-weight: 500;
        height: 35px;
        margin-top: 40px;
        box-shadow: 0 0 0 0 !important;
        border: 0px !important;
        outline: 0px !important;
      }
    }
  }
  .admin-pagination {
    margin: 0px 0px 0px 30px;
  }
}

.product-page {
  background-color: white;
  h2 {
    font-size: 28px;
    font-weight: 600;
    font-family: $opensans;
    margin: 60px 0px 30px 0px;
  }
  .service-module {
    .list-services {
      padding: 0px;
    }
  }
  .description-section {
    p {
      font-size: 14px;
      line-height: 28px;
      margin-bottom: 50px;
    }
  }
  .top-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 40px 0;
    @media (max-width: 500px) {
      padding: 60px 0 20px;
    }
    .text-section {
      width: 50%;
      h1 {
        font-size: 48px;
        font-family: $opensans;
        font-weight: 800;
        color: #2d2d2d;
        margin: 0 0 20px;
      }
      p {
        font-size: 16px;
        font-family: $opensans;
        font-weight: 400;
        color: #8d8d8d;
        margin: 0 0 40px;
        text-align: justify;
      }
      @media (max-width: 500px) {
        width: 100%;
        order: 2;
        h1 {
          font-size: 24px;
          margin: 0 0 10px;
        }
        p {
          font-size: 14px;
          margin: 0 0 25px;
        }
      }
    }
    .image-section {
      width: 50%;
      display: flex;
      justify-content: center;
      @media (max-width: 500px) {
        width: 100%;
        order: 1;
        img {
          width: 100%;
        }
      }
    }
  }
  .homepage-service-section {
    padding: 40px 0;
    width: 100%;
  }
  .homepage-service-item {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin: 0 0 40px;
    .image-service-section {
      order: 1;
      width: 40%;
      @media (max-width: 500px) {
        width: 100%;
        margin-bottom: 30px;
        img {
          max-width: 100%;
        }
      }
    }
    .text-section {
      order: 2;
      width: 55%;
      h4 {
        font-size: 24px;
        color: #2d2d2d;
        font-weight: 700;
        font-family: $opensans;
        margin-bottom: 20px;
      }
      p {
        font-size: 16px;
        color: #828282;
        font-weight: 400;
        font-family: $opensans;
        text-align: justify;
      }
      @media (max-width: 500px) {
        width: 100%;
        h4 {
          font-size: 18px;
        }
        p {
          font-size: 12px;
        }
      }
    }
    &:last-child {
      margin: 0;
    }
    &:nth-child(even) {
      .image-service-section {
        order: 2;
      }
      .text-section {
        order: 1;
      }
      @media (max-width: 500px) {
        .image-service-section {
          order: 1;
        }
        .text-section {
          order: 2;
        }
      }
    }
  }
}
.bottom-section {
  width: 100%;
  text-align: center;
  padding: 80px 0 120px;
  h2 {
    font-size: 48px;
    font-family: $opensans;
    font-weight: 800;
    color: #2d2d2d;
    margin: 0 0 40px;
  }
  > a {
    margin: 0 auto;
    height: 45px !important;
  }
  @media (max-width: 500px) {
    h2 {
      font-size: 24px;
    }
  }
}
