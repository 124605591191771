@import "eco_variable";

.card-edit-profile {
  .card-body {
    .form-edit-photo {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 40px;
    }
    h3 {
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      margin-bottom: 30px;
    }
    .form-add-project {
      width: 100%;
      .p-inputtext,
      .social-grid,
      .p-dropdown {
        max-width: 500px;
        &:disabled {
          background-color: #e8e8e8;
        }
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
      .p-field {
        > label {
          font-size: 14px;
        }
      }
    }
  }
}
