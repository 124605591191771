.client-profile-module {
  background-color: white;
  border-radius: 10px;
  min-height: calc(100vh - 185px);
  width: calc(100vw - 325px);
  @media (max-width: 767px) {
    border-radius: 0px;
    width: 100%;
  }
  .profile-title {
    @media (max-width: 767px) {
      display: none !important;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    font-family: $robotosans;
    font-weight: 700;
    font-size: 16px;
    color: $title-color;
    border-bottom: 1px solid $border-card;
    &.edit {
      justify-content: flex-start;
      align-items: center;
      span {
        position: relative;
        top: 2px;
      }
      img {
        margin-right: 31px;
      }
    }
  }
  .info-client-mobile {
    display: none;
    @media (max-width: 767px) {
      display: block;
      border-bottom: 1px solid $border-card;
      padding: 20px 28px;
      .info-item {
        margin-bottom: 12px;
        img {
          margin-right: 12px;
        }
      }
    }
  }
  .info-client {
    .change-photo {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .profile-info {
    display: flex;
    padding: 30px;
    border-bottom: 1px solid $border-card;
    @media (max-width: 767px) {
      padding: 30px 16px;
    }
    .icon-edit-o {
      @media (min-width: 768px) {
        display: none;
      }
    }
    .photo-profile {
      width: 156px;
      height: 156px;
      border-radius: 50%;
      border: 1px solid $grey-1;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 33px;
      img {
        height: 156px;
        width: 156px;
        border-radius: 50%;
        object-fit: cover;
      }
      aside {
        margin-top: 0px !important;
        @media (max-width: 767px) {
          margin-top: 0px !important;
        }
      }
      .image-thumb {
        width: 156px;
        height: 156px;
        img {
          height: 156px;
          width: 156px;
          border-radius: 50%;
          object-fit: cover;
          position: relative;
          left: 0;
          min-width: unset;
          transform: translateX(0);
        }
        @media (max-width: 767px) {
          width: 123px;
          height: 123px;
          img {
            width: 123px;
            height: 123px;
            max-width: unset;
            max-height: unset;
          }
        }
        &.client-profile {
          -webkit-transform: scale(0.66);
          -moz-transform: scale(0.66);
          transform: scale(0.66);
        }
      }
      .photo-profile-warp {
        border: 0px;
        .client-thumb {
          @media (max-width: 768px) {
            height: 80px;
            width: 80px;
          }
        }
      }
      @media (max-width: 767px) {
        height: 80px;
        width: 80px;
        margin: 0 auto 15px;
        img {
          object-fit: cover;
          max-height: 80px;
          max-width: 80px;
        }
      }
    }
    .delete-photo {
      position: absolute;
      left: 178px;
      top: 375px;
      width: 32px;
      height: 32px;
      box-shadow: $shadow-3;
      border-radius: 50%;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .name {
      font-family: $robotosans;
      font-weight: 700;
      font-size: 16px;
      color: $title-color;
      margin-bottom: 8px;
      @media (max-width: 767px) {
        width: 100%;
        text-align: center;
        margin-bottom: 4px;
      }
    }
    .position {
      font-family: $robotosans;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 20px;
      @media (max-width: 767px) {
        width: 100%;
        text-align: center;
      }
    }
    .info-item {
      margin-bottom: 21px;
      font-size: 14px;
      @media (max-width: 767px) {
        margin-bottom: 10px;
      }
      img {
        margin-right: 13px;
      }
    }
    &.edit {
      align-items: center;
      h4 {
        font-family: $robotosans;
        font-size: 16px;
      }
      .icon-edit-o {
        display: none;
      }
      @media (max-width: 767px) {
        .info-client {
          display: none;
        }
        .photo-profile-wrapper {
          width: calc(100vw - 32px);
          display: flex;
          justify-content: center;
        }
        .photo-profile {
          height: 123px;
          width: 123px;
          img {
            max-height: 123px;
            max-width: 123px;
          }
        }
      }
      .change-photo {
        @media (max-width: 767px) {
          display: none;
        }
        .file-upload {
          max-width: 420px;
          width: 420px;
          height: 40px;
        }
        .file-name {
          margin: 0px;
        }
        .file-button {
          top: 0px;
        }
        .file-picker {
          cursor: pointer;
          border: 1px solid $grey-1;
          width: 420px;
          height: 40px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 12px 10px;
          border-radius: 10px;
          color: $primary;
          font-weight: 700;
          font-size: 14px;
        }
        input {
          display: none;
        }
      }
    }
    .p-inputtext {
      height: 40px !important;
    }
  }
  .info-group {
    border-bottom: 1px solid $border-card;
    padding: 0px 30px;
    @media (max-width: 767px) {
      padding: 0px 16px;
    }
    h3 {
      margin: 30px 0px;
      font-size: 18px;
      font-family: $robotosans;
      font-weight: 700;
    }
    label {
      color: $title-color;
      font-family: $robotosans;
      font-weight: 500;
      font-size: 16px;
      display: block;
    }
    .p-inputtext {
      max-width: 770px;
      height: 40px;
      font-size: 14px;
      &.p-inputtextarea {
        height: 145px;
      }
      &.p-disabled {
        background-color: $border-card;
      }
    }
    .short {
      .p-inputtext {
        max-width: 419px;
      }
    }
    .company-profile {
      a {
        color: $primary;
        font-weight: 600;
        background-color: $border-card;
        height: 40px;
        width: 100%;
        border: 1px solid #ced4da;
        display: flex;
        align-items: center;
        padding: 6px 9px;
        border-radius: 10px;
        margin: -20px 0px 30px 0px;
        max-width: 419px;
      }
    }
    .file-upload {
      margin-bottom: 10px;
      small {
        margin-top: 10px;
        position: relative;
      }
    }
    &.last {
      padding-bottom: 20px;
    }
    label {
      &.label-text {
        margin-top: 10px;
      }
    }
    .label-text {
      font-size: 14px;
      font-weight: 400;
      color: #bdbdbd;
      margin-bottom: 30px;
      @media (max-width: 767px) {
        margin-bottom: 5px;
      }
    }
    .detail-text {
      font-size: 14px;
      font-weight: 400;
      color: #808080;
      margin-bottom: 30px;
      @media (max-width: 767px) {
        margin-bottom: 15px;
      }
    }
  }
  .footer-profile {
    padding: 40px 172px;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 767px) {
      padding: 20px 16px;
    }
    button {
      border-radius: 62px !important;
      font-family: $opensans;
      font-weight: 600 !important;
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
  label {
    &.label-text {
      font-size: 14px;
      font-weight: 400;
      color: #bdbdbd;
      margin-bottom: 30px;
      margin-top: 10px;
      @media (max-width: 767px) {
        margin-bottom: 5px;
      }
    }
  }
}

.profile-module {
  @media (max-width: 767px) {
    padding: 70px 0 0;
    .edit-portfolio,
    .card-edit-profile,
    .add-portfolio {
      margin: 20px 0 !important;
    }
  }
}
.header-account {
  display: none;
  @media (max-width: 767px) {
    display: flex;
  }
}
