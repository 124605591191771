@import "eco_variable";

.p-message {
  z-index: 88;
  &.p-message-default {
    width: 100%;
    max-width: 391px;
    background: $white;
    color: $white;
    border-radius: 10px;
    h3 {
      color: $title-color;
      font-size: 16px;
      line-height: 19px;
    }
    p {
      color: $black;
      font-size: 14px;
      line-height: 18px;
    }
    .p-message-close {
      display: none;
    }
  }
  &.fixed {
    position: fixed;
    top: 70px;
    right: 20px;
  }
  &.p-message-info {
    background-color: $info-rgba !important;
    .p-message-detail, .p-message-summary {
      color: $primary-color !important;
    }
    .p-message-icon {
      color: $primary-color !important;
    }
  }
  .p-message-wrapper {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }
}