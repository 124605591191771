@import "eco_variable";

.category-wrapper {
  padding: 0 20px 0 20px;
  h3 {
    color: $primary;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 20px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .category-list {
    margin-top: 20px;
    overflow-y: auto;
    min-height: 700px;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin-bottom: 10px;
        a {
          color: $black;
          text-transform: capitalize;
          font-size: 14px;
          line-height: 16px;
        }
        &.active {
          a {
            color: $primary;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.filter {
  margin-bottom: 30px;
  display: flex;
  @media (max-width: 767px) {
    width: 100%;
  }
  .filter-wrapp {
    max-width: 100%;
    width: 180px;
    margin-right: 15px;
    &.list-company {
      width: unset;
    }
    .p-inputtext {
      height: 40px;
      font-size: 13px;
    }
    > .p-button,
    > .btn {
      border-radius: 10px !important;
      background-color: transparent;
      border: 1px solid $grey-1;
      padding: 9px 8px !important;
      text-align: center;
      &:hover {
        background-color: transparent;
        outline: 0 none !important;
        border-color: $grey-1;
      }
    }
    .dropdown {
      .btn {
        border-radius: 10px !important;
        background-color: transparent;
        border: 1px solid $grey-1;
        padding: 9px 8px !important;
        text-align: center;
        height: 40px;
        width: 40px;
        &::after {
          display: none;
        }
        &:hover {
          background-color: transparent;
          outline: 0 none !important;
          border-color: $grey-1;
        }
      }
      .dropdown-menu {
        .dropdown-item {
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
  small {
    font-size: 14px;
    line-height: 16px;
    color: #898989;
    margin-top: 4px;
    strong {
      color: #0099ff;
    }
  }
  .filter-field {
    .btn-sort {
      border-radius: 10px !important;
      background-color: transparent;
      border: 1px solid $grey-1;
      padding: 9px 8px !important;
      text-align: center;
      height: 40px;
      width: 40px;
      &::after {
        display: none;
      }
      &:hover {
        background-color: transparent;
        outline: 0 none !important;
        border-color: $grey-1;
      }
    }
  }
}

.sort-by {
  h3 {
    font-size: 14px;
    font-weight: 600;
    color: $title-color;
    font-family: $opensans;
  }
  .p-field-radiobutton {
    font-family: $opensans;
    .p-radiobutton {
      .p-radiobutton-box {
        border-width: 1px !important;
      }
      &.p-radiobutton-checked {
        .p-radiobutton-box {
          &.p-highlight {
            background: transparent !important;
            .p-radiobutton-icon {
              background-color: $primary !important;
            }
          }
        }
        ~ label {
          color: $primary !important;
        }
      }
    }
  }
  .datefilter {
    margin-bottom: 40px;
    .p-inputtext {
      min-width: 140px;
    }
  }
}
