// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
// .brand-dark {
//   @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// }
// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
@import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }
@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";
// prime react style
@import "./_metronic/_assets/sass/eco_variable.scss";
@import "./_metronic/_assets/sass/adm_management_product.scss";
@import "./_metronic/_assets/sass/adm_management_project.scss";
@import "./_metronic/_assets/sass/adm_management_user.scss";

@import "./_metronic/_assets/sass/eco_header_auth.scss";
@import "./_metronic/_assets/sass/eco_header.scss";
@import "./_metronic/_assets/sass/eco_header_auth.scss";
@import "./_metronic/_assets/sass/eco_header_client.scss";
@import "./_metronic/_assets/sass/eco_footer.scss";
@import "./_metronic/_assets/sass/eco_auth.scss";
@import "./_metronic/_assets/sass/eco_button.scss";
@import "./_metronic/_assets/sass/eco_card.scss";
// @import "./_metronic/_assets/sass/eco_input_number.scss";
// @import "./_metronic/_assets/sass/eco_calendar.scss";
// @import "./_metronic/_assets/sass/eco_editor.scss";
// @import "./_metronic/_assets/sass/eco_progress.scss";
// @import "./_metronic/_assets/sass/eco_select_button.scss";
@import "./_metronic/_assets/sass/eco_dropdown.scss";
@import "./_metronic/_assets/sass/preview_project.scss";
// @import "./_metronic/_assets/sass/eco_breadcrumb.scss";
@import "./_metronic/_assets/sass/eco_icons.scss";
@import "./_metronic/_assets/sass/eco_forms.scss";
@import "./_metronic/_assets/sass/eco_filter.scss";
// @import "./_metronic/_assets/sass/eco_orderlist.scss";
@import "./_metronic/_assets/sass/eco_badges.scss";
// @import "./_metronic/_assets/sass/eco_status_progress.scss";
@import "./_metronic/_assets/sass/eco_dialog.scss";
@import "./_metronic/_assets/sass/eco_message.scss";
@import "./_metronic/_assets/sass/eco_table.scss";
@import "./_metronic/_assets/sass/richtext.scss";
// @import "./_metronic/_assets/sass/eco_checkout.scss";
@import "./_metronic/_assets/sass/landingpage.scss";
@import "./_metronic/_assets/sass/dashboard_user.scss";
@import "./_metronic/_assets/sass/dashboard_admin.scss";
@import "./_metronic/_assets/sass/media_share.scss";
@import "./_metronic/_assets/sass/datatables.scss";
@import "./_metronic/_assets/sass/pagination.scss";
@import "./_metronic/_assets/sass/upload.scss";
@import "./_metronic/_assets/sass/profile.scss";
@import "./_metronic/_assets/sass/clientSidebar.scss";
@import "./_metronic/_assets/sass/clientService.scss";
@import "./_metronic/_assets/sass/create_order.scss";
@import "./_metronic/_assets/sass/payment.scss";
@import "./_metronic/_assets/sass/preview_project.scss";
@import "./_metronic/_assets/sass/client_help.scss";
@import "./_metronic/_assets/sass/faq.scss";
@import "./_metronic/_assets/sass/clientCampaign.scss";

@import "./_metronic/_assets/sass/staticpage.scss";
@import "./_metronic/_assets/sass/clientSettings.scss";
@import "./_metronic/_assets/sass/clientProfile.scss";
@import "./_metronic/_assets/sass/clientMyRelease.scss";
// @import "./_metronic/_assets/sass/eco_cart.scss";
@import "./_metronic/_assets/sass/eco_bidding.scss";
// @import "./_metronic/_assets/sass/eco_detail.scss";
@import "./_metronic/_assets/sass/eco_global.scss";
@import "~primeflex/src/_variables.scss";
@import "~primeflex/src/_grid.scss";
@import "~primeflex/src/_formlayout.scss";
@import "~primeflex/src/_display.scss";
@import "~primeflex/src/_text.scss";
@import "~primeflex/src/flexbox/_flexbox.scss";
@import "~primeflex/src/_spacing.scss";
@import "~primeflex/src/_elevation.scss";

@import "~primereact/resources/themes/bootstrap4-light-blue/theme.css";
@import "~primereact/resources/primereact.min.css";
@import "~primeicons/primeicons.css";

@import "./_metronic/_assets/sass/button_input_style.scss";

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}
