@import "eco_variable";

.p-datatable {
  &.no-padding {
    .p-datatable-thead {
      > tr {
        > th {
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
          &.order-body {
            width: 100px;
          }
        }
      }
    }

    .p-datatable-tbody {
      > tr {
        > td {
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
          &.order-body {
            width: 100px;
          }
        }
      }
    }
  }
  &.admin {
    .p-button {
      border-radius: 10px !important;
    }
    .btn-wide {
      min-width: 121px;
    }
  }
  &.border-top {
    .p-datatable-thead {
      border-top: 1px solid $border-card;
    }
  }
  .p-datatable-thead {
    > tr {
      > th {
        text-transform: uppercase;
        &.thumbnail {
          width: 90px;
          padding-left: 0;
        }
        &.project-name {
          width: 200px;
        }
        &.action {
          width: 95px;
          &.text {
            width: 200px;
          }
          &.delete-only {
            width: 50px;
          }
        }
        &.action-article-list {
          width: 50px;
        }
        &.article-name,
        &.pic-name {
          width: 180px;
        }
        &.list-article-project {
          width: 180px;
        }
        &.pm-date,
        &.pic-date {
          width: 120px;
        }
        &.pic-status {
          width: 120px;
        }
        &.project-status {
          width: 95px;
        }
        &.action-button {
          width: 120px;
        }
        &.order-body {
          width: 100px;
        }
        &.invoice-body {
          width: 145px;
        }
        &.release-title {
          width: 400px;
          @media (max-width: 500px) {
            width: 100%;
          }
        }
      }
    }
  }
  .p-datatable-tbody {
    > tr {
      > td {
        font-size: 14px;
        color: #282828;
        font-weight: 400;
        .checkbox-invite {
          margin-left: 18px;
          margin-top: -3px;
        }
        .action-table {
          display: flex;
          .btn {
            padding: 0px !important;
            &.btn-action {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0px !important;
              width: 35px;
              height: 35px;
              background-color: #f3f6f9;
              border-color: #f3f6f9;
              border-radius: 6px !important;
              margin-right: 7px;
              position: relative;
              .dot {
                height: 7px;
                width: 7px;
                background-color: #fb0a2a;
                border-radius: 100%;
                position: absolute;
                top: 2px;
                right: -3px;
              }
              &:hover,
              &:focus,
              &:active {
                box-shadow: none;
                outline: none;
                opacity: 0.5;
              }
              &.text {
                width: unset;
                padding-left: 5px;
                padding-right: 5px;
                color: $primary;
                font-size: 12px;
                font-weight: 600;
                &:hover,
                &:focus,
                &:active {
                  box-shadow: none;
                  outline: none;
                  opacity: 0.8;
                }
              }
              &.article {
                width: unset;
                padding-left: 5px;
                padding-right: 5px;
                color: $primary;
                font-size: 14px;
                background-color: transparent;
                border-color: transparent;
                &:hover,
                &:focus,
                &:active {
                  box-shadow: none;
                  outline: none;
                  opacity: 0.8;
                }
              }
              &.full {
                width: 100%;
                color: $primary;
                font-size: 12px;
                font-weight: 600;
                &:hover,
                &:focus,
                &:active {
                  box-shadow: none;
                  outline: none;
                  opacity: 0.8;
                }
              }
              &:disabled,
              &.disabled {
                opacity: 0.5 !important;
                cursor: not-allowed !important;
              }
              &.chosen {
                background-color: $status-new-02;
              }
              &.article {
                width: unset;
                padding-left: 5px;
                padding-right: 5px;
                color: $primary;
                font-size: 14px;
                background-color: transparent;
                border-color: transparent;
                &:hover,
                &:focus,
                &:active {
                  box-shadow: none;
                  outline: none;
                  opacity: 0.8;
                }
              }
            }
          }
        }
        .image-table {
          cursor: pointer;
          overflow: hidden;
          width: 100%;
          .img-wrap {
            position: relative;
            width: 60px;
            height: 60px;
            overflow: hidden;
            margin: 0 auto;
            border: 1px solid #eaeaea;
            border-radius: 5px;
            > img {
              position: absolute;
              min-width: 100%;
              height: 60px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
        .name-title-table {
          cursor: pointer;
          > h6 {
            font-size: 14px;
            color: #282828;
            font-weight: 400;
            margin-bottom: 5px;
          }
          > p {
            font-size: 14px;
            color: #808080;
            font-weight: 400;
            margin: 0;
          }
        }
        .date-table {
          &.link {
            cursor: pointer;
          }
          p {
            margin: 0;
          }
        }
        .number-table {
          &.link {
            cursor: pointer;
          }
        }
        .status-table {
          display: flex;
          justify-content: flex-start;
          .status-badge {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;
            background-color: #f3f6f9;
            border-radius: 6px;
            padding: 0 15px;
            margin-left: 5px;
            font-size: 12px;
            font-weight: 600;
            color: #0099ff;
          }
        }
        .text-table {
          &.link {
            cursor: pointer;
          }
          p {
            margin: 0;
            text-transform: capitalize;
          }
          &.email {
            p {
              text-transform: none;
            }
          }
        }
        &.text-grey {
          color: $grey-6;
        }
        &.order-body {
          width: 100px;
        }
        &.invoice-body {
          width: 145px;
        }
      }
    }
  }
  &.table-release {
    min-width: 808px;
    .p-datatable-thead {
      > tr {
        > th {
          @media (max-width: 500px) {
            width: 100%;
            padding: 5px 0 !important;
          }
        }
      }
    }

    .p-datatable-tbody {
      > tr {
        > td {
          @media (max-width: 500px) {
            width: 100%;
          }
        }
      }
    }
  }
  &.table-project {
    .p-datatable-thead {
      > tr {
        > th {
          padding: 10px;
          &.thumbnail {
            width: 70px;
            padding-left: 0;
            padding-right: 0;
          }
          &.project-name {
            width: 180px;
          }
          &.project-category {
            width: 95px;
          }
          &.project-writer {
            width: 95px;
          }
          &.project-price {
            width: 130px;
          }
          &.project-date {
            width: 130px;
          }
          &.project-creator {
            width: 150px;
          }
          &.project-status {
            width: 130px;
          }
          &:last-child {
            padding-right: 0;
          }
          &.order-body {
            width: 100px;
          }
        }
      }
    }

    .p-datatable-tbody {
      > tr {
        > td {
          padding: 10px;
          &:first-child {
            padding-left: 0;
            padding-right: 0;
          }
          &:last-child {
            padding-right: 0;
          }
          &.order-body {
            width: 100px;
          }
        }
      }
    }
  }
}

.table-box {
  @media (max-width: 767px) {
    overflow-x: hidden;
  }
  .row-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
    padding: 0px 15px;
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      height: unset;
    }
    .t-head {
      font-size: 14px;
      font-weight: 600;
      color: $grey-6;
      padding: 10px;
      @media (max-width: 767px) {
        width: 100%;
        padding: 5px 0;
      }
      &:nth-child(1) {
        width: 200px;
        @media (max-width: 767px) {
          width: 100%;
        }
      }
      &:nth-child(2) {
        width: 100px;
        @media (max-width: 767px) {
          width: 100%;
        }
      }
      &:nth-child(3) {
        width: 150px;
        @media (max-width: 767px) {
          width: 100%;
        }
      }
      &:nth-child(4) {
        width: 180px;
        @media (max-width: 767px) {
          width: 100%;
        }
      }
      &:nth-child(5) {
        width: 90px;
        @media (max-width: 767px) {
          width: 100%;
        }
      }
    }
  }
  .row-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $border-card;
    height: 62px;
    padding: 0px 15px;
    @media (max-width: 767px) {
      height: unset;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
    }
    .hover-text {
      @media (max-width: 767px) {
        display: none !important;
      }
      position: absolute;
      font-size: 12px;
      span {
        display: none;
      }
      .show {
        display: block;
        background-color: $grey-3;
        padding: 5px;
        border-radius: 3px;
        &.detail {
          margin-left: -15px;
        }
        &.edit {
          margin-left: 20px;
        }
        &.chat {
          margin-left: 60px;
        }
      }
    }
    .t-body {
      width: calc(50vw - 20px);
      font-size: 14px;
      font-weight: 400;
      color: $title-color;
      padding: 10px;
      text-align: left;
      @media (max-width: 767px) {
        width: 100%;
        padding: 5px 0;
      }
      &.deadline {
        color: $grey-6;
      }
      &.color-black {
        color: $title-color !important;
      }
      @media (max-width: 330px) {
        &:nth-child(4) {
          position: relative;
          left: calc(-189vw + 10px);
          top: 30px;
        }
      }
      &:nth-child(1) {
        width: 200px;
        @media (max-width: 767px) {
          width: 100%;
        }
        span {
          cursor: pointer;
        }
      }
      &:nth-child(2) {
        width: 100px;
        @media (max-width: 767px) {
          width: 100%;
        }
      }
      &:nth-child(3) {
        width: 150px;
        @media (max-width: 767px) {
          width: 100%;
        }
      }
      &:nth-child(4) {
        width: 150px;
        color: $grey-6;
        @media (max-width: 767px) {
          width: 100%;
        }
      }
      &:nth-child(5) {
        width: 120px;
        @media (max-width: 767px) {
          width: 100%;
        }
      }
      .action-table {
        padding: 0;
        .btn {
          padding: 0 !important;
          color: #0099ff;
          position: relative;
          font-weight: 600 !important;
          .dot {
            height: 7px;
            width: 7px;
            background-color: #fb0a2a;
            border-radius: 100%;
            position: absolute;
            top: -2px;
            right: -5px;
          }
        }
      }
    }
  }
  &.balance-table {
    .row-head {
      .t-head {
        @media (max-width: 767px) {
          width: 100%;
        }
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          width: 20%;
          @media (max-width: 767px) {
            width: 100%;
          }
        }
      }
    }
    .row-body {
      .t-body {
        @media (max-width: 767px) {
          width: 100%;
        }
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          @media (max-width: 767px) {
            width: 100%;
          }
          width: 20%;
        }
        p {
          &.success {
            color: #17ad49;
          }
          &.danger {
            color: #fb0a2a;
          }
        }
      }
    }
  }
}

.btn {
  padding: 0px;
  &.btn-action {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 35px;
    height: 35px;
    background-color: #f3f6f9;
    border-color: #f3f6f9;
    border-radius: 6px;
    margin-right: 7px;
    position: relative;
    &.text {
      width: fit-content;
      height: auto;
      padding: 5px 10px;
      background-color: transparent;
      border-color: transparent;
    }
  }
}

.table-attribute-wrapp {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  &.between {
    justify-content: space-between;
    @media (max-width: 767px) {
      justify-content: start;
      flex-direction: column;
    }
  }
  .attribute-right {
    width: fit-content;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.table-content-wrapp {
  width: 100%;
  overflow-x: auto;
}
