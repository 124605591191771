.payment-confirmation {
  .icon-back {
    cursor: pointer;
  }
  &.card {
    font-family: $opensans;
    min-height: calc(100vh - 180px);
    @media (max-width: 767px) {
      border-radius: 0px;
    }
    .card-header {
      @media (max-width: 767px) {
        display: none;
      }
    }
    .card-body {
      padding: 40px 150px !important;
      @media (max-width: 767px) {
        padding: 15px !important;
        min-height: calc(100vh - 148px);
      }
      h3 {
        margin: 0px 0px 40px;
        font-size: 18px;
        font-weight: 600;
        @media (max-width: 767px) {
          display: none;
        }
      }
      .table-header {
        color: $grey-6;
        font-size: 18px;
        padding-bottom: 20px;
        border-bottom: 1px solid $border-card;
        font-weight: 600;
        h4 {
          color: $grey-6;
          font-weight: 600;
          margin-bottom: 0px;
          width: 33.33%;
          &:last-child {
            text-align: end;
          }
        }
      }
      .order-detail {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 600;
        color: $title-color;
        margin-top: 30px;
        @media (max-width: 767px) {
          display: block;
          margin-top: 5px;
        }
        > span {
          width: 33.33%;
          &:last-child {
            text-align: end;
          }
        }
        .item:nth-child(1) {
          margin-right: 20px;
        }
        .item:nth-child(2) {
          margin-right: 20px;
          text-align: center;
          @media (max-width: 767px) {
            text-align: left;
          }
        }
        .item:nth-child(3) {
          text-align: right;
          @media (max-width: 767px) {
            text-align: left;
          }
        }
        .item {
          width: 33%;
          margin-bottom: 10px;
          @media (max-width: 767px) {
            width: 100%;
            border-bottom: 1px solid $border-card;
            margin-bottom: 15px;
            padding-bottom: 5px;
          }
          label {
            color: $grey-6;
            margin-bottom: 20px;
            @media (max-width: 767px) {
              margin-bottom: 0px;
            }
          }
          .divider {
            position: absolute;
            width: calc(100% - 300px);
            height: 1px;
            background-color: $border-card;
            @media (max-width: 767px) {
              display: none;
            }
          }
          p {
            position: relative;
            margin-top: 30px;
            margin-bottom: 0px;
            font-size: 16px;
            @media (max-width: 767px) {
              margin-top: 0px;
              font-size: 14px;
              font-weight: 400;
              padding: 10px 0px;
            }
          }
        }
        .category {
          color: $grey-6;
          @media (max-width: 767px) {
            color: $title-color;
          }
        }
      }
      .table-content {
        font-size: 16px;
        font-weight: 600;
        color: $title-color;
        margin-top: 20px;
        border-bottom: 1px solid $border-card;
        &.item {
          font-size: 14px;
          font-weight: 400;
          align-items: end;
          padding-bottom: 30px;
          position: relative;
          .number {
            font-weight: 600;
          }
        }
        .item-total {
          margin: 0px 18px;
          button {
            border: unset !important;
          }
          .icon-wrapper {
            cursor: pointer;
            background-color: $border-card;
            border-radius: 50%;
            color: white;
            height: 28px;
            width: 28px;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            &.active {
              background-color: $primary;
            }
          }
          .number {
            color: $grey-6;
            width: 60px;
            border-bottom: 1px solid $border-card;
            display: flex;
            justify-content: center;
            margin: 0px 5px;
          }
          .input-number-qty {
            border-top: unset;
            border-left: unset;
            border-right: unset;
            border-bottom: 1px solid $border-card;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            margin: 0px 5px;
            color: $grey-6;
            text-align: center;
            &:focus-visible {
              outline: unset;
              border-color: $primary;
            }
          }
        }
        .p-inputtext {
          width: 50%;
          margin: 10px 0px 30px 0px;
        }
      }
      .table-footer {
        margin-top: 30px;
        @media (max-width: 767px) {
          margin-top: 20px;
        }
        span {
          font-size: 14px;
          color: $title-color;
        }
        strong {
          color: $title-color;
          font-size: 18px;
          font-weight: 600;
          @media (max-width: 767px) {
            font-size: 14px;
          }
        }
      }
      .address-section {
        display: flex;
        justify-content: space-between;
        @media (max-width: 767px) {
          display: flex;
          flex-direction: column-reverse;
        }
      }
      .invoice-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 767px) {
          display: flex;
          flex-direction: column-reverse;
        }
        .iris-logo {
          display: flex;
          @media (max-width: 767px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 35px 0px;
          }
          img {
            width: 142px;
            margin-right: 35px;
            @media (max-width: 767px) {
              margin-right: 0px;
              width: 57px;
              text-align: center;
            }
          }
          h1 {
            font-family: $opensans;
            font-weight: 700;
            font-size: 26px;
            margin-top: 33px;
            @media (max-width: 767px) {
              margin-top: 5px;
              text-align: center;
              font-size: 22px;
            }
          }
        }
        .payment-status {
          font-family: $opensans;
          text-align: right;
          @media (max-width: 767px) {
            width: 100%;
          }
          span {
            font-size: 14px;
            margin-bottom: 9px;
            @media (max-width: 767px) {
              font-size: 13px;
            }
          }
          h4 {
            font-weight: 600;
            font-size: 16px;
            @media (max-width: 767px) {
              font-size: 14px;
            }
          }
        }
      }
      .invoice-address {
        width: 70%;
        margin-bottom: 40px;
        @media (max-width: 767px) {
          width: 100%;
          margin-bottom: 20px;
        }
        h3 {
          margin: 30px 0px 20px 0px;
          font-size: 20px;
          font-weight: 600;
          @media (max-width: 767px) {
            font-size: 16px;
            margin: 10px 0px 5px 0px;
          }
        }
        h4 {
          font-size: 16px;
          font-weight: 600;
          color: $grey-6;
          margin-bottom: 18px;
          @media (max-width: 767px) {
            font-size: 14px;
            margin-bottom: 5px;
          }
        }
      }
      .invoice-number {
        width: 30%;
        margin-bottom: 40px;
        text-align: end;
        @media (max-width: 767px) {
          text-align: left;
          width: 100%;
          margin-bottom: 0px;
        }
        h3 {
          margin: 30px 0px 20px 0px;
          font-size: 20px;
          font-weight: 600;
          @media (max-width: 767px) {
            font-size: 16px;
            margin: 10px 0px 5px 0px;
          }
        }
        h4 {
          font-size: 16px;
          font-weight: 600;
          color: $grey-6;
          margin-bottom: 18px;
          @media (max-width: 767px) {
            font-size: 14px;
          }
        }
      }
      .invoice-table {
        padding-top: 40px;
        border-top: 1px solid $border-card;
        @media (max-width: 767px) {
          padding-top: 20px;
        }
        h4 {
          color: $title-color;
          font-size: 16px;
          margin-bottom: 40px;
          font-weight: 600;
          @media (max-width: 767px) {
            margin-bottom: 20px;
          }
        }
        h5 {
          color: $grey-6;
          font-weight: 600;
          @media (max-width: 767px) {
            font-size: 14px;
          }
        }
        .invoice-content {
          color: $title-color;
          font-size: 14px;
          border-bottom: 1px solid $border-card;
          padding: 25px 0px;
          display: flex;
          justify-content: space-between;
          @media (max-width: 767px) {
            padding: 20px 0px;
            span {
              padding: 20px 15px 20px 0;
              width: 100%;
              margin-left: 0;
              text-align: left;
            }
            strong {
              padding: 20px 0px;
              width: 110px;
              margin-left: 0;
              text-align: right;
            }
          }
          &.last {
            border-bottom: 0px;
          }
          .item {
            @media (max-width: 767px) {
              text-align: left !important;
              font-size: 12px;
            }
          }
        }
        .table-footer {
          border-bottom: 1px solid $border-card;
          padding-bottom: 25px;
          @media (max-width: 767px) {
            padding-bottom: 20px;
          }
        }
      }
    }
    .card-footer {
      padding: 26px 150px;
      display: flex;
      justify-content: flex-end;
      @media (max-width: 767px) {
        justify-content: center;
        padding: 26px 26px;
        button {
          width: 100%;
        }
      }
      button {
        font-weight: 600 !important;
      }
    }
  }
  .mobile-table-item {
    @media (max-width: 767px) {
      h3 {
        display: block !important;
      }
    }
  }
}

.method {
  &.col-sm-12 {
    padding: 0px !important;
  }
}

.payment-method {
  &.card {
    @media (max-width: 767px) {
      border-radius: 0px;
      min-height: calc(100vh - 55px);
      margin-bottom: 0px !important;
    }
    .card-header {
      margin-bottom: 0px;
      @media (max-width: 767px) {
        display: none;
      }
    }
    .p-radiobutton {
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      .p-radiobutton-box {
        border-width: 1px;
      }
      &.p-radiobutton-checked {
        .p-highlight {
          border-color: $dark-blue-color;
          background-color: white;
          border-width: 1px;
        }
        .p-radiobutton-icon {
          background-color: $dark-blue-color;
          height: 10px;
          width: 10px;
        }
      }
    }
    .card-body {
      .bank-options {
        border-top: 1px solid $border-card;
        padding-bottom: 20px;
        margin: 26px 0px;
        .bank {
          background-color: $grey-3;
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0px 15px;
          height: 65px;
          border-radius: 6px;
          img {
            height: 35px;
            @media (max-width: 767px) {
              height: 24px;
              width: 10%;
            }
          }
          label {
            font-family: $robotosans;
            font-weight: 400;
            font-size: 1.275rem;
            color: $title-color;
            margin: 0px 0px 0px 10px;
          }
        }
      }
      .payment-option {
        display: flex;
        align-items: center;
        label {
          font-family: $robotosans;
          font-weight: 500;
          font-size: 1.275rem;
          color: $title-color;
          margin: 0px 0px 0px 10px;
        }
      }
    }
    .p-button {
      margin: 0px 30px 40px 0px;
      font-weight: 600 !important;
      @media (max-width: 767px) {
        margin: 0px;
        width: 100%;
      }
    }
  }
  .card-footer {
    @media (max-width: 767px) {
      justify-content: center;
    }
  }
}

.payment-detail {
  font-family: $opensans;
  &.card {
    @media (max-width: 767px) {
      border-radius: 0px;
    }
    .card-header {
      margin-bottom: 0px;
      @media (max-width: 767px) {
        display: none;
      }
    }
    .card-body {
      padding: 20px 30px 40px 30px;
      @media (max-width: 767px) {
        padding: 20px 15px;
      }
      .bank-details {
        color: $grey-6;
        font-size: 14px;
        margin-bottom: 30px;
        .info {
          font-size: 16px;
          color: $title-color;
          font-weight: 700;
          @media (max-width: 767px) {
            font-size: 14px;
          }
        }
        .paste {
          color: $primary;
          font-weight: 700;
          cursor: pointer;
        }
      }
      .transfer-content {
        border-top: 1px solid $border-card;
        margin-top: 20px;
        padding-top: 30px;
        @media (max-width: 767px) {
          margin-top: 0px;
          padding-top: 10px;
          border-top: 0px;
        }
      }
      .helper-blue {
        background-color: #e7fafd;
        padding: 19px;
        border-radius: 6px;
        @media (max-width: 767px) {
          display: none !important;
        }
        p {
          color: $dark-blue-color;
          font-size: 14px;
          margin-bottom: 0px;
          width: calc(100% - 64px);
        }
        .icon-info {
          height: 40px;
          width: 40px;
        }
      }
      .upload-payment-form {
        padding-top: 30px;
        @media (max-width: 767px) {
          padding-top: 0px;
        }
        label {
          font-size: 14px;
          font-weight: 600;
          color: $title-color;
        }
        .p-inputtext {
          @media (max-width: 767px) {
            height: 40px;
          }
        }
        .button-upload-image {
          .icon-plus {
            @media (max-width: 767px) {
              height: 40px;
              width: 40px;
            }
          }
        }
      }
      .upload-transfer {
        border-top: 1px solid $border-card !important;
        padding-top: 30px;
        margin-top: 30px;
        h3 {
          font-size: 18px;
          font-weight: 600;
          color: $title-color;
        }
        .upload {
          width: 180px;
          margin-bottom: 30px;
        }
        .card-footer {
          @media (max-width: 767px) {
            .p-button {
              width: 100%;
            }
          }
        }
      }
      .p-accordion-tab {
        .p-accordion-header {
          border-bottom: 0px !important;
          .p-accordion-header-link {
            border-top: 1px solid $border-card !important;
            padding: 20px 0px !important;
            margin: 0px 0px 5px 0px;
          }
        }
      }
      .p-accordion-content {
        padding: 0px 0px 10px 0px;
        margin-bottom: 20px;
        ol {
          padding: 0px 10px 0px 15px;
        }
        li {
          margin-bottom: 15px;
        }
      }
      .p-button {
        font-weight: 600 !important;
      }
    }
    .card-footer {
      @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        .p-button {
          width: 100%;
        }
        .p-button-secondary {
          margin-top: 15px;
        }
      }
      .p-button {
        @media (max-width: 480px) {
          font-size: 11px !important;
          padding: 0 !important;
        }
      }
    }
  }
}

.success-payment {
  font-family: $opensans;
  &.card {
    .card-body {
      padding: 30px 70px;
      @media (max-width: 767px) {
        padding: 20px;
      }
      h2,
      p {
        text-align: center;
      }
      h2 {
        margin-bottom: 1.75rem;
        font-weight: 600;
      }
      p {
        margin-bottom: 20px;
        font-size: 14px;
      }
      img {
        height: 156px;
        margin-bottom: 50px;
      }
    }
    .p-button {
      font-weight: 600 !important;
      @media (max-width: 767px) {
        font-size: 12px;
      }
    }
    .card-footer {
      border-top: 0px;
    }
  }
}

.payment-info-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .payment-info-item {
    width: 25%;
    h6 {
      font-size: 16px;
      margin-bottom: 10px;
      font-weight: 600;
      color: #808080;
    }
    p {
      font-size: 18px;
      color: #2d2d2d;
      font-weight: 600;
      margin-bottom: 0 !important;
    }
    .bank-text {
      font-size: 16px;
      color: #808080;
      font-weight: 400;
      margin-bottom: 8px;
    }
  }
}
