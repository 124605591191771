.card {
  &.card-dashboard {
    .card-header {
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 2.25rem;
      margin-bottom: 0;
      @media (max-width: 500px) {
        padding: 0 1.5rem;
        height: 60px;
      }
    }
    .card-body {
      padding: 2rem 1.5rem;
    }
  }
}
.card-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
  a.card-title-back,
  .card-title-back {
    height: 35px;
    width: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
  }
  .card-title-text {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    @media (max-width: 767px) {
      font-size: 14px;
    }
    &.large {
      font-size: 22px;
      @media (max-width: 767px) {
        font-size: 16px;
      }
    }
  }
}
.card-header-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0;
}
.project-detail-top {
  padding: 0 0 30px;
  width: 100%;
  border-bottom: 1px solid #f4f4f4;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.project-info-wrapp {
  width: 100%;
  padding: 0 2.25rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  @media (max-width: 500px) {
    flex-direction: column;
  }
  .project-image {
    width: 180px;
    height: 180px;
    border: 1px solid #eaeaea;
    overflow: hidden;
    margin-right: 20px;
    float: left;
    position: relative;
    @media (max-width: 500px) {
      margin: 0 auto 20px;
      width: 100%;
    }
    img {
      min-width: 100%;
      height: 180px;
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      @media (max-width: 500px) {
        height: 200px;
      }
    }
  }
  .project-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
    width: calc(100% - 200px);
    @media (max-width: 500px) {
      width: 100%;
    }
  }
  .info-row-title {
    width: 100%;
    margin-bottom: 10px;
    @media (max-width: 500px) {
      margin-bottom: 20px;
    }
    h3 {
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 5px;
      @media (max-width: 500px) {
        font-size: 18px;
      }
    }
    h6 {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 0;
      @media (max-width: 500px) {
        font-size: 14px;
      }
    }
  }
  .info-row {
    margin-top: 20px;
    margin-right: 30px;
    width: fit-content;
    @media (max-width: 500px) {
      margin-top: 0;
    }
    .info {
      margin-bottom: 15px;
      img {
        height: 28px;
      }
      p {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  .project-description {
    padding: 20px 0;
    p {
      font-size: 14px;
      color: #2d2d2d;
      word-break: break-all;
    }
  }
  .project-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .action-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .action-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
.project-detail-bottom {
  padding: 30px 0 0;
  width: 100%;
  .accordion-project-wrapper {
    padding: 40px 2.25rem 0;
    h2 {
      margin-bottom: 10px;
    }
  }
  .detail-release-tab {
    padding: 40px 2.25rem 0;
  }
  .tab-project {
    .p-tabview {
      .p-tabview-nav {
        li {
          .p-tabview-nav-link {
            font-size: 16px;
            color: #2d2d2d;
            font-weight: 600;
          }
        }
      }
    }
  }
}
.detail-release-tab {
  overflow-x: auto;
  @media (max-width: 500px) {
    .p-datatable {
      min-width: 800px;
      .p-datatable-thead {
        > tr {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          width: 100%;
          border-bottom: 2px solid #dee2e6;
          > th {
            width: 100%;
            padding: 8px 10px;
            span {
              font-weight: 600;
            }
            &:first-child {
              padding: 8px 10px;
              padding-left: 10px !important;
            }
          }
        }
      }
      .p-datatable-tbody {
        > tr {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          width: 100%;
          border-bottom: 1px solid #dee2e6;
          &:last-child {
            border-bottom: unset;
          }
          > td {
            width: 100%;
            border: unset;
            padding: 5px 10px;
            &:first-child {
              padding: 15px 10px 5px;
              padding-left: 10px !important;
            }
            &:last-child {
              padding: 5px 10px 15px;
            }
          }
        }
      }
    }
  }
}
.dashboard-half-form {
  width: 60%;
  margin: 0 auto;
  label {
    font-size: 16px;
    font-weight: 600;
    color: #2d2d2d;
  }
  .p-error {
    font-size: 12px;
    font-weight: 500;
  }
  .p-dropdown {
    height: 50px;
    width: 100%;
    max-width: unset;
    .p-dropdown-label {
      &.p-placeholder,
      &.p-inputtext {
        height: 48px;
        display: flex;
        align-items: center;
      }
    }
    .p-dropdown-filter-container {
      height: 42px;
      input {
        height: 40px;
      }
    }
  }
  .button-action-wrapp {
    width: 100%;
    padding-top: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
