.media-share-upper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 767px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
}
.media-share-text {
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 40px;
  @media (max-width: 500px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 0;
  }
  .media-share-box {
    border: 1px solid #eaeaea;
    padding: 10px 15px;
    width: 100%;
    border-radius: 6px;
    margin-bottom: 10px;
    max-width: 250px;
    @media (max-width: 500px) {
      width: 48%;
    }
    &:last-child {
      margin-bottom: 0;
    }
    p {
      font-size: 16px;
      color: #2d2d2d;
      font-weight: 500;
      margin-bottom: 5px;
      @media (max-width: 500px) {
        font-size: 12px;
      }
    }
    h3 {
      font-size: 32px;
      color: #2d2d2d;
      font-weight: 500;
      @media (max-width: 500px) {
        font-size: 18px;
      }
    }
  }
}
.media-share-chart {
  margin-right: 20px;
  @media (max-width: 500px) {
    width: 150px;
    margin-right: 10px;
  }
}
.media-share-legend {
  border: 1px solid #eaeaea;
  padding: 10px 15px;
  border-radius: 6px;
  width: fit-content;
  @media (max-width: 500px) {
    width: 100%;
  }
  p {
    font-size: 16px;
    color: #2d2d2d;
    font-weight: 500;
    margin-bottom: 5px;
    @media (max-width: 500px) {
      font-size: 12px;
    }
  }
}
.legend-box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
  .legend-color {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    margin-right: 10px;
    @media (max-width: 500px) {
      width: 10px;
      height: 10px;
      margin-right: 8px;
    }
  }
  .legend-value {
    font-size: 24px;
    color: #2d2d2d;
    font-weight: 500;
    margin-right: 10px;
    @media (max-width: 500px) {
      font-size: 18px;
      margin-right: 8px;
    }
  }
  .legend-name {
    font-size: 14px;
    color: #808080;
    font-weight: 500;
    @media (max-width: 500px) {
      font-size: 12px;
    }
  }
}
.label-box {
  padding: 15px 20px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 4px 13px rgba($color: #808080, $alpha: 0.5);
  .label-color {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    margin-right: 10px;
    @media (max-width: 500px) {
      width: 10px;
      height: 10px;
      margin-right: 8px;
    }
  }
  .label-text {
    width: calc(100% - 25px);
  }
  .label-value {
    font-size: 14px;
    color: #2d2d2d;
    font-weight: 500;
    @media (max-width: 500px) {
      font-size: 12px;
    }
  }
  .label-name {
    font-size: 12px;
    color: #808080;
    font-weight: 500;
    @media (max-width: 500px) {
      font-size: 10px;
    }
  }
}
