.main-layout {
  overflow-x: hidden;
}
.right-side {
  position: relative;
  left: 265px;
}
.client-header {
  background-color: white;
  height: 120px;
  width: calc(100vw - 265px);
  box-shadow: $shadow-6;
  position: fixed;
  top: 0;
  z-index: 5;
  .account-info {
    display: flex;
    padding: 22px 37px;
    justify-content: flex-end;
    border-bottom: 1px solid $grey-4;
    height: 64px;
    color: $primary;
    .dropdown {
      margin-right: 20px;
      .btn.btn-success:not(:disabled):not(.disabled):active:not(.btn-text),
      .btn.btn-success:not(:disabled):not(.disabled).active,
      .show > .btn.btn-success.dropdown-toggle,
      .show .btn.btn-success.btn-dropdown {
        background-color: transparent;
        border-color: transparent;
      }
      .dropdown-toggle {
        background-color: transparent;
        border-color: transparent;
        position: relative;
        &:hover {
          background-color: transparent !important;
          border-color: transparent !important;
        }
      }
      &.has-noty {
        &::before {
          content: "";
          width: 6px;
          height: 6px;
          border-radius: 100%;
          background-color: $danger;
          position: absolute;
          top: 8px;
          right: 0px;
          z-index: 1;
        }
      }
      .dropdown-toggle.nav-link:after,
      .dropdown-toggle.btn:after {
        display: none;
      }
      .dropdown-menu {
        min-width: inherit;
        width: 241px;
        left: -200px !important;
        .title-menu {
          padding: 10px;
          font-size: 12px;
        }
        .dropdown-item {
          white-space: inherit;
          &.active {
            background-color: $primary-rgba;
          }
          .notif-box {
            .date {
              color: $grey-6;
              margin-bottom: 10px;
              font-size: 12px;
              display: block;
            }
            .title-box {
              font-size: 12px;
              font-weight: 600;
              color: $title-color;
              display: block;
            }
            .desc {
              color: $title-color;
              font-size: 12px;
            }
          }
        }
        &.dropdown-noty {
          height: 320px;
          overflow-y: auto;
        }
      }
      &.user {
        .dropdown-toggle {
          span {
            color: $grey-6;
          }
        }
        .dropdown-menu {
          left: 0px !important;
          .dropdown-item {
            color: $grey-6;
            svg {
              vertical-align: middle;
              margin-right: 8px;
              g {
                [fill] {
                  fill: $grey-6;
                }
              }
            }
          }
        }
      }
    }
  }
  .page-title {
    color: $title-color;
    font-size: 16px;
    font-weight: 500;
    padding: 17px 31px;
  }
}
.content-wrapper {
  padding: 120px 0 0;
  min-height: 100vh;
}
