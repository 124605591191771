@import "eco_variable";

.managament-product {
  padding-bottom: 50px;
  .product-info {
    &::after {
      content: "";
      clear: both;
      display: block;
    }
    .pict {
      width: 55px;
      margin-right: 15px;
      float: left;
      img {
        max-width: 100%;
      }
    }
    .desc {
      overflow: hidden;
      padding-bottom: 10px;
      h3 {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: $black;
        margin-bottom: 20px;
      }
    }
  }
  .desc-product {
    p {
      font-size: 14px;
      line-height: 18px;
      color: $black;
    }
    .action {
      text-align: right;
      a {
        font-weight: 700;
      }
    }
  }
  .action-table {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    > a {
      margin-right: 20px;
    }
    label {
      display: none;
    }
  }
  .p-datatable {
    .p-datatable-wrapper {
      table {
        .p-datatable-thead {
          > tr {
            > th {
              border-top: none;
              padding-top: 24px;
              padding-bottom: 24px;
              text-transform: uppercase;
              color: #898989;
              vertical-align: top;
              &:first-child {
                width: 25%;
              }
              &:last-child {
                width: 130px;
              }
            }
          }
        }
        .p-datatable-tbody {
          td {
            padding-top: 30px;
            padding-bottom: 30px;
            vertical-align: top;
            vertical-align: top;
            &:last-child {
              vertical-align: bottom;
            }
          }
          > tr {
            &:last-child {
              td {
                border-bottom: 1px solid #dee2e6;
              }
            }
          }
        }
      }
    }
  }
}
.managament-bidding {
  .p-datatable {
    .p-datatable-wrapper {
      table {
        .p-datatable-thead {
          > tr {
            > th {
              border-top: none;
              padding-top: 24px;
              padding-bottom: 24px;
              text-transform: uppercase;
              color: #898989;
              vertical-align: top;
              &:first-child {
                width: 30px;
              }
              &:last-child {
                width: 150px;
              }
            }
          }
        }
        .p-datatable-tbody {
          td {
            padding-top: 30px;
            padding-bottom: 30px;
            vertical-align: top;
            vertical-align: top;
            &:last-child {
              vertical-align: bottom;
            }
          }
          > tr {
            &:last-child {
              td {
                border-bottom: 1px solid #dee2e6;
              }
            }
          }
        }
      }
    }
    &.bidding-table {
      border-top: 1px solid #dee2e6;
      .p-datatable-wrapper {
        table {
          .p-datatable-thead {
            > tr {
              > th {
                border-top: none;
                padding-top: 24px;
                padding-bottom: 24px;
                text-transform: uppercase;
                color: #898989;
                vertical-align: top;
                &:first-child {
                  width: 25%;
                }
                &:last-child {
                  width: 150px;
                }
              }
            }
          }
          .p-datatable-tbody {
            td {
              padding-left: 20px;
              padding-right: 20px;
              vertical-align: middle;
              > p {
                margin-bottom: 0;
              }
              a {
                font-weight: 500;
                text-decoration: underline;
              }
              .total-qty {
                p {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 16px;
                  color: $black;
                  span {
                    color: #009931;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .estimate-wrapp {
    background-color: $white;
    padding: 20px;
    display: flex;
    border-bottom: 4px solid #dee2e6;
    .estimate-info {
      margin-left: auto;
      display: flex;
      justify-content: flex-end;
      .estimate {
        text-align: right;
        padding-right: 20px;
        p {
          margin-bottom: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: $grey-2;
        }
        span {
          font-weight: 500;
          font-size: 20px;
          line-height: 150%;
          color: $danger;
        }
      }
      .action {
        .btn {
          width: 175px;
        }
      }
    }
  }
}
.bidding-wrapp {
  background-color: $white;
  padding: 100px 0;
  margin-top: -50px;
  display: flex;
  justify-content: center;
  align-items: center;
  .bidding-info {
    width: 100%;
    max-width: 392px;
    text-align: center;
    h2 {
      font-size: 32px;
      line-height: 37px;
      color: $black;
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $grey-5;
      margin-bottom: 30px;
    }
    img {
      max-width: 100%;
    }
    a {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      display: block;
      text-align: center;
      margin-top: 20px;
    }
  }
  .empty-cart {
    width: 100%;
    max-width: 392px;
    text-align: center;
    h2 {
      font-size: 32px;
      line-height: 37px;
      color: $black;
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $grey-5;
      margin-bottom: 30px;
    }
    img {
      max-width: 100%;
    }
    a {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      display: block;
      text-align: center;
      margin-top: 20px;
    }
  }
}

.add-portfolio,
.edit-portfolio {
  max-width: 100%;
  padding: 10px;
  @media (max-width: 767px) {
    padding: 0px 12.5px !important;
    .p-inputtext,
    .file-name,
    .file-button {
      font-size: 13px;
    }
    .file-upload {
      margin-top: 18px;
    }
    .footer-form {
      display: flex;
      justify-content: center;
      .p-button {
        min-width: calc((100vw - 70px) / 2);
        display: flex;
        justify-content: center;
      }
    }
  }
}

.card {
  &.has-padding-x {
    padding-left: 20px;
    padding-right: 20px;
  }
  &.border-0 {
    border: none;
  }
  .card-header {
    &.profile {
      @media (max-width: 767px) {
        padding: 15px 15px;
        .icon-back {
          position: relative;
          top: -2px;
        }
      }
    }
    display: flex;
    .btn {
      margin-left: auto;
      height: 38px;
      padding: 4px 10px;
    }
  }
  &.card-custom-detail {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    @media (max-width: 767px) {
      margin-top: 100px !important;
      padding: 10px;
    }
    .icon-doc {
      position: relative;
      top: -3px;
    }
    .action-span {
      strong {
        font-size: 14px;
      }
    }
    &.client-setting {
      @media (max-width: 767px) {
        margin-top: 10px !important;
        .card-header {
          padding: 15px 11px;
          .btn {
            padding: 0px !important;
          }
          .card-label {
            font-size: 15px;
          }
          .icon-edit-o {
            height: 24px;
            width: 24px;
            padding: 6px;
            background-position-x: 3.75px;
            background-position-y: 3.75px;
            background-size: 60%;
          }
        }
      }
    }
    &.client-update-setting,
    &.list-pic,
    &.detail-myrelease {
      @media (max-width: 767px) {
        height: calc(100vh - 60px);
        margin-top: 0px !important;
        border-radius: 0px;
        .card-header {
          display: none !important;
        }
      }
    }
    &.add-portfolio,
    &.edit-portfolio {
      min-width: 100%;
      margin: 0px;
      .pi-calendar {
        position: relative;
        top: -5px;
      }
      @media (max-width: 767px) {
        label {
          font-size: 14px;
          font-weight: 600;
        }
        .p-inputtext {
          height: 50px;
          font-size: 14px;
          padding: auto 15px;
        }
      }
    }
    &.profile {
      margin-left: 0px !important;
      margin-right: 0px !important;
      max-width: 100% !important;
      margin-top: 20px !important;
      padding: 10px 20px;
    }
    &.setting {
      max-width: 100% !important;
      @media (max-width: 676px) {
        margin: 0 0 15px !important;
      }
    }
    &.w-full {
      max-width: inherit;
    }
    &.full-width {
      width: 100%;
      max-width: 1280px;
    }
    .card-header {
      h3 {
        margin-bottom: 0 !important;
        
        .card-label{
          @media (max-width: 767px) {
            font-size: 12px;
          }
        }
      }
      .button-back {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  &.no-radius {
    border-radius: 0;
  }
  &.has-shadow {
    box-shadow: $shadow-4;
    border: none;
  }
}
.detail-wrapp {
  h2 {
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: #181c32;
  }
  .price {
    font-size: 22px;
    line-height: 40px;
    color: #898989;
    span {
      font-weight: 700;
      font-size: 22px;
      line-height: 40px;
      color: #ea4946;
    }
  }
  .desc {
    margin-top: 30px;
    p {
      font-size: 12px;
      line-height: 21px;
      color: #3f4254;
    }
  }
  .detail-info {
    display: flex;
    flex-flow: wrap;
    margin-top: 30px;
    .info {
      padding: 10px;
      width: 40%;
      p {
        font-weight: 500;
        font-size: 13px;
        line-height: 21px;
        color: #181c32;
        margin-bottom: 8px;
      }
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #898989;
      }
    }
  }
}
.id-satatus {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  border-bottom: 4px solid #e5eaee;
  .info {
    h3 {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $black;
    }
    a {
      margin-left: 15px;
      &:first-child {
        margin-left: 0;
      }
    }
    .btn {
      height: 36px;
      > span {
        margin-top: -2px;
      }
    }
  }
}
.ukm-info {
  padding: 15px 20px;
  margin-bottom: 15px;
  .info {
    h3 {
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: #898989;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
    span {
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: #898989;
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.package-price {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  border-bottom: 4px solid #e5eaee;
  .info {
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #898989;
    }
    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $black;
      display: block;
      margin-bottom: 15px;
    }
    a {
      text-decoration: underline;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
    }
    .form-wrapp {
      margin-top: 20px;
    }
  }
}
.form-wrapp {
  display: flex;
  justify-content: center;
  align-items: baseline;
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $black;
    margin-right: 10px;
    display: inline-block;
  }
  .p-inputtext {
    width: 97px;
    text-align: center;
    color: $black;
  }
}
.termin-wrapp {
  padding: 20px 20px;
  border-bottom: 4px solid #e5eaee;
  position: relative;

  h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $black;
    margin-bottom: 20px;
  }
}
.button-add-termin {
  display: flex;
  height: 100%;
  align-items: center;

  > div {
    width: 100%;
    text-align: center;
    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: $black;
    }
  }
  .add-termin {
    display: flex;
    width: 76px;
    height: 76px;
    border-radius: 700px;
    background-color: $primary;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 15px;
  }
}
.termin-form {
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #e5eaee;
  position: relative;
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    display: none;
  }
  &:hover {
    .close {
      display: block;
    }
  }
  h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $black;
  }
  .form-wrapp {
    margin-bottom: 15px;
    display: block;
    label {
      display: block;
      margin-right: 0 !important;
      font-weight: 500 !important;
      font-size: 14px;
      line-height: 16px;
      color: $black;
      margin-bottom: 8px;
    }
    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $black;
      margin-left: 8px !important;
    }
  }
  .p-d-flex {
    display: block !important;
    margin-bottom: 15px;
    label {
      display: block;
      margin-right: 0 !important;
      font-weight: 500 !important;
      font-size: 14px;
      line-height: 16px;
      color: $black;
      margin-bottom: 8px !important;
    }
    .p-calendar {
      margin-bottom: 10px;
      .p-inputtext {
        border-radius: 10px !important;
      }
    }
  }
  .info {
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $black;
    }
    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #898989;
    }
  }
}
.termin-action {
  padding: 20px;
  text-align: right;
  .btn-link {
    color: #898989;
  }
  .btn {
    width: 175px;
  }
}
.msg-termin {
  border-radius: 10px;
  padding: 13px 20px;
  background-color: #ffeded;
  text-align: left;
  p {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: #ea4946;
  }
}

.step-wrapp {
  padding: 20px;
  background-color: $white;
  margin-bottom: 20px;
  @import "eco_variable";

  .step-progress {
    padding: 15px;
    display: flex;
    justify-content: center;
    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      li {
        display: inline-block;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: $grey-1;
        position: relative;
        vertical-align: middle;
        text-align: center;
        margin-right: 50px;
        &:last-child {
          margin-right: 0;
        }
        .status-check {
          width: 40px;
          height: 40px;
          display: block;
          border-radius: 100%;
          background-color: $grey-1;
          margin: 0 auto 20px auto;
          color: $grey-1;
          text-align: center;
          line-height: 24px;
          .pi {
            color: $grey-1;
            font-size: 24px;
            line-height: 40px;
          }
        }
        .date {
          font-size: 12px;
          line-height: 14px;
          color: $grey-2;
          display: none;
          margin-top: 10px;
          display: block;
        }
        &.active {
          color: $black;
          &::before {
            content: "";
            width: 27px;
            height: 25px;
            background-image: url($ic-step-arrow);
            background-size: 100%;
            background-repeat: no-repeat;
            display: block;
            position: absolute;
            top: 10px;
            left: -45px;
          }
          &:first-child {
            &::before {
              display: none;
            }
          }
          .date {
            display: block;
          }
          .status-check {
            background-color: $primary;
            color: $white !important;
            .pi {
              color: $white;
            }
          }
        }
      }
    }
  }
}
.title-wrapp {
  padding: 20px;
  background-color: $white;
  display: flex;
  justify-content: space-between;
  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $black;
  }
  .btn {
    height: 38px;
  }
}
.box-info {
  background-color: $white;
  height: 100%;
  margin-right: 2px;
  @media (max-width: 767px) {
    margin-right: 0;
  }
  .header-box {
    padding: 30px;
    position: relative;
    z-index: 2;
    &.shadow-box {
      box-shadow: $shadow-3;
      z-index: 1;
    }
    h3 {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: $black;
    }
    .status {
      color: $primary;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }
    .price {
      color: $danger;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }
    .close {
      position: absolute;
      top: 25px;
      right: 20px;
      color: #000000;
      opacity: 1 !important;
      .pi {
        color: #000000;
      }
    }
  }
  .discussion-wrapp {
    .timeline-chat {
      padding: 15px;
      background-color: $grey-3;
      height: 652px;
      overflow: auto;
      .list-chat {
        .chat-topic {
          width: 100%;
          padding: 10px;
          border-radius: 10px;
          background-color: $primary-3;
          margin-top: 20px;
          &.quotation {
            background-color: $info-1;
          }
          p {
            font-size: 14px;
            line-height: 18px;
            color: $black;
            margin-bottom: 0;
            a {
              color: $black;
              font-weight: 700;
            }
          }
        }
        .time-chat {
          text-align: right;
          font-size: 12px;
          line-height: 14px;
          color: $grey-2;
          display: flex;
          justify-content: center;
          justify-content: flex-end;
          align-items: center;
          margin-top: 5px;
          span {
            margin-left: 5px;
          }
        }
        .chat-line {
          display: block;
          margin-top: 20px;
          .chat-message {
            display: inline-block;
            padding: 10px;
            border-radius: 10px;
            background-color: $info-1;
            span {
              display: block;
              font-size: 14px;
              line-height: 18px;
              color: $black;
            }
          }
          &.right {
            text-align: right;
            .chat-message {
              background-color: $primary-3;
            }
          }
        }
      }
    }
    .form-chat {
      padding: 8px;
      display: flex;
      .text-chat {
        position: relative;
        width: 100%;
        display: inline-block;
        .p-inputtext {
          border-radius: 10px;
          padding-right: 40px;
        }
        a {
          display: block;
          width: 40px;
          height: 40px;
          text-align: center;
          position: absolute;
          top: 0;
          right: 0;
          > span {
            margin-top: 10px;
          }
        }
      }
      .p-button-rounded {
        width: 53px !important;
        height: 40px !important;
        margin-left: 10px;
        display: inline-block;
        padding: 0 !important;
        @media (max-width: 767px) {
          font-size: 0.7rem;
          max-width: 40px;
        }
      }
    }
  }
  .date-info {
    display: flex;
    padding: 8px 15px;
    .date-order {
      margin-right: 30px;
      p {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: $grey-2;
      }
      span {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: $black;
      }
    }
    .date-send {
      p {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: $grey-2;
      }
      span {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: $black;
      }
    }
  }
}

.header-left {
  display: flex;
}
