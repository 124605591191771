@import "eco_variable";

.p-dialog {
  border: none !important;
  .p-dialog-header {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom: 1px solid $grey-1 !important;
    color: $black !important;
    .p-dialog-header-icon {
      &:hover {
        outline: 0 none !important;
        box-shadow: none;
      }
      &:focus {
        outline: 0 none !important;
        box-shadow: none;
      }
    }
  }
  .p-dialog-footer {
    border-top: none !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    padding-bottom: 20px !important;
  }
  &.no-header {
    width: 30vw;
    @media (max-width: 767px) {
      width: 80vw;
    }
    .p-dialog-header {
      display: none;
    }
    .p-button {
      width: 130px;
      @media (max-width: 767px) {
        font-size: 12px;
        margin: 5px 10px !important;
      }
    }
    .p-button-disabled {
      height: 40px;
      margin-left: 1.25rem;
    }
  }
  .p-dialog-content {
    border-radius: 10px;
  }
}

.invoice-wrapp {
  text-align: center;
  padding: 40px 0;
  .pdf-wrapp {
    width: 150px;
    height: 150px;
    box-shadow: $shadow-4;
    border-radius: 5px;
    padding-top: 20px;
    margin: 0 auto;
    img {
      max-width: 100%;
    }
  }
  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: $title-color;
    margin-top: 10px;
    margin-bottom: 0;
    display: block;
    width: 100%;
  }
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $grey-2;
    display: block;
    width: 100%;
  }
}

.review-wrapp {
  text-align: left;
  .pdf-wrapp {
    display: flex;
    align-items: center;
    padding: 0 20px 20px 20px;
    margin: 0 -14px 20px -14px;
    border-bottom: 1px solid $grey-1;
    img {
      max-width: 20%;
    }
  }
  p {
    font-size: 14px;
    line-height: 18px;
    color: $black;
    margin-bottom: 20px;
  }
}

.notif-alert {
  text-align: center;
  padding: 20px 0;
  @media (max-width: 767px) {
    padding: 10px 20px;
    width: 100%;
    &.p-button {
      display: flex;
      flex-direction: column;
      margin: 0 10px;
    }
    .dialog-footer {
      button {
        min-width: 50%;
      }
    }
  }
  img {
    margin-bottom: 20px;
    @media (max-width: 767px) {
      width: 60%;
    }
  }
  h3 {
    margin-bottom: 8px;
    @media (max-width: 767px) {
      size: 11px;
    }
  }
  p {
    color: $grey-6 !important;
    @media (max-width: 767px) {
      size: 8px;
    }
  }
  .p-button {
    height: 40px;
    padding: 9.5px 0px !important;
  }
}
.dialog-invite {
  .p-dialog {
    border: none;
    border-radius: 10px;
    overflow: hidden;
    .p-dialog-header {
      font-size: 18px;
      border-bottom: 1px solid $border-card;
      padding-left: 20px;
      padding-right: 20px;
    }
    .p-dialog-content {
      border-radius: 0;
      padding-left: 20px;
      padding-right: 20px;
      .invite-wrapper {
        position: relative;
        height: 100%;
        .invite-header {
          padding: 20px 0;
          display: flex;
          justify-content: space-between;
        }
        .invite-body {
          padding: 0 0 20px;
          display: flex;
          max-height: 60vh;
        }
        p {
          color: $grey-6;
          font-size: 16px;
          line-height: 28px;
        }
        .p-field {
          label {
            font-weight: 600;
          }
        }
        .filter-wrapp {
          .p-inputtext {
            height: 40px;
          }
          > p {
            font-size: 14px;
            span {
              color: $primary !important;
              font-weight: 600;
            }
          }
          &.list-company {
            .p-inputtext {
              width: unset;
            }
          }
        }
      }
      .review-wrapper {
        min-height: 80vh;
        .title-wrapp {
          font-size: 28px;
          color: #2d2d2d;
          font-weight: 700;
          padding: 0;
          margin-bottom: 20px;
          p {
            margin-bottom: 0;
          }
        }
        .image-wrapp {
          width: fit-content;
          max-width: 600px;
          height: 300px;
          position: relative;
          overflow: hidden;
          border: 1px solid #c4c4c4;
          border-radius: 8px;
          margin-bottom: 20px;
          img {
            height: 300px;
            max-width: 100%;
          }
        }
        .content-wrapp {
          p {
            color: $grey-6;
            font-size: 14px;
            line-height: 28px;
            margin-bottom: 7px;
            font-weight: 500;
          }
          img {
            max-width: 100%;
          }
        }
        .history-wrapp {
          padding-top: 20px;
          margin-top: 80px;
          border-top: 1px solid #c4c4c4;
          h6 {
            font-size: 18px;
            color: #2d2d2d;
            margin: 0 0 10px;
          }
        }
      }
    }
    .p-dialog-footer {
      padding-left: 20px;
      padding-right: 20px;
      .p-button {
        margin-right: 15px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
.dialog-preview-portfolio {
  @media (max-width: 768px) {
    width: calc(100vw - 25px) !important;
    .preview-page {
      width: calc(100vw - 50px);
    }
  }
  .p-dialog-content {
    @media (min-width: 768px) {
      height: calc(100vh - 147px);
    }
  }
}

.normal-dialog {
  .btn.btn-action {
    border-radius: 6px;
    padding: 4px !important;
  }
  .p-dialog {
    border: none;
    border-radius: 6px !important;
    overflow: hidden;
    .p-dialog-header {
      border-bottom: 1px solid $border-card !important;
    }
    .p-dialog-content {
      border-radius: 0;
      padding: 30px;
      .portfolio-file {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img {
          max-width: 100%;
        }
        .p-button {
          text-transform: uppercase;
          width: 75%;
        }
        .pagination-preview {
          p {
            margin-bottom: 0px;
          }
        }
      }
    }
    .p-dialog-footer {
      border-top: 1px solid $border-card !important;
      padding: 20px 20px;
    }
  }
  &.no-close {
    .p-dialog-header {
      .p-dialog-header-close {
        display: none;
      }
    }
  }
  &.no-border-footer {
    .p-dialog {
      .p-dialog-footer {
        padding: 8px 20px;
        border-top: none !important;
      }
    }
  }
}
.image-dialog-portfolio {
  height: 280px;
  margin: 0 auto 30px;
}

.dialog-preview {
  width: 50vw;
  border-radius: 10px !important;
  border: unset !important;
  overflow: hidden;
  .p-dialog-content {
    border-radius: unset;
  }
  .image-wraper {
    height: 400px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
      max-height: 400px;
    }
  }

  @media (max-width: 786px) {
    width: 90vw;
  }
}
