.project-confirmation {
  &.card-custom {
    .card-header {
      margin-bottom: 0px;
    }
    .card-body {
      padding: 0px;
    }
    .footer-form {
      margin-top: 0px;
    }
  }
}

.confirmation-list {
  padding: 40px 150px 40px 150px;
  border-bottom: 1px solid $border-card;
  font-family: $opensans;
  .confirmation-label {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
  }
  .confirmation-info {
    color: #a5a5a5;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .confirmation-title {
    color: #000000;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px;
  }
  .confirmation-label-right {
    text-align: right;
    color: #a5a5a5;
    font-size: 16px;
    font-weight: 600;
  }
  .confirmation-info-right {
    color: #000000;
    text-align: right;
    font-size: 14px;
    margin-bottom: 20px;
    font-weight: 600;
  }
  .confirmation-info-right-total {
    color: #0099FF;
    text-align: right;
    font-size: 30px;
    margin-bottom: 20px;
    font-weight: 600;
  }
  .confirmation-title-right {
    text-align: right;
    color: #000000;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px;
  }
}

.confirmation-list-ideation {
  // padding: 40px 26px 40px 26px;
  border-bottom: 1px solid $border-card;
  font-family: $opensans;
  .confirmation-label {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
  }
  .confirmation-info {
    color: #a5a5a5;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .confirmation-title {
    color: #000000;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px;
  }
  .confirmation-label-right {
    text-align: right;
    color: #a5a5a5;
    font-size: 16px;
    font-weight: 600;
  }
  .confirmation-info-right {
    color: #000000;
    text-align: right;
    font-size: 14px;
    margin-bottom: 20px;
    font-weight: 600;
  }
  .confirmation-info-right-total {
    color: #0099FF;
    text-align: right;
    font-size: 30px;
    margin-bottom: 20px;
    font-weight: 600;
  }
  .confirmation-title-right {
    text-align: right;
    color: #000000;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px;
  }
}

.brief-step {
  &.card {
    @media (max-width: 767px) {
      background-color: $page-bg;
    }
    .card-header {
      @media (max-width: 767px) {
        display: none !important;
      }
    }
    .card-body {
      padding-top: 0px;
      @media (max-width: 767px) {
        padding: 28px;
      }
    }
  }
}

.add-detail-brief {
  @media (max-width: 767px) {
    border-radius: 0px;
  }
  .card-body {
    @media (max-width: 767px) {
      padding: 30px 20px !important;
    }
  }
}

.helper-warning {
  background-color: #f5edcf;
  padding: 12px 17px;
  border-radius: 6px;
  color: #fd9800;
  font-weight: 500;
  align-items: center;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    display: none !important;
  }
  p {
    margin-bottom: 0px;
  }
}
