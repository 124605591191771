@import "eco_variable";

.create-article-wrapper {
  padding-top: 10px;
  @media (max-width: 767px) {
    margin-top: 80px;
  }
}

.empty-article {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 300px);
  h1 {
    font-family: $robotosans;
    margin-bottom: 20px;
  }
  img {
    margin-bottom: 20px;
  }
}
.section-progress {
  padding: 20px 0;
  border-bottom: 1px solid #eaeaea;
  &.writer {
    padding: 0;
    border-bottom: none;
  }
}
.section-article {
  padding: 20px 10px;
  border-bottom: 1px solid #eaeaea;
  overflow-y: scroll;
  max-height: 500px;
  @media (max-width: 767px) {
    padding: 20px 0;
  }
  .article-box {
    font-family: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia,
      serif !important;
    h4 {
      margin-bottom: 20px;
      font-weight: 500;
    }
  }
  .article-view-title {
    margin-bottom: 10px !important;
    font-size: 45px !important;
    font-weight: 800 !important;
    color: #2d2d2d !important;
    @media (max-width: 767px) {
      font-size: 24px !important;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      font-size: 45px !important;
      margin-bottom: 0 !important;
      font-weight: 800 !important;
      color: #2d2d2d !important;
      @media (max-width: 767px) {
        font-size: 24px !important;
      }
    }
  }
  .article-view-cover {
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
    img {
      max-width: 100%;
      max-height: 350px;
      margin: 0 auto;
    }
  }
  .article-view-content {
    font-family: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia,
      serif !important;
    color: #2d2d2d;
    img {
      max-width: 100%;
    }
    p {
      margin-bottom: 10px;
      font-size: 16px;
      color: #2d2d2d;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 6px;
      font-weight: 600;
      color: #2d2d2d;
    }
    ul,
    ol {
      padding-left: 10px;
      margin-left: 20px;
      margin-bottom: 10px;
      font-size: 16px;
      color: #2d2d2d;
    }
    h1 {
      font-size: 32px;
    }
    h2 {
      font-size: 28px;
    }
    h3 {
      font-size: 24px;
    }
    h4 {
      font-size: 20px;
    }
    h5 {
      font-size: 18px;
    }
  }
}
.section-comment {
  margin-top: 20px;
  padding: 0 0 20px;
  width: 100%;
  .comment-title {
    border-bottom: 1px solid #eaeaea;
    padding: 20px 0;
    color: #2d2d2d;
    font-size: 18px;
    font-weight: 600;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  .comment-list {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 15px 20px;
    border-bottom: 1px solid #eaeaea;
    &.pinned {
      background-color: rgba(0, 153, 255, 0.1);
    }
    @media (max-width: 767px) {
      padding: 10px 0;
      flex-direction: column;
    }

    .comment-date {
      width: 190px;
      margin-right: 10px;
      margin-top: 15px;
      color: #898989;
      @media (max-width: 767px) {
        width: 100%;
        margin: 0 0 10px;
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
      }
    }
    .comment-content {
      width: calc(100% - 300px);
      @media (max-width: 767px) {
        width: 100%;
      }
      .comment-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #2d2d2d;
        font-size: 16px;
        font-weight: 600;
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
      .chat-avatar {
        border-radius: 100%;
        width: 40px;
        height: 40px;
        margin-right: 20px;
        &.writer {
          background-color: #17ad49;
        }
        &.pm {
          background-color: #fdc800;
        }
      }
      .comment-text {
        padding-left: 60px;
        font-size: 14px;
        color: #2d2d2d;
        margin-top: 5px;
        @media (max-width: 767px) {
          margin-top: 0;
        }
        &.blue {
          color: #006bb3;
          font-weight: 600;
        }
      }
      .comment-user {
        span {
          color: #898989;
          font-weight: 500;
          text-transform: capitalize;
        }
      }
      .comment-image {
        img {
          height: 108px;
          width: 108px;
          border-radius: 6px;
          margin: 20px 0px 6px 0px;
          object-fit: cover;
          border: 1px solid $border-card;
        }
        span {
          margin-left: 5px;
          color: $grey-6;
        }
      }
    }
    .comment-action {
      width: 100px;

      @media (max-width: 767px) {
        width: 100%;
        margin: 0 0 10px;
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
      }
      .unpinned-button {
        color: #006bb3;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }
  .comment-box {
    width: 100%;
    background-color: #fafafa;
    border: 1px solid #c4c4c4;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    @media (max-width: 767px) {
      padding: 10px;
    }
    &.help {
      .textarea-without-toolbar {
        margin-bottom: 0px;
        .ql-editor {
          border-radius: 8px 8px 0px 0px !important;
          border-bottom: 0px;
        }
      }
      .editor-wrapp {
        aside {
          margin-top: 0px !important;
        }
      }
      .image-comment-wrapper {
        width: 100%;
        background-color: #ffffff;
        padding: 0px 0px 10px 20px;
        border-left: 1px solid #ced4da;
        border-right: 1px solid #ced4da;
        .image-thumb-wrapp {
          width: 108px;
          .image-thumb {
            width: 108px;
            height: 108px;
          }
          img {
            width: 108px;
            height: 108px;
          }
        }
      }
      .comment-upload {
        background-color: white;
        border-radius: 0px 0px 8px 8px;
        border: 1px solid #ced4da;
        border-top: 0px;
        margin-bottom: 20px;
        padding: 0px 20px 0px 20px;
        .box-input-file-error {
          position: absolute;
          margin-bottom: 3px;
          right: -5px;
          width: 500px;
        }
        .comment-upload-content {
          padding: 12px 0px 5px 0px;
          border-top: 1px solid #ced4da;
        }
        p {
          margin-right: 25px;
          font-size: 14px;
          span {
            color: $primary;
            font-weight: 600;
          }
        }
      }
    }
    label {
      font-size: 14px;
      color: #2d2d2d;
      margin-bottom: 15px;
      font-weight: 600;
      @media (max-width: 767px) {
        margin-bottom: 10px;
      }
    }
    textarea {
      resize: none;
      height: 150px;
      border-color: #c4c4c4;
      margin-bottom: 15px;
      padding: 15px;
      font-size: 14px;
      color: #2d2d2d;
      @media (max-width: 767px) {
        margin-bottom: 10px;
        height: 100px;
      }
    }
    button {
      width: fit-content !important;
    }
  }
}
.section-note {
  padding: 15px 0;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 16px;
    color: #2d2d2d;
    font-weight: 400;
    margin: 0;
    strong {
      color: #2d2d2d;
      font-weight: 600;
    }
  }
  .write-count {
    font-size: 16px;
    color: #2d2d2d;
    margin-right: 20px;
    font-weight: 400;
    strong {
      color: #006bb3;
    }
  }
}

.section-writer {
  width: 100%;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    margin-bottom: 0px;
  }
  .p-datatable {
    .p-datatable-tbody {
      > tr {
        > td {
          border-color: #eaeaea !important;
        }
      }
    }
  }
  .empty-winner {
    font-size: 14px;
    font-weight: 400;
    margin-top: 15px;
  }
}

.section-writer-head {
  font-size: 16px;
  font-weight: 600;
  color: #2d2d2d;
}
.winner-list {
  margin-top: 15px;
  padding-left: 20px;
  li {
    font-size: 14px;
    font-weight: 400;
    color: #2d2d2d;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.preview-article-page {
  position: relative;
  padding-bottom: 144px;
  .card-header {
    &.preview-article {
      @media (max-width: 767px) {
        display: none !important;
      }
    }
    &.approval-release {
      @media (max-width: 767px) {
        position: relative;
        bottom: 0;
        width: 100%;
        display: block !important;
        .project-title {
          display: none !important;
        }
        > div {
          width: 100%;
          display: block !important;
        }
        .approval-buttons {
          width: 100%;
          flex-direction: column-reverse;
          .p-button {
            min-width: 100%;
            width: 100%;
          }
          .p-button:nth-child(1) {
            margin: 15px 0px 0px 0px !important;
          }
        }
      }
    }
  }
}

.role-tab {
  border-top: 1px solid $border-card;
  border-bottom: 1px solid $border-card;
  .section-writer-head {
    padding: 20px 0px;
    font-size: 16px;
    font-weight: 500;
    color: $grey-light;
    font-family: $robotosans;
    margin-right: 76px;
    cursor: pointer;
    @media (max-width: 767px) {
      margin-right: 25px;
      padding: 0px 0px 20px 0px;
    }
    &.active {
      color: $primary;
      border-bottom: 2px solid $primary;
    }
    &.pointer {
      cursor: default;
      color: #2d2d2d;
    }
  }
}

// User View

.box-content {
  &.box-create {
    width: 100%;
    max-width: 1280px !important;
    margin: 0 auto;
    height: auto;
    overflow: hidden;
    position: relative;
    @media (max-width: 1281px) {
      max-width: 1024px !important;
    }

    .card-header {
      border-bottom: 1px solid #e4e4e4;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &.p-jc-center {
        justify-content: center !important;
      }
    }
  }
}

.article-preview {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  background-color: #ffffff;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  .section-article-header {
    width: 100%;
    position: relative;
    .form-title {
      position: relative;
      .upload-image-cover {
        height: 35px;
        width: 35px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        .cover-icon {
          width: 35px;
          height: 35px;
        }
      }
    }
  }
}

.section-article {
  &.no-scroll {
    width: 60%;
    padding: 20px 0;
    border-bottom: unset;
    max-height: unset;
    overflow-y: unset;
    position: relative;
    height: max-content;
    border-right: 1px solid #e4e4e4;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  &.full-no-scroll {
    width: 100%;
    padding: 20px 0;
    border-bottom: unset;
    max-height: unset;
    overflow-y: unset;
    position: relative;
    height: max-content;
  }
  .section-article-header {
    padding: 0 20px;
  }
  .section-article-body {
    padding: 20px 0;
    min-height: 632px;
    .form-write {
      width: 100%;
    }
  }
  .section-article-footer {
    padding: 20px 20px 0;
    border-top: 1px solid #e4e4e4;
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

.section-comment-writer {
  width: 40%;

  @media (max-width: 767px) {
    width: 100%;
    border-left: unset;
  }
}
.comment-writer-head {
  font-size: 18px;
  font-weight: 600;
  color: #2d2d2d;
  padding: 10px 20px;
  border-bottom: 1px solid #e4e4e4;
}
.comment-writer-body {
  border-bottom: 1px solid #e4e4e4;
  &.detail {
    padding: 20px;
  }
  .comment-writer-label {
    font-size: 14px;
    color: #808080;
    margin-bottom: 5px;
  }
  .comment-writer-text {
    font-size: 14px;
    color: #2d2d2d;
    font-weight: 600;
    margin-bottom: 10px;
  }
  &.note-pm {
    overflow-y: scroll;
    position: relative;
    min-height: 236px;
    max-height: 236px;
    border: unset;
  }
  &.comment-wrap {
    overflow-y: scroll;
    position: relative;
    min-height: 236px;
    max-height: 236px;
    border: unset;
    @media (max-width: 767px) {
      min-height: 60px;
    }
  }
  &.fit-content {
    overflow-y: scroll;
    position: relative;
    max-height: 569px;
    border: unset;
  }
}
.comment-writer-box {
  width: 100%;
  background-color: #fafafa;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  padding: 20px;
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    padding: 10px;
  }
  label {
    font-size: 14px;
    color: #2d2d2d;
    margin-bottom: 8px;
    font-weight: 600;
    @media (max-width: 767px) {
      margin-bottom: 5px;
    }
  }
  textarea {
    resize: none;
    height: 100px;
    border-color: #c4c4c4;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 14px;
    color: #2d2d2d;
    @media (max-width: 767px) {
      margin-bottom: 10px;
      height: 70px;
    }
  }
  button {
    width: fit-content !important;
  }
}

.comment-list-wraper {
  width: 100%;
  height: auto;
  .comment-list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(100% - 20px);
    padding: 15px 15px;
    margin: 0 10px;
    border-bottom: 1px solid #eaeaea;
    &.pinned {
      background-color: rgba(0, 153, 255, 0.1);
      position: relative;
    }
    &.empty {
      border-bottom: unset;
      justify-content: center;
      align-items: center;
      height: 236px;
    }
  }
  .comment-image {
    margin-right: 20px;
    .chat-avatar {
      border-radius: 100%;
      width: 40px;
      height: 40px;
      &.writer {
        background-color: #17ad49;
      }
      &.pm {
        background-color: #fdc800;
      }
    }
  }
  .comment-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .comment-action {
    position: absolute;
    right: 20px;
    top: 7px;
    color: #006bb3;
    font-weight: 600;
  }
  .comment-text {
    font-size: 14px;
    color: #2d2d2d;
    margin-bottom: 5px;
    &.blue {
      color: #006bb3;
      font-weight: 600;
    }
  }
  .comment-user {
    margin: 7px 0 10px;
    font-size: 16px;
    color: #2d2d2d;
    font-weight: 600;
    span {
      color: #898989;
      font-weight: 500;
      text-transform: capitalize;
    }
  }
  .comment-date {
    color: #898989;
    width: 100%;
    font-size: 14px;
  }
}

.section-article-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .write-note {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 767px) {
      width: 100%;
      justify-content: space-between;
      margin-bottom: 15px;
    }
  }
  .write-count {
    font-size: 16px;
    color: #2d2d2d;
    margin-right: 20px;
    strong {
      color: #006bb3;
    }
  }
  .write-button {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    @media (max-width: 767px) {
      width: 100%;
      justify-content: space-between;
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      vertical-align: unset;
      @media (max-width: 767px) {
        width: calc(50% - 10px) !important;
        .p-button-label {
          display: none !important;
        }
        &:first-child {
          margin-right: 10px !important;
        }
        &:last-child {
          margin-left: 10px !important;
        }
      }
    }
  }
}

.section-article-box {
  .empty-article {
    height: 560px;
  }
  &.preview {
    min-height: 705px;
    max-height: 705px;
    padding-left: 20px;
    padding-right: 20px;
    overflow-y: scroll;
    color: #2d2d2d;
  }
  &.no-scroll {
    min-height: 560px;
    max-height: unset;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.pinned-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  label {
    margin-bottom: 0 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    margin-left: 10px !important;
  }
}

.message-inner {
  p {
    margin-bottom: 5px;
    font-size: 14px;
  }
  ul,
  ol {
    padding-left: 15px;
  }
}

.mobile-preview-header {
  display: none;
  @media (max-width: 767px) {
    display: block;
    background-color: white;
    padding: 25px 16px 30px 16px;
    span {
      font-weight: 600;
      margin-right: 10px;
    }
  }
}
