@import "eco_variable";

.chat-wrapper {
  height: calc(100vh - 105px);
  &.chat-pm {
    height: calc(100vh - 175px);
    width: 100%;
    display: flex;
    justify-content: center;
    .p-grid {
      @media (min-width: 1440px) {
        width: 1280px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .empty-state-wrapper {
    @media (max-width: 767px) {
      display: none;
    }
  }
  .box-info {
    background-color: $white;
    height: 100%;
    margin-right: 2px;
    &.empty-state {
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 767px) {
        display: none !important;
      }
    }
    .header-box {
      padding: 30px;
      position: relative;
      height: 97px;
      z-index: 2;
      &.shadow-box {
        box-shadow: $shadow-3;
        z-index: 1;
      }
      h3 {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $black;
      }
      .dropdown {
        position: absolute;
        top: 30px;
        right: 20px;
      }
      .pict {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: $primary-dark;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        overflow: hidden;
        position: relative;
        span {
          font-size: 24px;
          font-weight: 700;
          color: $white;
        }
        img {
          height: 40px;
          min-width: 100%;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
  .table-order {
    padding-bottom: 20px;
    border-bottom: 4px solid $grey-4;
    margin-bottom: 20px;
    .p-button {
      margin-bottom: 10px;
      &.p-button-text {
        background-color: transparent !important;
        padding: 0 !important;
        text-decoration: underline;
        &:focus {
          background-color: transparent !important;
          outline: 0 none !important;
          box-shadow: none;
        }
      }
    }
    .p-datatable {
      .p-datatable-thead {
        th {
          color: $grey-2;
          padding: 8px 15px;
          &:last-child {
            width: 20%;
          }
        }
      }
      .p-datatable-tbody {
        td {
          color: $black;
          font-size: 14px;
          padding: 8px 15px;
          &:nth-child(2) {
            color: $grey-2;
            font-weight: 500;
          }

          &:last-child {
            font-weight: 500;
            text-align: right;
          }
        }
        > tr {
          &:last-child {
            border-bottom: 1px solid #dee2e6;
          }
        }
      }
    }
    .date-info {
      display: flex;
      padding: 8px 15px;
      .date-order {
        margin-right: 30px;
        p {
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: $grey-2;
        }
        span {
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: $black;
        }
      }
      .date-send {
        p {
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: $grey-2;
        }
        span {
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: $black;
        }
      }
    }
  }
  .formulir-wrapp {
    padding: 15px;
    .file-upload {
      margin-bottom: 20px;
      > div {
        position: relative;
      }
      label {
        font-weight: 700;
      }
      .clear-field {
        display: block;
        width: 13px;
        height: 13px;
        background-image: url($ic-close);
        background-repeat: no-repeat;
        background-size: 100%;
        position: absolute;
        top: 14px;
        right: 10px;
        z-index: 1;
      }
      .p-fileupload {
        .p-button {
          background-color: $white;
          border: 1px solid #ced4da;
          border-radius: 10px;
          height: 40px;
          padding: 10px !important;
          &:focus {
            outline: 0 none !important;
            box-shadow: none;
          }
          .pi-plus {
            display: none;
          }
          .p-button-label {
            color: $primary;
            text-align: left;
            font-weight: 700;
          }
          .p-button-icon {
            &.pi-upload {
              width: 16px;
              height: 21px;
              background-image: url($ic-file-o);
              background-size: 100%;
              background-repeat: no-repeat;
              &::before {
                content: "";
              }
            }
          }
        }
      }
    }

    .p-field {
      label {
        font-weight: 700;
      }
    }
    .p-field-checkbox {
      margin-top: 13px;
      line-height: 21px;
      color: $black;
      label {
        margin-top: 13px;
      }
    }
    .p-button {
      width: 100%;
    }
  }
}

.filter-list {
  display: flex;
  justify-content: center;
  .search-list {
    .pi {
      margin-right: 2px;
    }
  }
}
.box-inner-right {
  height: calc(100vh - 201px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  &.chat-pm {
    height: calc(100vh - 275px);
  }
  @media (max-width: 767px) {
    height: calc(100vh - 150px);
    &.chat-pm {
      height: calc(100vh - 150px);
    }
  }
  .detail-project {
    p {
      color: $title-color;
      font-size: 16px;
      word-break: break-all;
      a {
        color: $title-color;
        font-weight: 600;
      }
    }
    .publish-date {
      padding: 20px 0;
      font-size: 14px;
      color: $grey-5;
      border-top: 1px solid $border-card;
      border-bottom: 1px solid $border-card;
    }
    .preview-article {
      padding: 20px 0;
      border-bottom: 1px solid $border-card;
      > a {
        display: flex;
        font-weight: 600;
        color: $primary;
        font-size: 16px;
        align-items: center;
        width: fit-content;
        > span {
          margin-right: 10px;
        }
      }
    }
    .detail-writer {
      margin-bottom: 7px;
      p {
        margin-bottom: 3px;
      }
    }
  }
  .button-project {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    margin: 40px 0 0;
    .p-button {
      width: 75%;
      margin: 5px 0;
      display: flex;
      justify-content: center;
      > .p-button-label {
        display: none;
      }
      &:focus,
      &:hover,
      &:active {
        outline: none;
        box-shadow: unset;
      }
    }
  }
}
.inboxlist {
  height: calc(100vh - 201px);
  overflow-y: auto;
  overflow-x: hidden;
  @media (max-width: 767px) {
    height: calc(100vh - 150px);
  }
  &.chat-pm {
    height: calc(100vh - 275px);
  }
  .card {
    border-radius: 0;
    border: none;
  }
  .inbox-item {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    width: 100%;
    padding: 20px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    cursor: pointer;

    .pict-container {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      position: relative;
      overflow: hidden;
      border: 1px solid #ddd;
      .image-profile {
        height: 40px;
        min-width: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    @media (max-width: 767px) {
      border-top: 1px solid #eaeaea;
      &:last-child {
        border-bottom: 1px solid #eaeaea;
      }
      .pict-container {
        width: 50px;
        height: 50px;
        margin-right: 20px;
        .image-profile {
          height: 50px;
        }
      }
    }
    .inbox-item-detail {
      flex: 1 1 0;
      .project-info {
        display: flex;
        justify-content: space-between;
      }
      @media (max-width: 767px) {
        justify-content: space-between;
      }
      h5 {
        font-weight: 600;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 767px) {
          margin-bottom: 7px;
        }
        span {
          display: block;
          width: 10px;
          height: 10px;
          border-radius: 100%;
          background-color: $text-rev;
        }
      }
    }
    .inbox-item-deadline {
      height: 20px;
      width: 20px;
      border-radius: 12px;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      &.danger {
        background-color: $rejected;
      }
      &.success {
        background-color: $verified;
      }
      &.warning {
        background-color: $waiting;
      }
    }
    &.active,
    &:hover,
    &:focus {
      background-color: $grey-3;
    }
  }
}
.discussion-wrapper {
  height: calc(100vh - 200px);
  background-color: $grey-3;
  position: relative;
  overflow: hidden;
  @media (max-width: 767px) {
    height: calc(100vh - 150px);
  }
  &.chat-pm {
    height: calc(100vh - 275px);
  }
  .discussion-box {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .discussion-message {
    padding: 20px 20px 90px 20px;

    .welcome {
      padding: 20px 0;
      text-align: center;
      color: $grey-6;
      font-size: 10px;
      @media (max-width: 767px) {
        font-size: 12px !important;
      }
    }
    .date-wrapper {
      display: flex;
      justify-content: center;
      .date {
        text-transform: uppercase;
        color: $grey-6;
        font-size: 10px;
        background-color: $grey-7;
        display: inline-block;
        padding: 5px 20px;
        border-radius: 40px;
        @media (max-width: 767px) {
          font-size: 12px !important;
        }
      }
    }
    .status-wrapper {
      display: flex;
      justify-content: center;
      margin: 15px 0 0;
      .status {
        padding: 5px 20px;
        border-radius: 40px;
        font-size: 14px;
        font-weight: 400;
        @media (max-width: 767px) {
          font-size: 12px !important;
        }
        &.winner {
          background-color: #cfffec;
          color: #1f7351;
        }
        &.reject {
          background-color: rgba(251, 10, 42, 0.2);
          color: #fb0a2a;
        }
      }
    }
    .message-block {
      display: block;
      width: 100%;
      margin-top: 40px;
      .msg-wrapper {
        max-width: 90%;
        position: relative;
        padding-left: 20px;
        padding-right: 35px;
        display: inline-block;
        @media (max-width: 767px) {
          max-width: 100% !important;
        }
        .pict {
          width: 38px;
          height: 38px;
          border-radius: 100%;
          background-color: $primary-dark;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          position: absolute;
          overflow: hidden;
          left: 0;
          bottom: 0;
          span {
            font-size: 24px;
            font-weight: 700;
            color: $white;
          }
          img {
            max-width: 100%;
          }
        }
        .msg-box {
          background-color: $white;
          border-radius: 12px;
          border-bottom-left-radius: 0;
          padding: 5px;
          color: $title-color;
          font-size: 14px;
          line-height: 21px;
          width: 100%;
          margin-bottom: 10px;
          position: relative;
          word-break: break-all;
          &:last-child {
            margin-bottom: 0;
          }
          img {
            width: 100%;
            border-radius: 12px;
          }
          .msg-time {
            display: flex;
            align-items: center;
            margin-top: 7px;
            span {
              &.date {
                margin-right: 5px;
              }
              display: block;
              font-size: 10px;
              color: $grey-5;
            }
          }
        }
      }
      &.right {
        text-align: right;
        .msg-wrapper {
          padding-left: 35px;
          padding-right: 20px;
          .pict {
            left: initial;
            right: 0;
            margin-right: 0;
            margin-left: 10px;
          }
          .msg-box {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 12px;
            background-color: $msg-bg;
            text-align: left;
            word-break: break-all;
            @media (max-width: 767px) {
              padding: 5px 10px;
            }
            img {
              width: 100%;
              border-radius: 12px;
            }
            .msg-time {
              margin-top: 7px;
              display: flex;
              align-items: center;
              span {
                &.date {
                  margin-right: 5px;
                }
              }
              &.icon-read,
              &.icon-unread {
                margin-left: 12px;
              }
            }
          }
        }
      }
    }
    .preview-images-wrap {
      width: calc(100% - 40px);
      padding: 10px 20px 20px;
      position: absolute;
      background-color: $white;
      left: 20px;
      bottom: 65px;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .preview-images-header {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 10px 0;
        .close {
          color: #808080;
        }
      }
      .preview-images {
        position: relative;
        height: 200px;
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        background-color: #fff;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .form-chat {
      width: calc(100% - 40px);
      height: 50px;
      border-radius: 6px;
      background-color: $white;
      position: absolute;
      left: 20px;
      bottom: 15px;
      z-index: 2;
      padding-right: 150px;
      &::before {
        content: "";
        width: 100%;
        height: 45px;
        background-color: $grey-3;
        position: absolute;
        left: 0;
        bottom: -45px;
        z-index: 2;
      }
      .p-inputtextarea,
      .p-inputtext {
        border-radius: 6px;
        height: 50px;
        border: none;
        resize: none;
        padding: 15px;
        &:focus,
        &:focus-within {
          outline: none;
          box-shadow: none;
        }
        @media (max-width: 767px) {
          width: 70vw;
          font-size: 14px;
        }
      }
      .chat-attr {
        position: absolute;
        top: 8px;
        right: 8px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .btn-send {
          width: 34px;
          height: 34px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          border: none;
          background-color: $primary;
        }
        .btn-img {
          display: inline-block;
          border: none;
          background-color: transparent;
          margin-right: 8px;
        }
        .btn-document {
          display: inline-block;
          border: none;
          background-color: transparent;
          margin-right: 8px;
        }
      }
    }
  }
}
.user-progress {
  margin-top: 20px;
  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    li {
      position: relative;
      padding-left: 40px;
      margin-bottom: 30px;

      &::before {
        content: "";
        display: block;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        background-color: $border-card;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
      &::after {
        content: "";
        width: 1px;
        height: 50px;
        border-left: 1px dashed $border-card;
        position: absolute;
        top: -50px;
        left: 15.5px;
      }
      &:first-child {
        &::after {
          display: none;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      p {
        margin-bottom: 0;
        font-weight: 600;
        color: $grey-light !important;
      }
      span {
        @media (max-width: 767px) {
          font-size: 14px !important;
        }
      }
      &.active {
        p {
          color: $primary !important;
        }
        &::before {
          background-color: $primary;
        }
        &::after {
          border-left: 1px solid $primary;
        }
      }
    }
  }
}

.chat-avatar {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #fff;
  background-color: #006bb3;
  font-weight: 600;
  text-transform: uppercase;
}
