@import "eco_variable";

.user-info-box {
  width: 100%;
  max-width: 600px;
  display: flex;
  .pict {
    width: 156px;
    height: 156px;
    overflow: hidden;
    margin-right: 20px;
    border: 1px solid #c4c4c4;
    border-radius: 8px;
    position: relative;
    img {
      height: 156px;
      min-width: 100%;
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
  .brief-wrapper {
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .pict-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      @media (max-width: 768px) {
        display: flex;
        justify-content: center;
        width: calc(100vw - 25px - 40px);
        margin-left: 0px !important;
      }
    }
    .pict {
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 110px;
      font-weight: 600;
      span {
        background-color: $primary;
        height: 130px;
        width: 130px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $robotosans;
        @media (max-width: 768px) {
          font-size: 110px;
        }
      }
    }
  }
  .desc-info {
    h3 {
      font-size: 16px;
      font-weight: 700;
      color: $title-color;
    }
    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      li {
        display: block;
        position: relative;
        color: $grey-6;
        font-size: 14px;
        margin-bottom: 10px;
        padding-left: 35px;
        span {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
  &.vert {
    max-width: inherit;
    display: block;
    .pict {
      width: 120px;
      height: 120px;
      overflow: hidden;
      margin-right: 0;
      border: none;
      border-radius: 100%;
      position: relative;
      margin: 0 auto;
      border: 1px solid #c4c4c4;
      img {
        height: 120px;
        min-width: 100%;
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        transform: translateX(-50%);
      }
    }
    h3 {
      font-size: 18px;
      font-weight: 600;
      color: $title-color;
      text-align: left;
      margin: 10px 0;
      @media (max-width: 768px) {
        text-align: center;
        margin-bottom: 10px;
      }
    }
    .desc-info {
      @media (min-width: 768px) {
        min-width: 70%;
        margin-left: 20px !important;
        margin-top: -10px;
      }
      &.profile {
        ul {
          @media (max-width: 767px) {
            padding-top: 10px;
            border-top: 1px solid $grey-light;
          }
        }
        li {
          color: $title-color;
        }
      }
    }
    .edit-profile-icon {
      @media (min-width: 768px) {
        margin-top: -10px;
      }
      @media (max-width: 767px) {
        position: absolute;
        top: 0px;
        right: 22px;
      }
    }
  }
  &.pm {
    padding-bottom: 15px;
    .pict {
      width: 130px;
      height: 130px;
      overflow: hidden;
      margin-right: 20px;
      border: 1px solid #c4c4c4;
      position: relative;
      border-radius: 50%;
      @media (max-width: 768px) {
        margin-right: 0px !important;
      }
      img {
        height: 130px;
        min-width: 100%;
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        transform: translateX(-50%);
      }
    }
    .desc-info {
      h3 {
        font-size: 16px;
        font-weight: 700;
        color: $title-color;
        @media (max-width: 768px) {
          text-align: center;
          font-size: 18px !important;
          border-bottom: 1px solid $grey-light;
          padding-bottom: 15px;
          margin-bottom: -10px;
        }
      }
      ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        li {
          display: block;
          position: relative;
          color: $grey-6;
          font-size: 14px;
          margin-bottom: 10px;
          padding-left: 35px;
          span {
            position: absolute;
            top: 0;
            left: 0;
          }
          &:first-child() {
            padding-left: 0;
            margin-bottom: 30px;
            margin-top: 10px;
          }
        }
      }
    }
  }
  &.applicant {
    .letter-pics {
      background-color: $primary;
      font-size: 110px;
      height: 156px;
      width: 156px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
    }
  }
}
.user-attr {
  &.writer {
    margin-top: -10px;
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .attr-info {
    p {
      color: $grey-6;
      font-size: 14px;
    }
  }
}
.biography {
  padding: 30px 0;
  border-top: 1px solid $border-card;
  h3 {
    font-size: 18px;
    font-weight: 700;
    color: $title-color;
  }
  p {
    color: $grey-6;
    font-size: 14px;
  }
}
.biography-text {
  p {
    color: $grey-6;
    font-size: 14px;
  }
}

.footer-action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 30px 0;
  border-top: 1px solid $border-card;
  .p-button {
    margin-left: 15px;
  }
}

.col-sm-12 {
  &.update-setting {
    @media (max-width: 767px) {
      padding: 0px;
      border-radius: 0px;
      margin-top: 0px !important;
    }
  }
}

.card-title {
  &.update-setting {
    span {
      font-family: $opensans;
      position: relative;
      top: 2px;
      @media (max-width: 767px) {
        top: 0px !important;
      }
    }
  }
}
.card-body {
  &.setting {
    p {
      font-size: 14px;
    }
  }
  &.detail-release {
    @media (max-width: 767px) {
      padding: 0px !important;
    }
  }
  &.list-pic {
    @media (max-width: 767px) {
      padding: 0px 0px 20px 0px !important;
      background-color: $grey-3;
      .mobile-table {
        position: relative;
        top: 60px;
      }
      .data-empty-wrap {
        position: relative;
        top: 60px;
      }
    }
    .pic-filter {
      display: flex;
      justify-content: space-between;
      @media (max-width: 767px) {
        padding: 12.5px;
        position: fixed;
        top: 67px;
        left: 0;
        background-color: white;
        width: 100%;
        z-index: 3;
        .filter-wrapp {
          margin: 0px;
          width: calc(100vw - 135px);
          max-width: 230px;
        }
        .filter {
          margin-bottom: 0px;
        }
        #search-help {
          display: none !important;
        }
        .p-button {
          padding: 10px !important;
          font-size: 12px !important;
        }
      }
    }
  }
  &.card-pm {
    min-height: calc(100vh - 350px);
    padding: 0 !important;
    display: flex;
    justify-content: center;
  }
}

.form-update-setting {
  font-family: $opensans;
  padding: 18px 30px 0px 30px;
  @media (max-width: 767px) {
    padding: 20px 16px 30px 16px;
  }
  .image-card {
    margin-bottom: 40px;
  }
  .text-card {
    font-size: 16px;
    color: #808080;
    margin-bottom: 0;
    font-weight: 600 !important;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  label {
    font-weight: 600 !important;
    font-size: 16px;
    color: $title-color;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  .p-disabled {
    background-color: #eaeaea;
  }
  .p-inputtext {
    margin-bottom: 0px;
    @media (max-width: 767px) {
      height: 45px;
      border-radius: 6px !important;
      font-size: 14px;
    }
    &.input-withdraw {
      font-size: 18px;
      font-weight: 600;
    }
  }
  .p-field {
    padding-bottom: 20px;
    margin-bottom: 0 !important;
  }
  .old-password {
    border-bottom: 1px solid $border-card;
    margin-bottom: 0 !important;
    @media (max-width: 767px) {
      border-bottom: 0px;
      margin-bottom: 0px !important;
    }
    label {
      display: flex !important;
      justify-content: space-between;
      .show-password {
        display: flex !important;
      }
      @media (max-width: 767px) {
        flex-direction: column-reverse;
        .show-password {
          margin-bottom: 10px;
          display: none !important;
        }
      }
    }
  }
  .p-dropdown {
    height: 50px;
    .p-dropdown-label.p-placeholder,
    .p-dropdown-label.p-inputtext {
      height: 50px;
    }
    @media (max-width: 767px) {
      height: 45px;
      border-radius: 6px !important;
      font-size: 14px;
    }
  }
  .new-password-label {
    display: flex;
    justify-content: space-between;
    @media (max-width: 767px) {
      flex-direction: column-reverse;
      .show-password {
        margin-bottom: 10px;
      }
    }
    span {
      font-size: 16px;
    }
  }
}
.udpate-setting-footer {
  padding: 42px 30px;
  button {
    font-weight: 600 !important;
    font-family: $opensans;
    margin-left: 20px;
  }
  &.client {
    @media (max-width: 767px) {
      padding: 30px 16px;
      position: absolute;
      bottom: 0px;
      width: 100%;
    }
    .p-button-outlined {
      @media (max-width: 767px) {
        display: none;
      }
    }
    .p-button-primary {
      @media (max-width: 767px) {
        margin-left: 0px;
        width: 100%;
      }
    }
  }
}

.update-setting-dialog {
  width: 40vw;
  .p-dialog-footer {
    .p-button {
      font-size: 14px;
      font-family: $opensans;
      font-weight: 600 !important;
    }
  }
  &.p-dialog {
    &.p-component {
      &.confirmation {
        .confirm-note {
          font-size: 14px;
          width: 100%;
          text-align: center;
          margin-bottom: 20px;
          color: #808080;
        }
        .p-dialog-content {
          padding: 20px 30px 0px 30px !important;
          .form-setting {
            padding: 0px !important;
          }
        }
        .p-dialog-footer {
          padding: 0px 20px 20px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    width: calc(100vw - 25px);
    &.password {
      .p-dialog-content {
        padding: 42px 12px 5px 12px !important;
      }
    }
    .form-setting {
      padding: 0px 5px !important;
      .p-inputtext {
        height: 40px;
        font-size: 14px;
        padding: 6px 10px;
      }
      .p-disabled {
        background-color: $border-card;
        color: $title-color;
      }
    }
    .otp-wrapp {
      .p-inputtext {
        width: calc(100% - 4px) !important;
      }
    }
    .p-dialog-content {
      padding: 12px 12px 5px 12px !important;
    }
    .p-dialog-footer {
      &.phone {
        padding: 8px 0px 20px 0px !important;
      }
      .p-button {
        width: calc((100% - 18px) / 2);
        font-size: 14px !important;
        padding: 9px 0px !important;
        max-height: 41px;
      }
    }
  }
}
.notif-settings {
  position: relative;
  padding-left: 50px;
  display: block !important;
  p {
    color: $grey-6;
  }
  @media (max-width: 767px) {
    p {
      font-size: 14px;
    }
  }
  .notif-icon {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $verified;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      color: $white;
      font-size: 18px;
      display: inline-block;
      width: 20px;
    }
  }
  label {
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: $title-color;
    padding: 0;
    margin-left: 0;
  }
}
.form-setting {
  padding-top: 20px;
  .p-field {
    margin-bottom: 20px;
    position: relative;
    .show-password {
      position: absolute;
      top: 0;
      right: 0;
      @media (max-width: 767px) {
        top: -32px !important;
        label {
          font-size: 14px;
          position: relative;
          top: 2px;
        }
        .p-checkbox {
          height: 18px !important;
          width: 18px !important;
        }
      }
      label {
        font-weight: 400;
        margin-left: 3px;
        color: $grey-6;
      }
    }
    label {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .otp-wrapper {
    width: 100%;
    max-width: 330px;
    margin: 0 auto;
    p {
      color: $grey-5;
      margin-bottom: 40px;
    }
    .p-field {
      .p-button {
        text-transform: uppercase;
        width: 100%;
        margin-top: 30px;
      }
      .time {
        font-size: 14px;
        font-weight: 19px;
        color: $grey-5;
      }
    }
    .footer-otp {
      padding: 20px 0;
      text-align: center;
      border-top: 1px solid $border-card;
      p {
        color: $grey-5;
        a {
          font-weight: 500;
          color: $primary;
        }
      }
    }
  }
  .otp-wrapp {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
    .p-inputtext {
      border-radius: 0 !important;
      border-top: none;
      border-left: none;
      border-right: none;
      border-width: 2px;
      width: 39px;
      font-size: 48px;
      font-weight: 600;
      padding: 8px 0 !important;
      margin: 0 2.5px 5px;
      &:focus {
        outline: 0 none !important;
        box-shadow: none;
      }
    }
  }
}

.empty-bank-account {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0 60px;
  img {
    max-width: 150px;
    width: 100%;
    height: auto;
  }
  > p {
    font-size: 16px;
    color: #2d2d2d;
    font-weight: 600;
    margin: 30px 0;
    text-align: center;
  }
  button {
    width: 50%;
  }
}

.info-withdraw {
  padding: 0 0 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eaeaea;
  p {
    font-size: 16px;
    color: #2d2d2d;
    font-weight: 600;
    margin-bottom: 5px;
  }
  h3 {
    font-size: 24px;
    color: #2d2d2d;
    font-weight: 600;
  }
  a {
    font-size: 14px;
    font-weight: 600;
    color: #0099ff;
    text-decoration: unset;
    &:hover {
      color: #0099ff;
    }
  }
}
.label-withdraw {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  .p-field-checkbox {
    margin-bottom: 0;
    label {
      color: #808080;
      font-weight: 400;
    }
  }
}
