@import "eco_variable";

.footer-menu {
  font-family: $montserrat;
  background-color: $bg-footer;
  padding: 50px 0;
  @media (max-width: 767px) {
    padding: 25px 15px 10px 15px;
    font-size: 14px;
  }

  .footer-section {
    display: flex;
    flex-direction: column;
    margin-top: 37px;
    @media (max-width: 767px) {
      margin-top: 0px;
    }
  }

  address {
    width: 100%;
    display: block;
    margin-top: 20px;
    h3 {
      color: $white;
      font-size: 18px;
      font-weight: 700;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      color: $white;
    }
    @media (max-width: 767px) {
      margin: 0px 0px 5px;
      p {
        font-size: 14px !important;
        margin-bottom: 0px !important;
        text-align: center;
      }
    }
  }
}
.footer-landing {
  font-family: $montserrat;
  background-color: $bg-footer2;
  padding: 10px 0;
  @media (max-width: 767px) {
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      text-align: center;
    }
  }
  p {
    color: $white;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
  }
}

.footer-logo {
  display: block;
  width: 135px;
  height: 80px;
  overflow: hidden;
  margin: 0 0;
  position: relative;
  @media (max-width: 767px) {
    display: flex !important;
    justify-content: center;
    width: 100%;
    height: 60px;
    img {
      height: 60px !important;
    }
  }
  img {
    width: unset;
    height: 80px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: 767px) {
      position: relative;
      left: 0px;
      transform: none;
    }
  }
}
.contact-text {
  width: 100%;
  p {
    font-size: 16px;
    font-weight: 500;
    color: $white;
    @media (max-width: 767px) {
      font-size: 14px;
      text-align: center;
    }
  }
}
