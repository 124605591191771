@import "eco_variable";
.p-button,
.btn {
  padding: 9.5px 25px !important;
  font-weight: 600 !important;
  border-radius: 62px !important;
  &.sm-radius {
    border-radius: 10px !important;
  }
  &.xs-radius {
    border-radius: 6px !important;
  }
  @media (max-width: 767px) {
    font-size: 12px !important;
    font-weight: 600 !important;
  }
  &.text-capitalize {
    text-transform: capitalize;
  }
  &.text-uppercase {
    text-transform: uppercase;
  }
  &.btn-wide {
    min-width: 166px;
    @media (max-width: 767px) {
      font-size: 12px;
      min-width: unset;
      width: fit-content;
    }
  }
  &.sm-padding {
    padding: 5px 10px !important;
  }
  &.sm-padding-w {
    padding: 5px 18px !important;
  }
  &.p-button-outlined {
    color: $primary-color !important;
    border-color: $primary-color !important;
    font-weight: 600 !important;
    &.p-button-danger {
      color: #fb0a2a !important;
      border-color: #fb0a2a !important;
    }
  }
  &.p-button-secondary,
  &.btn-secondary {
    border-color: $grey-1 !important;
    color: $grey-6 !important;
    background-color: $white !important;
  }
  &.p-button-primary {
    background-color: $primary-color !important;
    border-color: $primary-color !important;
    .pi {
      color: $primary-color !important;
    }
    &.p-button-text {
      background-color: none !important;
      border-color: none !important;
      color: $primary-color !important;
    }
    &:hover {
      background-color: $secondary;
      border-color: $secondary;
    }
  }
  &.p-button-outlined,
  &.btn-outlined {
    &.p-button-primary {
      color: $primary-color;
      border-color: $primary-color;
    }

    &.outlined-bg-primary {
      background-color: $primary-1;
    }
  }
  &.ic-cart {
    position: relative;
    padding-left: 50px !important;
    &::before {
      content: "";
      display: block;
      width: 22px;
      height: 22px;
      background-image: url($ic-cart);
      background-repeat: no-repeat;
      background-size: 100%;
      position: absolute;
      top: 50%;
      left: 15px;
      margin-top: -11px;
    }
  }
  &.p-button-disabled {
    opacity: 0.5 !important;
    justify-content: center !important;
    padding: 2.5px 25px !important;
    cursor: wait !important;
    > img {
      margin-bottom: 0 !important;
      cursor: wait !important;
    }
    .p-button-label {
      display: none !important;
    }
    &:hover,
    &:active,
    &:focus {
      outline: none;
      box-shadow: unset;
      opacity: 0.5 !important;
      background-image: unset !important;
    }
  }
  &.p-button-rounded {
    &.sm-rounded {
      border-radius: 10px !important;
    }
  }
  &.p-button-grey {
    background-color: #eaeaea;
    color: #808080;
    border-color: #eaeaea;
    &:hover,
    &:active,
    &:focus {
      opacity: 0.5;
      background-color: #eaeaea !important;
      color: #808080 !important;
      border-color: #eaeaea !important;
    }
  }
}
.btn-chat {
  background-color: $white;
  padding: 12px 20px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  color: $grey-2;
  position: fixed;
  right: 9%;
  bottom: 60px;
  box-shadow: $shadow-1;
  border-radius: 100px;
  border: none;
  z-index: 2;
  > span {
    margin-right: 10px;
  }
}

.p-fileupload {
  &.icon-style {
    .p-button {
      padding: 5px !important;
      background-color: $white;
      background-image: url($ic-file);
      background-repeat: no-repeat;
      background-position: center;
      width: 40px;
      height: 40px;
      border: none;
      &:hover {
        background-color: transparent;
        background-image: url($ic-file);
        border: none;
      }
      &:focus {
        outline: 0 none !important;
      }
      .p-button-icon {
        display: none;
      }
      .p-button-label {
        display: none;
      }
    }
  }
}

.button-link {
  height: 40px;
  text-transform: uppercase;
  border-radius: 10px !important;
  font-weight: 500 !important;
}

.button-upload-image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 180px;
  width: 180px;
  border: 1px solid $primary;
  color: $primary;
  background-color: #fff;
  border-radius: 10px;
  font-size: 24px;
  span {
    color: $grey-6;
    font-size: 14px;
    margin-top: 20px;
  }
  &.disabled {
    opacity: 0.5;
    cursor: wait;
  }

  i {
    color: $primary;
    font-size: 24px;
    font-weight: 600;
  }

  &:hover,
  &:focus,
  &:active {
    opacity: 0.8;
    box-shadow: none;
    outline: none;
  }
}

.button-back {
  background-color: transparent;
  display: flex;
  justify-items: center;
  align-items: center;
  height: auto;
  width: auto;
  border: unset;
  cursor: pointer;

  &:focus,
  &:hover,
  &:active {
    opacity: 0.8;
    box-shadow: none;
    outline: none;
  }
}

a {
  &.p-button {
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      color: #fff !important;
    }
    &.p-button-outlined {
      &:hover,
      &:focus {
        color: #007bff !important;
      }
    }
  }
  &.btn {
    &.btn-preview {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      text-transform: capitalize;
      color: #007bff;
      font-weight: 600 !important;
      background-color: #f3f6f9;
      border-radius: 6px !important;
      position: relative;
      span {
        margin-right: 7px;
      }
    }
  }
}

button {
  &:disabled,
  &.disabled {
    cursor: not-allowed !important;
  }
}
.btn-info-chat,
.btn-icon-outline {
  height: 30px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: unset;
  box-shadow: unset;
  background-color: transparent;
  &:hover,
  &:active,
  &:focus {
    outline: unset;
    box-shadow: unset;
  }
  &.writer {
    display: none;
    @media (max-width: 768px) {
      display: flex;
    }
  }
}

.btn-download {
  width: fit-content !important;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: unset;
  box-shadow: unset;
  color: #0099ff !important;
  font-weight: 600;
  background-color: transparent !important;
  @media (max-width: 768px) {
    font-size: 12px;
  }
  &.dropdown-toggle {
    &:after {
      display: unset;
    }
  }
  img {
    margin-right: 5px;
  }
  &:hover,
  &:active,
  &:focus {
    outline: none;
    box-shadow: unset;
    background-color: transparent !important;
  }
  &.brief {
    width: 100% !important;
    text-transform: uppercase;
    &.text-danger {
      cursor: default;
      color: #ea4946 !important;
      &:hover,
      &:active,
      &:focus {
        color: #ea4946 !important;
      }
    }
  }
}
.btn-back {
  font-weight: 600;
  color: $title-color;
  font-size: 24px;
  cursor: default;
  span {
    cursor: pointer;
  }
  @media (max-width: 767px) {
    font-size: 18px !important;
  }
  &.dashboard-back {
    font-size: 18px;
    .icon-back {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
    &:hover,
    &:focus,
    &:active {
      color: $title-color;
    }
  }
}

.edit-button {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  > span {
    margin-right: 6px;
  }
  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
    color: #0099ff;
  }
}
