@import "eco_variable";

.badges {
  .p-tag {
    background: $primary;
    color: $white;
    font-size: 12px;
    font-weight: 500;
    padding: 0.25rem 0.6rem;
    &.outlined {
      background: $primary-1;
      color: $primary;
      border: 1px solid $primary;
    }
  }
}
.badge {
  border-radius: 3px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  padding: 2px 10px;
  display: inline-block;
  text-transform: capitalize;
  .font-weight-semibold {
    font-weight: 600;
  }
  @media (max-width: 767px) {
    font-size: 12px !important;
  }
  &.md {
    padding: 2px 10px;
    height: 22px;
  }
  &.wide {
    min-width: 83px;
  }
  &.waiting {
    color: $waiting;
    background-color: $bg-waiting;
  }
  &.reject,
  &.rejected,
  &.non_active {
    color: $rejected;
    background-color: $bg-rejected;
  }
  &.verified,
  &.active {
    color: $verified;
    background-color: $bg-verified;
  }
  &.private {
    color: $primary;
    background-color: $border-card;
  }
  &.process {
    color: $grey-6;
    background-color: $border-card;
  }

  &.sent,
  &.Terkirim {
    background-color: $sent-rgba;
    color: #0099ff;
  }
  &.on-going,
  &.new,
  &.ongoing {
    background-color: $status-new-02;
    color: $dark-blue-color;
  }
  &.done {
    background-color: $status-new;
    color: $primary-dark;
  }
  &.revision {
    background-color: $rev-rgba;
    color: $text-rev;
  }
  &.winner,
  &.published {
    background-color: #cfffec;
    color: $secondary;
  }
}

.keyword {
  border: 1px solid $grey-1 !important;
  color: $grey-6 !important;
  padding: 9.5px 25px;
  font-size: 14px;
  text-align: center;
  font-weight: 700;
  display: inline-block;
  border-radius: 10px;
  margin-right: 15px;
  &:last-child {
    margin-right: 0;
  }
}

a.badge-status,
.badge-status {
  display: block;
  font-size: 12px;
  font-weight: 600;
  color: #272727;
  background-color: #f7f7f7;
  padding: 3px 5px;
  min-width: 110px;
  width: fit-content;
  text-align: center;
  text-transform: capitalize;
  border-radius: 5px;
  &.ongoing {
    color: #0099ff;
    background-color: #d6eaf8;
  }
  &.winner {
    color: #17ad49;
    background-color: #eafaf1;
  }
  &.revision,
  &.draft {
    color: #fdca30;
    background-color: #fef9e7;
  }
  &.sent {
    color: #f79400;
    background-color: #fdf2e9;
  }
  &.published {
    color: #da9a39;
    background-color: #fef9e7;
  }
  &.reject,
  &.only_view {
    color: #fb0a2a;
    background-color: #f9ebea;
  }
  &.removed {
    color: #ffffff;
    background-color: #272727;
  }
  &.unpublished {
    color: #272727;
    background-color: #bfbfbf;
  }
  &.pm_approved,
  &.new {
    color: #581845;
    background-color: #f4ecf7;
  }
  &.fit {
    min-width: unset;
    padding-left: 10px;
    padding-right: 10px;
  }
}
