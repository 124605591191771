.admin-pagination {
  flex: 1;
  display: flex;
  list-style: none;
  margin: 0 0 10px;
  padding: 0;
  > li {
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      height: 28px;
      margin: 0 2px;
      border-radius: 5px;
      background-color: #f4f4f4;
      color: #979797;
      font-size: 14px;
      &.active {
        background-color: #0099ff;
        color: #ffffff;
      }
    }
  }
}
