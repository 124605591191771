@import "eco_variable";

.p-dropdown {
  width: 100%;
  height: 50px;
  max-width: 290px;
  border-radius: 10px !important;
  .p-dropdown-label {
    line-height: 34px;
    &.p-inputtext {
      &:read-only {
        background-color: transparent !important;
      }
    }
  }
  &:hover,
  &:visited,
  &:active,
  &:focus-visible,
  &:focus,
  &:focus-within {
    box-shadow: unset !important;
    outline: unset !important;
  }
}
.search-list {
  width: 100%;
  max-width: 222px;
  transition: all linear 400ms;
  -moz-transition: all linear 400ms;
  -webkit-transition: all linear 400ms;
  &.minimize {
    .p-inputtext {
      max-width: 40px;
    }
  }
  .pi {
    color: $grey-2 !important;
  }
}
.status-filter {
  width: 100%;
  max-width: 222px;
  .p-dropdown {
    width: 100%;
    height: 40px;
    transition: all linear 400ms;
    -moz-transition: all linear 400ms;
    -webkit-transition: all linear 400ms;
    .p-dropdown-trigger {
      width: 40px;
      position: absolute;
      top: 11px;
      right: -2px;
      .p-dropdown-trigger-icon {
        background-image: url($ic-filter);
        background-repeat: no-repeat;
        background-size: 100%;
        width: 18px;
        height: 18px;
        margin-right: 0;
        &::before {
          display: none;
        }
      }
    }
  }
  &.minimize {
    max-width: 40px;
  }
}
.sort-filter {
  width: 100%;
  max-width: 180px;
  .p-dropdown {
    width: 100%;
    height: 40px;
    transition: all linear 400ms;
    -moz-transition: all linear 400ms;
    -webkit-transition: all linear 400ms;
    .p-dropdown-trigger {
      width: 40px;
      position: absolute;
      top: 11px;
      right: -2px;
      .p-dropdown-trigger-icon {
        background-image: url($ic-sort);
        background-repeat: no-repeat;
        background-size: 100%;
        width: 18px;
        height: 18px;
        margin-right: 0;
        &::before {
          display: none;
        }
      }
    }
    .p-dropdown-label {
      line-height: 24px !important;
    }
  }
  &.minimize {
    max-width: 40px;
  }
}

.dropdown-register {
  max-width: unset !important;
  width: 100%;
  .p-dropdown-label {
    line-height: 38px;
  }
  &.p-invalid {
    border-color: #dc3545;
  }
  @media (max-width: 767px) {
    .p-dropdown-header {
      display: none;
    }
  }
}

.dropdown {
  &.left-dropdown {
    .dropdown-menu {
      float: none;
      right: 0px !important;
      left: initial !important;
      min-width: 234px;
      &.filter-dropdown {
        padding: 30px 20px 20px 20px;
        @media (max-width: 767px) {
          transform: translate3d(0px, 25.6667px, 0px) !important;
        }
        h5 {
          font-weight: 600;
          margin-bottom: 15px;
        }
        .p-button {
          width: 100%;
          text-transform: uppercase;
          font-size: 16px;
        }
      }
    }
  }
}

.has-noty,
.not {
  > .btn {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
