@import "eco_variable";

.section-project {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #f4f4f4;
  @media (max-width: 767px) {
    padding-bottom: 0px;
    border-bottom: 0px;
    margin-bottom: 0px;
  }
  &.description {
    @media (max-width: 767px) {
      padding: 20px 16px;
      background-color: white;
      h6 {
        display: none;
      }
    }
  }
  p {
    margin-bottom: 0;
  }
  .section-project-detail {
    display: flex;
    justify-content: space-between;
    @media (max-width: 767px) {
      display: block;
    }
    .project-detail-info {
      font-family: $robotosans;
      display: flex;
      .mobile-release-title {
        display: none;
      }
      @media (max-width: 767px) {
        display: block;
        .detail-info-header,
        .project-info {
          padding: 15px 16px 26px 16px;
          border-bottom: 1px solid $border-card;
        }
        .detail-info-header {
          span {
            font-size: 15px;
          }
        }
        .project-image {
          height: 66px;
          width: 66px;
          margin-right: 20px;
          img {
            height: 66px;
            width: 66px;
            min-width: 66px;
            object-fit: cover;
          }
        }
        .mobile-release-title {
          display: block;
        }
      }
    }
  }
  .action-section {
    justify-content: flex-end;
    .p-button {
      border-radius: 10px !important;
      height: 40px;
      width: 165px;
    }
    .p-dropdown {
      height: 40px;
      width: 165px;
      .p-dropdown-label {
        height: 40px;
        display: flex;
        align-items: center;
      }
    }
  }
  .p-d-flex {
    @media (max-width: 767px) {
      display: block !important;
    }
  }
}
.project-image {
  width: 160px;
  height: 160px;
  position: relative;
  overflow: hidden;
  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 20px;
  }
  img {
    min-width: 100%;
    height: 160px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: 767px) {
      min-width: inherit;
      width: 100%;
      height: 200px;
    }
  }
}

.project-info {
  padding: 0 20px;
  .info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    img {
      height: 24px;
      margin-right: 10px;
    }
    p {
      color: #808080;
      font-size: 14px;
      font-weight: 400;
      margin: 0;
      strong {
        color: $primary;
      }
    }
  }
}

.project-button {
  width: 200px;
  display: flex;
  flex-direction: column;
  a {
    margin-bottom: 10px;
    justify-content: center;
    &.p-button-primary {
      color: #fff;
    }
  }
  &.client {
    min-height: 160px;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    @media (max-width: 767px) {
      min-height: 0px;
      justify-content: center;
      width: 100%;
      padding-top: 20px;
      .p-button {
        width: calc(100% - 40px);
      }
    }
  }
}

.tab-project {
  &.detail-release {
    padding: 0px 16px;
    @media (max-width: 767px) {
      background-color: white;
    }
  }
  .p-tabview {
    .p-tabview-nav {
      border: unset !important;
      display: flex;
      justify-content: space-between;
      li {
        width: 50%;
        text-align: center !important;
        padding-left: 100px;
        padding-right: 100px;
        @media (max-width: 767px) {
          padding-left: 0px;
          padding-right: 0px;
        }
        .p-tabview-nav-link {
          border: none !important;
          border-bottom: 2px solid transparent !important;
          color: #bfbfbf;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          background-color: transparent !important;
          padding: 13px 0px !important;
          display: flex;
          justify-content: center;
          &:hover {
            border: none;
          }
          &:focus {
            outline: 0 none !important;
            box-shadow: none !important;
          }
        }
        &.p-highlight {
          .p-tabview-nav-link {
            border: none !important;
            color: $primary !important;
            border-bottom: 2px solid $primary !important;
            &:hover {
              border: none;
            }
            &:focus {
              outline: 0 none !important;
              box-shadow: none !important;
            }
          }
        }
      }
    }
    .p-tabview-panels {
      padding: 0;
    }
  }
  .tab-info {
    margin: 0 -20px;
    padding: 20px 30px;
    border-bottom: 1px solid #e5eaee;
    display: flex;
    align-items: center;
    flex-direction: column;
    .stakeholder {
      @media (max-width: 320px) {
        flex-direction: column !important;
      }
    }
    @media (max-width: 767px) {
      padding: 20px 20px;
      .p-col-8 {
        width: 100%;
        &.p-inputtext {
          width: calc(100vw - 132px);
        }
      }
    }
    &.detail-release {
      @media (max-width: 767px) {
        background-color: white;
      }
    }
    &:last-child {
      border-bottom: none;
    }
    .p-checkbox {
      margin-right: 8px;
      pointer-events: none;
    }
    label {
      font-size: 16px;
      color: #2d2d2d;
      @media (max-width: 767px) {
        font-size: 14px;
        margin-right: 4px;
        display: block;
      }
      &.p-checkbox-label {
        margin-bottom: 0;
        line-height: 18px;
        pointer-events: none;
        @media (max-width: 767px) {
          font-size: 14px;
          margin-left: 4px;
          display: inline-block;
        }
      }

      &.grey {
        color: #808080;
      }
    }
    .p-inputtext {
      height: 40px;
      @media (max-width: 767px) {
        font-size: 14px;
      }
      &.p-disabled {
        background-color: #eaeaea !important;
        color: #979797 !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        border-color: #eaeaea !important;
        opacity: 1 !important;
      }
    }
    .p-inputtextarea {
      height: 120px;
      resize: unset;
    }
    .attribution {
      overflow-y: scroll;
    }
  }
}

.form-add-project,
.form-detail-company,
.form-detail-pm,
.form-add-help,
.form-detail-pic {
  width: 770px;
  margin: 0 auto;
  @media (max-width: 767px) {
    min-height: calc(100vh - 182px);
  }
  &.brief {
    @media (max-width: 767px) {
      width: 100%;
      .p-inputtext,
      .p-dropdown {
        border-radius: 0px !important;
        border: 0px;
        border-bottom: 1px solid $grey-1;
        font-size: 14px;
      }
      .p-field {
        .p-inputtextarea {
          height: 40px;
          min-height: 40px;
        }
        label {
          font-size: 14px;
        }
      }
      .icon-plus {
        height: 40px;
        width: 40px;
      }
    }
  }
  .p-field {
    &.status-dropdown,
    &.pic-dropdown {
      .p-dropdown {
        max-width: 100%;
        height: 40px;
        .p-dropdown-label {
          height: 40px;
          display: flex;
          align-items: center;
        }
      }
      .icon-add-o {
        height: 25px;
        width: 25px;
      }
    }
    .p-inputtext {
      height: 40px;
      &.width-half {
        max-width: 419px;
      }
    }
    .pic-project {
      padding: 28px 10px;
      color: $title-color;
      border-radius: 10px;
      border-bottom: 1px solid $border-card;
      font-family: $robotosans;
    }
    .p-inputtextarea {
      height: 145px;
    }
    .p-dropdown {
      max-width: 419px;
    }
    > label {
      color: $title-color;
      font-weight: 600;
      font-size: 16px;
    }
    .innef-field {
      display: flex;
      margin-top: 10px;
      @media (max-width: 767px) {
        display: block !important;
      }
      > span {
        width: 180px;
        display: inline-block;
        margin-top: 8px;
        @media (max-width: 767px) {
          width: 100%;
          display: block;
        }
      }
      > div {
        width: 60vw;
        @media (max-width: 767px) {
          width: 100%;
        }
        .p-field-checkbox {
          display: inline-block;
          margin-right: 15px;
          width: 23%;
          @media (max-width: 767px) {
            width: 40%;
          }
        }
      }
    }
    .pi-calendar {
      position: relative;
      top: -5px;
    }
    > p {
      font-size: 14px;
      color: #2d2d2d;
    }
    .p-disabled {
      background-color: #eaeaea;
      color: $title-color;
      span {
        color: $title-color;
      }
    }
  }
  .icon-info {
    cursor: pointer;
  }
  .info-question-wrapper {
    position: relative;
    top: -40px;
    background-color: blue;
    &.bottom {
      top: 260px;
      .info-question {
        top: -260px;
        bottom: auto;
      }
    }
    .info-question {
      font-size: 12px;
      font-weight: 400;
      background-color: $primary-light;
      position: absolute;
      z-index: 100;
      bottom: 0px;
      left: 20vw;
      width: 500px;
      color: $primary;
      padding: 5px 10px;
      border-radius: 8px;
    }
  }
  .portofolio-section {
    .icon-add {
      cursor: pointer;
    }
    p {
      font-size: 16px;
    }
    label {
      margin-bottom: 35px;
    }
    .portofolio-item:last-of-type {
      margin-bottom: 0px;
      border-bottom: 0px;
    }
    .portofolio-item {
      border-bottom: 1px solid $border-card;
      margin-bottom: 30px;
      padding-bottom: 30px;
      .name {
        font-family: $robotosans;
        font-weight: 700;
        font-size: 16px;
        color: $title-color;
      }
      .year {
        font-family: $robotosans;
        color: $grey-6;
        font-size: 14px;
      }
      .p-button {
        &.p-button-outlined {
          border-radius: 10px !important;
          height: 39px;
          width: 178px;
          margin-left: 20px;
        }
      }
    }
  }
  &.pm-add {
    padding: 0px 12.5px !important;
  }
}
.form-add-ideation {
  width: 100%;
  margin: 0 auto;
  @media (max-width: 767px) {
    min-height: calc(100vh - 182px);
  }
  &.brief {
    @media (max-width: 767px) {
      width: 100%;
      .p-inputtext,
      .p-dropdown {
        border-radius: 0px !important;
        border: 0px;
        border-bottom: 1px solid $grey-1;
        font-size: 14px;
      }
      .p-field {
        .p-inputtextarea {
          height: 40px;
          min-height: 40px;
        }
        label {
          font-size: 14px;
        }
      }
      .icon-plus {
        height: 40px;
        width: 40px;
      }
    }
  }
  .p-field {
    &.status-dropdown,
    &.pic-dropdown {
      .p-dropdown {
        max-width: 100%;
        height: 40px;
        .p-dropdown-label {
          height: 40px;
          display: flex;
          align-items: center;
        }
      }
      .icon-add-o {
        height: 25px;
        width: 25px;
      }
    }
    .p-inputtext {
      height: 40px;
      &.width-half {
        max-width: 419px;
      }
    }
    .pic-project {
      padding: 28px 10px;
      color: $title-color;
      border-radius: 10px;
      border-bottom: 1px solid $border-card;
      font-family: $robotosans;
    }
    .p-inputtextarea {
      height: 145px;
    }
    .p-dropdown {
      max-width: 419px;
    }
    > label {
      color: $title-color;
      font-weight: 600;
      font-size: 16px;
    }
    .innef-field {
      display: flex;
      margin-top: 10px;
      @media (max-width: 767px) {
        display: block !important;
      }
      > span {
        width: 180px;
        display: inline-block;
        margin-top: 8px;
        @media (max-width: 767px) {
          width: 100%;
          display: block;
        }
      }
      > div {
        width: 60vw;
        @media (max-width: 767px) {
          width: 100%;
        }
        .p-field-checkbox {
          display: inline-block;
          margin-right: 15px;
          width: 23%;
          @media (max-width: 767px) {
            width: 40%;
          }
        }
      }
    }
    .pi-calendar {
      position: relative;
      top: -5px;
    }
    > p {
      font-size: 14px;
      color: #2d2d2d;
    }
    .p-disabled {
      background-color: #eaeaea;
      color: $title-color;
      span {
        color: $title-color;
      }
    }
  }
  .icon-info {
    cursor: pointer;
  }
  .info-question-wrapper {
    position: relative;
    top: -40px;
    background-color: blue;
    &.bottom {
      top: 260px;
      .info-question {
        top: -260px;
        bottom: auto;
      }
    }
    .info-question {
      font-size: 12px;
      font-weight: 400;
      background-color: $primary-light;
      position: absolute;
      z-index: 100;
      bottom: 0px;
      left: 20vw;
      width: 500px;
      color: $primary;
      padding: 5px 10px;
      border-radius: 8px;
    }
  }
  .portofolio-section {
    .icon-add {
      cursor: pointer;
    }
    p {
      font-size: 16px;
    }
    label {
      margin-bottom: 35px;
    }
    .portofolio-item:last-of-type {
      margin-bottom: 0px;
      border-bottom: 0px;
    }
    .portofolio-item {
      border-bottom: 1px solid $border-card;
      margin-bottom: 30px;
      padding-bottom: 30px;
      .name {
        font-family: $robotosans;
        font-weight: 700;
        font-size: 16px;
        color: $title-color;
      }
      .year {
        font-family: $robotosans;
        color: $grey-6;
        font-size: 14px;
      }
      .p-button {
        &.p-button-outlined {
          border-radius: 10px !important;
          height: 39px;
          width: 178px;
          margin-left: 20px;
        }
      }
    }
  }
  &.pm-add {
    padding: 0px 12.5px !important;
  }
}

.form-detail-company,
.form-detail-pm,
.form-detail-pic {
  .p-disabled {
    background-color: $border-card;
  }
}

.assign-pic {
  @media (max-width: 767px) {
    &.card {
      min-height: calc(100vh - 64px);
      border-radius: 0px;
      background-color: $page-bg;
      .card-header {
        display: none;
      }
      .card-body {
        padding: 0px;
      }
    }
  }
}

.detail-pm {
  .card {
    @media (max-width: 767px) {
      border-radius: 0px;
      min-height: calc(100vh - 55px);
      padding: 15px;
      .col-12 {
        padding: 0px !important;
      }
    }
  }
  .detail-footer {
    height: 10px;
    border-top: 0px;
  }
  .card-header {
    @media (max-width: 767px) {
      display: none !important;
      .col-12 {
        padding: 0px !important;
      }
    }
  }
  .card-body {
    @media (max-width: 767px) {
      padding: 0px;
    }
  }
}

.company,
.pm {
  &.footer-form {
    padding: 20px 2.25rem;
    width: 100%;
    margin: 0;
    .p-button {
      border-radius: 10px !important;
    }
    .form-add-project {
      padding: 12.5px;
      width: 100%;
    }
  }
}

.pm {
  &.footer-form {
    border-top: 0px;
    margin-top: 0px;
    margin-bottom: 10px;
    .form-add-project {
      padding: 0 20px;
    }
  }
}

.footer-form {
  padding: 20px 20px;
  border-top: 1px solid $border-card;
  margin-top: 30px;
  margin-left: -20px;
  margin-right: -20px;
  .p-button {
    text-transform: uppercase;
    border-radius: 10px !important;
  }
  &.edit-profile {
    @media (max-width: 767px) {
      .p-button {
        min-width: calc((100vw - 88px) / 2);
        font-size: 14px !important;
      }
    }
  }
  &.add-portfolio {
    @media (max-width: 767px) {
      .p-button {
        min-width: calc((100vw - 74px) / 2);
        font-size: 14px !important;
      }
    }
  }
  .form-add-project {
    &.brief {
      @media (max-width: 767px) {
        flex-direction: column-reverse;
        min-height: 60px;
        .p-button {
          width: 100%;
        }
        .p-button-secondary {
          margin-top: 15px;
        }
      }
    }
  }
}

.form-group-header {
  padding: 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #bfbfbf;
  > h4 {
    font-size: 18px;
    font-weight: 600;
    color: #2d2d2d;
    margin-bottom: 12px;
  }
  &.m-t-50 {
    margin-top: 50px;
  }
}
.footer-form-client {
  padding: 0px 27px 30px 0px;
  @media (max-width: 767px) {
    padding: 0px;
    display: block;
    .col-sm-12 {
      padding: 0px;
    }
    .p-button-outlined {
      min-width: 100%;
    }
  }
  .footer-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column-reverse;
      .p-button {
        width: 100%;
      }
      .p-button:nth-child(2) {
        margin-left: 0px !important;
        margin-bottom: 15px;
      }
    }
  }
  .p-button-secondary {
    min-width: 170px;
  }
  .p-button-outlined:nth-child(2) {
    margin-left: 20px;
    @media (max-width: 767px) {
      margin-left: 0px;
      margin-bottom: 15px;
    }
  }
}

.finance-setting {
  height: calc(100vh - 54px - 65px - 50px);
}

.project-detail-alert {
  text-align: center;
  padding: 40px 0 20px;
  h3 {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 600;
  }
  p {
    font-size: 14px;
    max-width: 75%;
    margin: 0 auto 10px;
  }
  > a {
    width: fit-content !important;
    margin: 30px auto 0;
  }
}

.tab-user-type {
  width: 100%;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  .tab-button-user {
    padding: 10px 30px;
    width: 150px;
    border-bottom: 1px solid #eaeaea;
    border-top: unset;
    border-left: unset;
    border-right: unset;
    font-size: 16px;
    color: #2d2d2d;
    font-weight: 500;
    background-color: transparent;
    &.active {
      border-color: $primary;
      color: $primary;
    }
    &:disabled {
      color: #808080;
    }
  }
}
.p-inputtextarea {
  &:read-only {
    background-color: #eaeaea;
    border-color: #eaeaea;
  }
}
.p-accordion {
  &.accordion-project-detail {
    max-width: 100%;
  }
}

.accordion-project-wrapper {
  width: 100%;
  padding: 20px 80px;
  h2 {
    font-size: 24px;
    font-weight: 600;
    color: #2d2d2d;
    margin-bottom: 10px;
  }
  h3 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
    color: #2d2d2d;
  }
  h6 {
    font-size: 14px;
    font-weight: 500;
    color: #2d2d2d;
    margin-bottom: 10px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    color: #808080;
    margin-bottom: 30px;
  }
  .box-text {
    padding: 8px 15px;
    color: #2d2d2d;
    background-color: #eaeaea;
    margin-bottom: 15px;
    font-size: 14px;
    border-radius: 6px;
    width: fit-content;
    text-transform: capitalize;
    &.list {
      margin-right: 5px;
    }
  }
  @media (max-width: 500px) {
    padding: 20px 10px;
  }
}
