.p-card {
  border-radius: 10px !important;
  box-shadow: none !important;
  border: 1px solid $border-card !important;
  .p-card-header {
    position: relative;
    img {
      max-height: 126px;
      object-fit: contain;
    }
    .card-title {
      a {
        color: $title-color;
      }
    }
  }
  .p-card-body {
    min-height: 160px;
    padding-bottom: 56px;
    position: relative;
    .p-card-content {
      padding: 0 !important;
    }
    .p-card-title {
      text-align: left;
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 600;
      color: $title-color;
      a {
        color: $title-color;
      }
    }
  }
  .p-card-footer {
    color: $grey-1;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 20px !important;
  }
  &.card-sm {
    padding: 15px;
    &::after {
      content: "";
      display: block;
      clear: both;
    }
    .p-card-header {
      position: relative;
      width: 126px;
      height: 126px;
      border-radius: 6px !important;
      float: left;
    }
    .p-card-body {
      min-height: 126px !important;
      padding-top: 0;
      padding-bottom: 36;
      overflow: hidden;
      .p-card-title {
        margin-bottom: 0;
      }
    }
    .p-card-footer {
      color: $grey-1;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 20px 0 0 20px !important;
    }
  }
  &.card-md {
    overflow: hidden;
    .p-card-header {
      .images {
        width: 100%;
        height: 200px;
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #808080;
        img {
          width: 100%;
          max-height: unset;
          height: auto;
          object-fit: cover;
        }
      }
    }
  }
}

.card-info-bottom {
  width: 100%;
  margin-top: 20px;
  .article-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: 1px solid #eaeaea;
    margin-bottom: 20px;
    padding: 20px 30px;
  }
  .article-left {
    width: calc(100% - 150px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    h3 {
      font-size: 16px;
      color: #0099ff;
      font-weight: 600;
      margin-bottom: 5px;
    }
    p {
      font-size: 14px;
      color: #808080;
      margin-bottom: 0;
    }
  }
  .article-right {
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    text-align: end;
    p {
      font-size: 14px;
      color: #808080;
      margin-bottom: 5px;
    }
    .btn {
      &.btn-action {
        background-color: #f4f4f4;
        padding: 0 !important;
        height: 35px !important;
        width: 35px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px !important;
      }
    }
  }
}
