@import "eco_variable";

.landing-page {
  background-color: $white;
  font-family: "Open Sans", sans-serif !important;
  &.client {
    h2 {
      font-size: 32px;
      font-weight: 600;
      text-align: center;
      @media (max-width: 767px) {
        font-size: 28px;
      }
    }
    .section-action {
      background-color: $grey-3;
      padding-top: 60px;
      @media (max-width: 767px) {
        padding: 40px 15px 20px 15px;
      }
      .section-action-header {
        display: flex;
        justify-content: center;
      }
      h2 {
        width: 671px;
        margin-bottom: 60px;
        @media (max-width: 767px) {
          font-size: 24px;
          margin-bottom: 40px;
        }
      }
      .p-button {
        margin: 0px 57px 60px 57px;
        @media (max-width: 767px) {
          margin: 0px 0px 15px 0px !important;
        }
      }
      .action-buttons {
        display: flex;
        justify-content: center;
        @media (max-width: 767px) {
          flex-direction: column;
        }
      }
    }
    .testimony-section {
      padding-bottom: 0px;
      .slick-slide {
        margin-right: 20px;
      }
      .carousel-item {
        .testimony-wrapper {
          width: 328px;
          .testimony-info {
            padding: 24px 12px;
          }
        }
      }
    }
    .carousel-section {
      padding-top: 0px;
    }
    .author-section {
      padding-bottom: 0px;
      @media (max-width: 767px) {
        padding: 40px 15px 0px 15px;
      }
      h2 {
        margin-bottom: 40px;
        @media (max-width: 767px) {
          margin-bottom: 20px;
        }
      }
      p {
        margin-bottom: 40px;
        font-size: 16px;
        @media (max-width: 767px) {
          font-size: 14px;
          margin-bottom: 20px;
        }
      }
      .p-grid {
        @media (max-width: 767px) {
          margin: 0px;
          width: 100vw;
        }
      }
      .slick-slide {
        width: 328px !important;
        max-width: 328px !important;
        .author-attr {
          p {
            margin-bottom: 0px;
          }
        }
        .info-wrapp {
          text-align: center;
        }
      }
    }
    .section-benefit {
      margin-top: 50px;
      h2 {
        margin-bottom: 62px;
        @media (max-width: 767px) {
          font-size: 28px;
          margin-bottom: 40px;
        }
      }
      .benefit-card {
        text-align: center;
        padding: 30px;
        @media (max-width: 767px) {
          padding: 30px 15px;
        }
        img {
          border-radius: 50%;
          background-color: $grey-1;
          margin-bottom: 30px;
          height: 154px;
          width: 154px;
          object-fit: cover;
          @media (max-width: 767px) {
            height: 120px;
            width: 120px;
            margin-bottom: 15px;
          }
        }
        h4 {
          margin-bottom: 30px;
          font-size: 24px;
          font-weight: 600;
          @media (max-width: 767px) {
            font-size: 20px;
            margin-bottom: 15px;
          }
        }
        p {
          font-size: 16px;
          @media (max-width: 767px) {
            font-size: 14px;
            line-height: 28px;
          }
        }
      }
    }
  }
  .hero-banner {
    @media (max-width: 500px) {
      text-align: center;
      .p-button {
        font-size: 14px !important;
      }
      &.image {
        width: 100%;
      }
    }
    &.image {
      width: 300px;
      height: 300px;
      overflow: hidden;
      position: relative;
      margin: 0 auto;
      img {
        height: 300px;
        min-width: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    h1 {
      font-size: 48px;
      color: $title-color;
      line-height: 72px;
      font-weight: 700;
      margin-bottom: 20px;
      @media (max-width: 1024px) {
        font-size: 32px;
      }
      @media (max-width: 767px) {
        font-size: 24px;
        line-height: 30px;
      }
    }
    p {
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 30px;
    }
    .p-button {
      text-transform: uppercase;
      width: fit-content;
      font-weight: 600 !important;
      font-size: 16px;
      @media (max-width: 767px) {
        margin: 0 auto;
      }
    }
  }
  .section {
    padding: 40px 0;
    @media (max-width: 767px) {
      padding-top: 60px;
    }
    &.bg-primary {
      background-color: $primary-dark !important;
      color: $white;
      padding: 60px 0;
    }
    &.bg-grey {
      background-color: $grey-3 !important;
    }
    &.article-view {
      min-height: calc(100vh - 353.5px);
      width: 100%;
      max-width: 1280px !important;
      padding: 20px;
      margin: 0 auto;
      @media (max-width: 1281px) {
        max-width: 1024px !important;
        padding: 90px 20px 20px;
      }
    }
    &.loading-landing {
      max-width: 1280px !important;
      margin: 0 auto;
      @media (max-width: 1281px) {
        max-width: 1024px !important;
      }
    }
    .slick-slider {
      .slick-slide {
        .p-grid {
          @media (max-width: 767px) {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .p-sm-12 {
            padding: 20px !important;
            @media (max-width: 767px) {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }
  .about-section {
    &.client {
      height: calc(100vh - 200px);
      @media (max-width: 767px) {
        height: unset;
      }
    }
    text-align: center;
    @media (max-width: 767px) {
      max-width: 100vw;
      text-align: center;
      padding: 30px 0 0;
      h2 {
        font-size: 22px;
        margin-bottom: 0px !important;
      }
      p {
        width: 100%;
        font-size: 14px !important;
        margin-bottom: 10px;
        text-align: justify;
      }
    }
    h2 {
      font-size: 32px;
      margin-bottom: 20px;
      font-weight: 700;
      @media (max-width: 1024px) {
        font-size: 22px;
      }
    }
    p {
      color: $grey-6;
      font-size: 16px;
    }
  }
  .fiture {
    display: flex;
    justify-content: center;
    padding: 40px 0;
    max-width: 100vw;
    @media (max-width: 767px) {
      max-width: 100vw;
      margin: 0px 0px 50px 0px;
    }
    .p-grid {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      @media (max-width: 767px) {
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        img {
          width: 50vw;
        }
      }
    }
    .fiture-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 20px;
      padding: 0 20px;
      h5 {
        color: $grey-6;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 20px;
      }
      p {
        color: $grey-6;
        font-size: 16px;
        text-align: justify;
      }
      @media (max-width: 767px) {
        flex-direction: row;
        justify-content: center;
        width: 100%;
        align-items: flex-start;
        padding: 0;
        margin: 10px 0 30px;
        flex-wrap: wrap;
        h5 {
          margin-bottom: 10px;
        }
        p {
          margin: 0px 0px 0px 10px;
          font-size: 14px !important;
          text-align: justify;
        }
      }
      .icon-wrapp {
        margin: 0 0 40px;
        width: 100%;
        height: 175px;
        overflow: hidden;
        position: relative;
        @media (max-width: 767px) {
          width: 100%;
          margin-bottom: 20px;
        }
        img {
          width: 100%;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          height: 175px;
        }
      }
    }
  }
  .join-action {
    display: flex;
    justify-content: center;
    padding: 40px 0;
    max-width: 100vw;
    h2 {
      font-size: 32px;
      color: #fff;
      font-weight: 700;
      margin-bottom: 40px;
    }
    p {
      font-size: 16px;
      text-align: center;
      color: #fff;
      margin-bottom: 60px;
    }
    .benefit-box {
      padding: 0 15px;
      .benefit-image {
        margin: 0 0 40px;
        width: 100%;
        height: 175px;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: flex-end;
        img {
          width: 100%;
          height: 175px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      > p {
        font-size: 16px;
        color: #fff;
        text-align: justify;
        margin-bottom: 0;
      }
    }
  }
  .article-section {
    text-align: center;
    h2 {
      font-size: 32px;
      margin-bottom: 30px;
      font-weight: 700;
      color: $title-color;
    }
  }
  .author-section {
    h2 {
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 20px;
      color: $title-color;
    }
    p {
      margin: 0;
      line-height: 28px;
      font-size: 16px;
    }
  }
}
.carouser-wrapper {
  padding: 0 10px;
  margin-left: -50px;
  &.author-carousel {
    margin-left: 0;
  }
  @media (max-width: 767px) {
    margin: 30px auto 0;
    padding: 0;
  }
  @media (min-width: 0px) and (max-width: 330px) {
    min-height: 345px;
  }
  @media (min-width: 331px) and (max-width: 385px) {
    min-height: 310px;
  }
  @media (min-width: 386px) and (max-width: 420px) {
    min-height: 261px;
  }
  &.author-carousel {
    @media (max-width: 767px) {
      margin-top: 0px;
    }
  }
  .slick-list {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    margin-bottom: 30px;
  }
}
.carousel-item {
  padding: 0 10px;
}
.carousel-client {
  height: 100px;
  h3 {
    background-color: blue;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 100px;
    font-size: 35px;
    color: white;
    font-weight: 600;
    margin: 10px;
    border: 3px solid yellow;
  }
}
.author-wrapper {
  background-color: $white;
  border-radius: 10px;
  .author-info {
    border-bottom: 1px solid $border-card;
    padding: 24px 0;
    text-align: center;
    .info-wrapp {
      h3 {
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        font-weight: 700;
        color: $title-color;
        @media (max-width: 767px) {
          font-size: 14px;
          line-height: 18px;
        }
      }
      p {
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        @media (max-width: 767px) {
          font-size: 12px;
          line-height: 18px;
        }
        img {
          display: inline-block;
          margin-right: 5px;
          vertical-align: middle;
           @media (max-width: 767px) {
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
      .pict-wrapper {
        display: inline-block;
        position: relative;
        margin-bottom: 20px;
        .pict {
          width: 145px;
          height: 145px;
          border-radius: 100%;
          overflow: hidden;
          margin: 0 auto;
          position: relative;
          @media (max-width: 767px) {
            width: 100px;
            height: 100px;
          }
          img {
            min-width: 100%;
            height: 145px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            @media (max-width: 767px) {
              min-width: inherit;
              width: 100%;
              height: auto;
            }
          }
        }
        .author-number {
          width: 46px;
          height: 46px;
          border-radius: 100%;
          background-color: $primary;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          right: 0;
          bottom: 0;
          @media (max-width: 767px) {
            width: 32px;
            height: 32px;
          }
          span {
            color: $white;
            font-size: 24px;
            line-height: 32px;
            font-weight: 600;
            @media (max-width: 767px) {
              font-size: 18px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
  .author-attr {
    padding: 30px;
    p {
      font-size: 16px;
      font-weight: 600;
      color: $grey-1;
    }
    > span {
      color: $title-color;
      font-size: 48px;
      font-weight: 700;
    }
    .p-button {
      margin-top: 10px !important;
      width: 100%;
      padding: 16px 10px !important;
      font-size: 16px;
      margin-top: 20px;
      .p-button-label {
        font-size: 16px;
      }
    }
  }
}

.testimony-wrapper {
  background-color: $white;
  border-radius: 10px;
  border: 1px solid $border-card;
  .testimony-info {
    padding: 24px 0;
    .testimony-wrapp {
      text-align: center;
      h3 {
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        font-weight: 600;
        color: $primary;
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
      p {
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: $title-color;
        @media (max-width: 767px) {
          font-size: 14px;
          line-height: 28px;
        }
      }
      span {
        color: $grey-6;
        font-size: 16px;
        font-weight: 600;
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
      .pict-wrapper {
        display: inline-block;
        position: relative;
        margin-bottom: 20px;
        .pict {
          width: 145px;
          height: 145px;
          border-radius: 100%;
          overflow: hidden;
          margin: 0 auto;
          position: relative;
          img {
            min-width: 100%;
            height: 145px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
}

.benefit-wrapper {
  padding: 30px;
  border-radius: 10px;
  background-color: $white;
  box-shadow: $shadow-5;
  width: 480px;
  height: 200px;
  @media (max-width: 767px) {
    padding: 20px;
    width: 50vhpx;
    min-height: 50vw;
  }
  @media (max-width: 480px) {
    padding: 20px;
    width: 205px;
    min-height: 50vw;
  }
  .desc {
    h3 {
      font-size: 24px;
      font-weight: 600;
      color: $title-color;
      margin-bottom: 20px;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media (max-width: 767px) {
        padding-left: 0px;
        font-size: 18px;
        margin-bottom: 10px;
      }
      .image-wrap {
        width: 50px;
        height: 50px;
        overflow: hidden;
        margin: 0 0;
        position: relative;
        @media (max-width: 767px) {
          display: none;
        }
        img {
          min-width: 100%;
          height: 50px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .text-wrap {
        width: calc(100% - 70px);
        margin-left: 20px;
        @media (max-width: 767px) {
          width: 100%;
          margin-left: 0px;
        }
      }

      span {
        position: absolute;
        top: -3px;
        left: 0;
      }
    }
    p {
      font-size: 16px;
      font-weight: 400;
      color: $grey-6;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
}

.fullpage-content {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  .not-found-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      margin-bottom: 30px;
    }
    .loading-image {
      height: 150px;
    }
    .success-image {
      height: 200px;
      margin-bottom: 100px;
    }
    h4 {
      font-size: 18px;
      font-weight: 600;
      text-align: center;
    }
  }
}
.data-empty-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  p {
    font-size: 16px;
    font-weight: 600;
    margin: 15px 0 0;
  }
}

.article-view-card {
  width: 70%;
  margin: 0 auto;
  @media (max-width: 767px) {
    width: 100%;
  }
  .article-view-header {
    margin: 30px 0 50px;
    .article-view-title {
      width: 100%;
      margin-bottom: 20px;
      h1,
      p {
        font-size: 38px !important;
        color: #2d2d2d !important;
        font-weight: 800 !important;
      }
    }
    .article-view-about {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }
      .hr-article {
        content: "";
        height: 30px;
        width: 2px;
        background-color: #dee2e6;
        margin: 0 20px;
      }
      .writer-name {
        display: flex;
        justify-content: flex-start;
        width: fit-content;
        @media (max-width: 767px) {
          margin: 0 0 10px;
        }
      }
      .pict {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        overflow: hidden;
        margin-right: 10px;
        position: relative;
        img {
          min-width: 100%;
          height: 30px;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
        .letter-pics {
          width: 30px;
          height: 30px;
          background-color: #0099ff;
          color: #fff;
          font-size: 20px;
          font-weight: 600;
          text-transform: uppercase;
          display: flex;
          justify-content: center;
        }
      }
      .name {
        width: calc(100% - 40px);
        font-size: 16px;
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
      .publish-date {
        font-size: 16px;
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
    }
  }
  .article-view-body {
    margin: 0 0 20px;
    .article-view-cover {
      width: 100%;
      margin-bottom: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 100%;
        min-height: 280px;
      }
    }
    .article-view-content {
      font-size: 16px;
      color: #2d2d2d;
      img {
        max-width: 100%;
      }
      p {
        font-size: 16px;
        color: #2d2d2d;
      }
      h1 {
        font-size: 32px;
        color: #2d2d2d;
        font-weight: 700;
      }
      h2 {
        font-size: 28px;
        color: #2d2d2d;
        font-weight: 700;
      }
      h3 {
        font-size: 24px;
        color: #2d2d2d;
        font-weight: 700;
      }
      h4 {
        font-size: 22px;
        color: #2d2d2d;
        font-weight: 700;
      }
      h5 {
        font-size: 20px;
        color: #2d2d2d;
        font-weight: 700;
      }
      h6 {
        font-size: 18px;
        color: #2d2d2d;
        font-weight: 700;
      }
      a {
        font-size: 16px;
        color: #0099ff;
        text-decoration: none;
        font-weight: 600;
      }
    }
  }
}

.card-article-column {
  .p-card-body {
    min-height: 100px;
    border-top: 1px solid #eaeaea;
  }
  .title-text {
    height: 60px;
    overflow: hidden;
  }
}
.card-article-row {
  .image-wrap {
    width: 126px !important;
    height: 126px !important;
    display: flex;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    img {
      min-width: 100%;
      height: 126px;
      max-width: unset;
      max-height: unset;
      object-fit: cover;
    }
  }
}

.article-view-list {
  width: 70%;
  margin: 80px auto 0;
  border-top: 3px solid #eaeaea;
  padding-top: 20px;
  .title-list {
    font-size: 24px;
    font-weight: 600;
    color: #2d2d2d;
    margin-bottom: 15px;
  }
  .p-card {
    &.card-md {
      .p-card-header {
        .images {
          height: 150px;
        }
      }
    }
  }
}
