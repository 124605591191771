.dashboard-user {
  background-color: $grey-3;
  font-family: "Open Sans", sans-serif;
  min-height: 100vh;
  position: relative;
  width: 100%;
  .p-grid {
    max-width: 1280px !important;
    @media (max-width: 1281px) {
      max-width: 1024px !important;
    }
  }
  .chat-wrapper {
    display: flex;
    justify-content: center;
    @media (max-width: 767px) {
      margin-top: 80px;
      height: calc(100vh - 80px);
    }
    &.full-page {
      .p-grid {
        max-width: inherit;
        height: 100%;
        margin: 0 auto;
      }
      .list-project-chat {
        padding: 0;
        @media (max-width: 767px) {
          padding: 55px 0px 0px;
        }
        &.hide {
          @media (max-width: 767px) {
            display: none;
          }
        }
        .header-box {
          @media (max-width: 767px) {
            height: 70px;
          }
        }
        .client-chat {
          justify-content: space-between;
          @media (max-width: 767px) {
            justify-content: flex-start !important;
          }
        }
        .status {
          @media (max-width: 767px) {
            display: none;
          }
        }
        .button-back {
          @media (min-width: 767px) {
            display: none;
          }
          &.discussion {
            margin-left: -20px;
          }
          &.detail-chat {
            margin-left: -20px;
          }
        }
      }
    }
  }
  .action {
    @media (max-width: 767px) {
      max-width: calc(100vw - 20px);
    }
  }
  .my-feed-wrapper {
    max-width: 850px;
    @media (max-width: 767px) {
      padding-top: 90px !important;
    }
  }
  .dashboard-content-wrapper {
    min-height: calc(100vh - 299px);
    width: 100%;
    @media (max-width: 500px) {
      min-height: calc(100vh - 212px) !important;
      padding-top: 70px;
    }
  }
  .section {
    min-height: calc(100vh - 350px);
    &.setting {
      &.p-py-5 {
        @media (max-width: 676px) {
          padding-top: 85px !important;
        }
      }
    }
  }
}
.dashboard {
  .mt-4 {
    @media (max-width: 767px) {
      margin-top: 0px;
    }
  }
  .name-box {
    @media (max-width: 767px) {
      display: none;
    }
  }
  .project-active-wrapper {
    @media (max-width: 767px) {
      padding-top: 0px !important;
      .box-content {
        min-height: 40px;
      }
    }
  }
}
.writer-name {
  display: flex;
  align-items: center;
  .pict {
    width: 49px;
    height: 49px;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 10px;
    position: relative;
    img {
      min-width: 100%;
      height: 49px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .letter-pics {
      width: 49px;
      height: 49px;
      background-color: $primary;
      color: #fff;
      font-size: 34px;
      font-weight: 600;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $robotosans;
    }
  }
  .name {
    width: calc(100% - 50px);
    font-size: 16px;
    line-height: 21px;
    font-weight: 600;
    color: $title-color;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .writer-text-content {
    .name {
      width: 100%;
    }
    p {
      font-size: 14px;
      color: #808080;
      font-weight: 500;
      margin-bottom: 0;
    }
  }
}

.project-active {
  color: $grey-6;
  display: flex;
  &.column {
    flex-direction: column;
    .dashboard-label {
      font-size: 16px;
      color: #2d2d2d;
      font-weight: 500;
      margin-bottom: 5px;
    }
    h1 {
      font-size: 32px;
      color: #2d2d2d;
      font-weight: 700;
      margin-bottom: 0;
    }
  }
  span {
    font-size: 16px;
    line-height: 21px;
    font-weight: 600;
    color: $title-color;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  p {
    margin: 0;
    font-size: 16px;
    line-height: 21px;
    font-weight: 600;
    @media (max-width: 767px) {
      margin-top: 2px;
    }
  }
}
.account-balance {
  display: flex;
  padding: 5px 30px;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #0099ff;
  text-transform: unset;
  @media (max-width: 767px) {
    margin-top: 10px;
    height: 38px;
    padding: 5px 15px;
  }
  span {
    font-size: 16px;
    line-height: 21px;
    font-weight: 600;
    color: $title-color;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  .nominal-balance {
    font-size: 16px;
    line-height: 21px;
    font-weight: 600;
    color: #0099ff;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
}

.call-action {
  @media (max-width: 767px) {
    display: flex;
    .pict {
      display: flex;
      justify-content: center;
      height: 80px !important;
      margin: 10px 15px 0px 0px !important;
      width: 80px !important;
      img {
        width: 78px;
        height: 78px;
      }
    }
    .desc {
      width: calc(100% - 103px);
      overflow: unset !important;
      h3 {
        line-height: 28px !important;
        font-size: 17px !important;
        height: 80px;
        display: flex;
        align-items: center;
        text-align: center;
        margin: 10px 0px 20px 0px;
      }
      p {
        font-size: 14px !important;
        margin-bottom: 18px;
        width: calc(100vw - 30px - 3rem);
        position: relative;
        left: -93px;
        z-index: 3;
      }
      .button-action {
        padding: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% + 93px);
        margin-left: -93px;
      }
    }
  }
  &::after {
    content: "";
    display: block;
    clear: both;
  }
  .pict {
    width: 201px;
    height: 201px;
    overflow: hidden;
    margin-right: 10px;
    float: left;
    img {
      max-width: 100%;
    }
  }
  .desc {
    overflow: hidden;
    h3 {
      font-size: 24px;
      line-height: 32.68px;
      font-weight: 600;
      color: $title-color;
      margin-bottom: 10px;
    }
    p {
      font-size: 16px;
      line-height: 21px;
      font-weight: 400;
      color: $grey-6;
    }
    .p-button {
      text-transform: uppercase;
      display: flex;
      width: 194px;
      height: 50px;
      font-weight: 600 !important;
      font-size: 14px;
    }
  }
}

.announcement {
  border-radius: 10px;
  min-height: 268px;
  padding: 32px 19px;
  position: relative;
  .desc {
    position: relative;
    z-index: 1;
    h3 {
      text-transform: uppercase;
      color: $white;
      font-size: 24px;
      line-height: 28px;
      font-weight: 900;
      font-family: "Roboto", sans-serif;
      margin-bottom: 15px;
    }
    p {
      color: $white;
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      font-family: "Roboto", sans-serif;
    }
  }
  &.blue {
    background: -webkit-linear-gradient(
      319.82deg,
      #0099ff -0.13%,
      #0ccae8 100%
    );
    background: -moz-linear-gradient(319.82deg, #0099ff -0.13%, #0ccae8 100%);
    background: linear-gradient(319.82deg, #0099ff -0.13%, #0ccae8 100%);
    &::before {
      content: "";
      width: 213px;
      height: 206px;
      background-image: url($bg-iso-blue);
      background-size: 100%;
      background-repeat: no-repeat;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  &.green {
    background: -webkit-linear-gradient(321.04deg, #17ad49 0%, #a0c119 100%);
    background: -moz-linear-gradient(321.04deg, #17ad49 0%, #a0c119 100%);
    background: linear-gradient(321.04deg, #17ad49 0%, #a0c119 100%);
    &::before {
      content: "";
      width: 213px;
      height: 206px;
      background-image: url($bg-iso-green);
      background-size: 100%;
      background-repeat: no-repeat;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  &.yellow {
    background: -webkit-linear-gradient(140.52deg, #fdc800 0%, #fd7900 100.84%);
    background: -moz-linear-gradient(140.52deg, #fdc800 0%, #fd7900 100.84%);
    background: linear-gradient(140.52deg, #fdc800 0%, #fd7900 100.84%);
    &::before {
      content: "";
      width: 213px;
      height: 206px;
      background-image: url($bg-iso-yellow);
      background-size: 100%;
      background-repeat: no-repeat;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  &.cyan {
    background: -webkit-linear-gradient(141.04deg, #0ccae8 0%, #0099ff 100%);
    background: -moz-linear-gradient(141.04deg, #0ccae8 0%, #0099ff 100%);
    background: linear-gradient(141.04deg, #0ccae8 0%, #0099ff 100%);
    &::before {
      content: "";
      width: 213px;
      height: 206px;
      background-image: url($bg-iso-cyan);
      background-size: 100%;
      background-repeat: no-repeat;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}
.announcement-box {
  border-radius: 10px;
  height: 295px;
  max-width: 295px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.7);
  background-size: cover;
  overflow: hidden;
  padding: 15px;
  background-size: cover;
  img {
    width: 100%;
    height: auto;
  }
  > h3 {
    font-size: 28px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 20px;
  }
  > p {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
  }
  @media (max-width: 480px) {
    max-width: unset;
    margin-bottom: 20px;
  }
}
.project-detail {
  @media (max-width: 767px) {
    padding-top: 60px;
  }
  p {
    word-break: break-all;
  }
}
.box-project {
  margin-top: 0px;
  &.project-list {
    @media (max-width: 767px) {
      min-width: calc(100vw - 20px);
    }
  }
  &.sticky {
    @media (max-width: 767px) {
      margin-top: 50px;
      .attr-write {
        height: 46px;
        border-bottom: 0px;
        h2 {
          display: none;
        }
      }
    }
  }
}

.write-wrapper {
  width: 100%;
  .btn-back {
    .icon-back {
      margin-top: -5px;
    }
  }
  .title-write {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 4px 20px;
    @media (max-width: 767px) {
      padding: 15px 20px;
      justify-content: flex-start;
      flex-direction: column;
      border-bottom: 1px solid #eaeaea;
      .deadline {
        text-align: right;
        margin-bottom: 0px;
        margin-top: 5px;
      }
    }
    h2 {
      font-weight: 600;
      color: $title-color;
      font-size: 24px;
      display: flex;
      align-items: center;
      .button-back {
        margin-right: 1rem;
        margin-top: 3px;
        max-width: 21px;
      }
      @media (max-width: 767px) {
        font-size: 20px;
      }
    }
    @media (max-width: 767px) {
      .button-back {
        margin: 0px 6px 0px 0px !important;
        img {
          height: 14px;
        }
      }
    }
  }
}
.attr-write {
  padding: 10px 20px !important;
  border-bottom: 1px solid $border-card;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 767px) {
    padding: 10px 20px;
    .history-list {
      display: none;
    }
  }
  .load-btn {
    position: absolute;
    left: 50%;
    bottom: -25px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .btn-back {
    font-weight: 600;
    color: $title-color;
    font-size: 24px;
    cursor: default;
    span {
      cursor: pointer;
    }
    @media (max-width: 767px) {
      font-size: 18px !important;
    }
    &.dashboard-back {
      font-size: 16px;
      .icon-back {
        width: 18px;
        height: 16px;
      }
    }
  }
  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      li {
        padding: 0px !important;
      }
    }
    li {
      display: inline-block;
      padding: 0 10px;
      &:first-child {
        padding-left: 0;
      }
      @media (max-width: 767px) {
        &:nth-child(3) {
          display: none;
        }
      }
    }
  }
}
.attr-write {
  padding: 4px 20px 20px 20px;
  border-bottom: 1px solid $border-card;
  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    li {
      display: inline-block;
      padding: 0 10px;
      &:first-child {
        padding-left: 0;
      }
      @media (max-width: 767px) {
        span {
          display: none;
        }
      }
    }
  }
  h2 {
    font-weight: 600;
    color: $title-color;
    font-size: 24px;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
}
.write-article-wrapper {
  min-height: calc(100vh - 412px);
  @media (max-width: 767px) {
    min-height: calc(100vh - 212px);
    padding-top: 90px;
    .writing-wrapper {
      max-width: calc(100vw - 20px) !important;
    }
  }
}
.write-body {
  padding: 30px 70px;
  min-height: calc(100vh - 493px);
  @media (max-width: 767px) {
    padding: 30px !important;
    min-height: calc(100vh - 350px) !important;
  }
  .form-title {
    position: relative;
    .upload-image {
      position: absolute;
      top: 50%;
      left: -45px;
      margin-top: -12px;
      @media (max-width: 767px) {
        left: -20px;
        top: 22px;
      }
      &::after {
        content: "";
        width: 1px;
        height: 36px;
        background-color: $grey-light;
        position: absolute;
        top: 50%;
        right: -10px;
        margin-top: -18px;
      }
    }
    .p-inputtext {
      border: none;
      border-radius: 0 !important;
      font-size: 36px;
      height: 45px;
      padding: 0 0 !important;
      font-family: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia,
        serif;
      &:focus {
        outline: 0 none !important;
        box-shadow: none;
      }
      ::-webkit-input-placeholder {
        /* Edge */
        color: $grey-light;
        font-size: 36px;
        font-family: TimesNewRoman, "Times New Roman", Times, Baskerville,
          Georgia, serif;
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $grey-light;
        font-size: 36px;
        font-family: TimesNewRoman, "Times New Roman", Times, Baskerville,
          Georgia, serif;
      }

      &::placeholder {
        color: $grey-light;
        font-size: 36px;
        font-family: TimesNewRoman, "Times New Roman", Times, Baskerville,
          Georgia, serif;
      }
    }
    &.preview {
      color: #2d2d2d;
      font-size: 36px;
      font-family: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia,
        serif;
    }
  }
  .form-write {
    .p-inputtextarea {
      color: $grey-light;
      font-size: 16px;
      font-family: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia,
        serif;
      border: none;
      border-radius: 0 !important;
      padding: 20px 0;
      min-height: 400px;
      &:focus {
        outline: 0 none !important;
        box-shadow: none;
        border-color: $grey-light;
      }
      &::-webkit-input-placeholder {
        /* Edge */
        color: $grey-light;
        font-size: 16px;
        font-family: TimesNewRoman, "Times New Roman", Times, Baskerville,
          Georgia, serif;
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $grey-light;
        font-size: 16px;
        font-family: TimesNewRoman, "Times New Roman", Times, Baskerville,
          Georgia, serif;
      }

      &::placeholder {
        color: $grey-light;
        font-size: 16px;
        font-family: TimesNewRoman, "Times New Roman", Times, Baskerville,
          Georgia, serif;
      }
    }
    .p-button {
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 400;
    }
    &.preview {
      color: #2d2d2d;
      font-size: 16px;
      font-family: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia,
        serif;
      img {
        max-width: 100%;
      }
    }
  }
  .image-wrapp {
    margin-bottom: 30px;

    img {
      max-width: 100%;
    }
  }
}
.write-footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid $border-card;
  padding: 20px;

  .write-button {
    width: 150px;
    button {
      font-family: $opensans;
      font-weight: 600 !important;
      width: 100%;
      justify-content: center;
      span {
        display: none;
      }
    }
  }
}

.side-nav {
  .nav-wrapp {
    @media (max-width: 767px) {
      display: none;
    }
  }
  h2 {
    @media (max-width: 767px) {
      font-size: 20px !important;
      margin-bottom: 0px !important;
    }
    font-weight: 600;
    font-size: 32px;
    line-height: 43px;
    margin-bottom: 30px;
    color: $title-color;
  }
  ul {
    list-style: none;
    padding-left: 0;
    li {
      a {
        color: $title-color;
        font-size: 18px;
        font-weight: 600;
        display: block;
        padding: 4px 18px;
        border-left: 3px solid $primary-color;
      }
    }
  }
}

.box-search {
  margin-bottom: 30px;
  width: 100%;
  @media (max-width: 767px) {
    margin-bottom: 20px;
    &.p-mt-4 {
      margin-top: 0 !important;
    }
    .p-input-icon-right {
      width: calc(100vw - 20px) !important;

      input {
        height: 40px;
        padding-left: 12px;
        background-color: white;
      }
      .src-btn {
        height: 40px !important;
      }
    }
  }
  .p-input-icon-right {
    @media (max-width: 767px) {
      background-color: $grey-3;
      padding: 10px 0px;
      &.sticky {
        position: fixed;
        top: 0px;
      }
      .src-btn {
        position: relative;
        top: 10px !important;
      }
    }
    width: 100%;
    .src-btn {
      position: absolute;
      top: 0;
      right: 0;
      width: 67px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $border-card;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      .pi {
        color: $primary-color;
      }
    }
    .p-inputtext {
      border: 1px solid $border-card;
    }
  }
}
.write-detail {
  padding: 20px;
  .summary {
    border-bottom: 1px solid $border-card;
    padding-bottom: 20px;
    margin-bottom: 20px;
    &:last-child {
      border-bottom: none;
    }
    &:nth-last-child(2) {
      border-bottom: 0;
    }
    > p {
      color: $title-color;
    }
    .detail-attr {
      @media (max-width: 767px) {
        display: flex !important;
        flex-direction: column !important;
      }
      p {
        @media (max-width: 767px) {
          display: flex;
          flex-direction: row;
          margin-bottom: 1px !important ;
          padding: 0 !important ;
        }
        img {
          margin-right: 10px;
          @media (max-width: 767px) {
            width: 12px;
          }
        }
        .text {
          font-weight: 500;
          @media (max-width: 767px) {
            font-size: 12px;
          }
        }
        span {
          font-weight: 600;
        }
      }
    }

    .desc {
      h3 {
        > a {
          color: #2d2d2d;
        }
      }
    }

    .footer-attr {
      a {
        font-weight: 600;
        color: $primary-color;
      }
    }
    .action-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 20px;
      @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        .p-button {
          text-align: center;
          align-content: center;
          margin: 0 10px 10px !important;

          min-width: 150px !important;
          &.label {
            text-align: center;
          }
        }
      }
      .action-row {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 50%;
        &.right {
          justify-content: flex-end;
        }
        @media (max-width: 767px) {
          width: 100%;
          justify-content: flex-end;
          &:first {
            margin-bottom: 10px;
          }
        }
      }
      .article-status {
        display: flex;
        align-items: center;
        @media (max-width: 767px) {
          justify-content: start;
          margin-bottom: 20px;
        }
        p {
          margin-right: 10px;
          margin-bottom: 0;
        }
      }
    }
  }
  .author-wrapp {
    @media (max-width: 767px) {
      margin-bottom: 0px !important;
      margin-right: 0 !important;
      &.p-2 {
        padding: 0px !important;
      }
      .desc {
        margin-bottom: 10px;
        h3 {
          font-size: 14px !important;
          margin-bottom: 0px !important;
        }
        span {
          font-size: 14px !important;
        }
        h6 {
          font-size: 12px !important;
        }
      }
    }
    &::after {
      content: "";
      display: block;
      clear: both;
    }
    .pict {
      &.detail {
        border-radius: 0px;
        height: 155px;
        width: 155px;
        @media (max-width: 767px) {
          width: 100%;
          min-height: 155px;
        }
        img {
          border-radius: 0;
          height: 155px;
          width: 155px;
          @media (max-width: 767px) {
            width: 100%;
          }
        }
      }
      &.list {
        border-radius: 0px;
      }
      width: 48px;
      height: 48px;
      border-radius: 100%;
      overflow: hidden;
      margin-right: 20px;
      float: left;
      position: relative;
      @media (max-width: 480px) {
        float: none;
        margin-bottom: 20px;
      }
      img {
        min-width: 100%;
        height: 48px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .desc {
      overflow: hidden;
      h3 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 5px;
        color: $title-color;
      }
      span {
        font-size: 14px;
        font-weight: 600;
        color: $grey-6;
      }
    }
    .project-info-wrapp {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      padding: 0;
      .info-row {
        margin-top: 10px;
        margin-right: 20px;
        width: fit-content;
        @media (max-width: 500px) {
          margin-top: 0;
        }
        .info {
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          &:last-child {
            margin-bottom: 0;
          }
          img {
            height: 24px;
            margin-right: 5px;
          }
          p {
            font-size: 14px;
            font-weight: 500;
            margin: 0;
          }
        }
      }
    }
  }
  .footer-writer {
    padding: 30px 20px 10px 20px;
    margin: 0 -20px 0 -20px;
    border-top: 1px solid $border-card;
    @media (max-width: 767px) {
      display: flex;
      justify-content: center;
    }
    .p-button {
      text-transform: uppercase;
      min-width: 195px;
      height: 39px;
    }
  }
  &.detail {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid $border-card;
    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
    .summary {
      padding-bottom: 0;
      margin-bottom: 0;
      .bd-highlight {
        @media (max-width: 767px) {
          display: block !important;
        }
      }
    }
  }
}
.summary {
  .action-wrapper {
    .p-button {
      font-weight: 600 !important;
      font-family: $opensans;
      min-width: 194px;
      height: 40px;
    }
  }
  .project-wrapp {
    display: flex;
    @media (max-width: 767px) {
      flex-direction: column;
    }
    .project-img {
      width: 170px;
      height: 170px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #eaeaea;
      border: 1px solid #eaeaea;
      overflow: hidden;
      border-radius: 6px;
      position: relative;
      @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 20px;
        height: 200px;
      }
      img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        min-width: 100%;
        height: 170px;
        @media (max-width: 767px) {
          height: 200px;
          width: unset;
          max-width: 100%;
        }
      }
    }
    .project-detail {
      width: calc(100% - 170px);
      display: flex;
      flex-direction: column;
      padding-left: 20px;
      @media (max-width: 767px) {
        width: 100%;
        padding: 0;
      }
      p {
        word-break: break-all;
      }
    }
  }
}

.load-btn {
  background-color: $white;
  border-radius: 65px;
  height: 50px;
  box-shadow: 0px 4px 8px 0px $load-rgba;
  text-align: center;
  padding: 12px 20px;
  display: inline-block;
  cursor: pointer;
  span {
    color: $primary;
    font-size: 16px;
    font-weight: 600;
    i {
      font-size: 20px;
    }
  }
}

.detail-article {
  .attr {
    color: $grey-6 !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    margin-bottom: 10px !important;
  }
  h2 {
    color: $title-color !important;
    font-size: 32px !important;
    font-weight: 600 !important;
    margin-bottom: 30px !important;
  }
  img {
    margin-bottom: 30px !important;
  }
  p {
    font-size: 16px !important;
    line-height: 28px !important;
    color: $title-color !important;
    margin-bottom: 30px !important;
  }
}

.setting-side {
  h2 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 50px;
    @media (max-width: 767px) {
      margin-bottom: 24px;
      font-size: 20px;
    }
  }
  span {
    font-size: 18px;
    font-weight: 600;
    @media (max-width: 767px) {
      display: none;
    }
  }
}

.dashboard-client-module {
  padding: 30px;
  max-width: 100%;
  border-radius: 10px;
  background-color: #fff;
  @media (max-width: 767px) {
    background-color: unset;
    padding: 0px;
    width: calc(100% - 20px);
    .p-col {
      max-width: 100%;
    }
  }
}

.author-desc {
  font-size: 16px;
  font-weight: 600;
  color: #808080;
  margin: 20px 20px 0;
  text-align: start;
  height: 48px;
  @media (max-width: 767px) {
    font-size: 12px;
  }
}
.balance-upper {
  width: 100%;
  padding: 40px 0 0;
  background-color: #fff;
  @media (max-width: 500px) {
    padding: 20px 0 0;
  }
  .balance-grey-box {
    padding: 20px;
    background-color: #f2f2f2;
    border: 1px solid #f4f4f4;
    border-radius: 10px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .balance-text {
      width: 50%;
      p {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        @media (max-width: 500px) {
          font-size: 12px;
        }
      }
      h4 {
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 0;
        @media (max-width: 500px) {
          font-size: 18px;
        }
      }
    }
  }
  .button-balance-wrapper {
    position: relative;
    .hint {
      position: absolute;
      top: 45px;
      right: 0;
      z-index: 1;
      .tail {
        &.top {
          left: calc(100% - 40px);
        }
      }
    }
    .button-style {
      @media (max-width: 500px) {
        font-size: 12px;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
.balance-bottom {
  padding: 40px 0 20px;
  width: 100%;
  background-color: #fff;
  @media (max-width: 500px) {
    padding: 20px 0 10px;
  }
  .balance-filter {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
    @media (max-width: 500px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .p-dropdown {
      max-width: unset;
      width: 200px;
      margin-right: 20px;
      @media (max-width: 500px) {
        width: 100%;
        margin-bottom: 5px;
      }
    }
  }
  .button-more {
    font-size: 16px;
    color: #007bff;
    border: unset;
    background-color: transparent;
    font-weight: 600;
  }
}

.box-content {
  &.client-dashboard {
    height: 100px;
    padding: 40px 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &.widget-dashboard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    @media (max-width: 500px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
}
.m-label-name {
  font-size: 14px;
  font-weight: 600;
  color: #2d2d2d;
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
}
.d-hide-mobile {
  @media (max-width: 767px) {
    display: none !important;
  }
}