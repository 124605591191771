.image-thumb-wrapp {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $primary;
  color: $primary;
  background-color: #fff;
  border-radius: 10px;
  font-size: 24px;

  &.and-edit {
    border: 0px;
  }

  .close-image {
    height: 20px;
    width: 20px;
    background-color: #c4c4c4;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 2;
    span {
      color: #282828;
      font-size: 10px;
      font-weight: 600;
    }
  }

  .image-thumb {
    height: 180px;
    width: 180px;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    &.client-profile {
      img {
        border-radius: 50%;
      }
    }
    img {
      position: absolute;
      min-width: 100%;
      height: 180px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.photo-profile-warp {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #c4c4c4;
  color: $primary;
  background-color: transparent;
  border-radius: 50%;
  font-size: 24px;
  overflow: hidden;
  &.and-edit {
    border-radius: 10px;
  }
  &.client {
    border-radius: 0px;
    border: 0px;
    .button-wrap {
      &.edit {
        .camera-btn {
          position: absolute;
          bottom: 30px;
          right: 10px;
          z-index: 1;
        }
      }
    }
  }
  .button-wrap {
    position: absolute;
    height: 156px;
    width: 156px;
    display: none;
    overflow: hidden;
    &.edit {
      display: flex;
      .camera-btn {
        position: absolute;
        bottom: 20px;
        right: 10px;
        z-index: 1;
        img {
          height: 20px;
          width: auto;
        }
      }
      .delete-btn {
        position: absolute;
        bottom: 20px;
        left: 10px;
        img {
          height: 20px;
          width: auto;
        }
      }
      button {
        @media (min-width: 768px) {
          display: none;
        }
      }
    }
    > button {
      margin: 0 10px;
      height: 35px;
      width: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      z-index: 3;
      background-color: #fff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border: unset;
      &:hover,
      &:focus {
        outline: none;
      }
    }
  }

  .image-thumb {
    height: 130px;
    width: 130px;
    overflow: hidden;
    position: relative;
    &.and-edit {
      height: 180px;
      width: 180px;
      overflow: hidden;
      position: relative;
      border-radius: 10px;
      img {
        position: absolute;
        min-width: 100%;
        height: 180px;
        left: 50%;
      }
    }
    &.client-thumb {
      @media (max-width: 767px) {
        height: 65px;
        width: 65px;
      }
    }
    .letter-pics {
      background-color: $primary;
      height: 130px;
      width: 130px;
      font-size: 110px;
      font-weight: 600;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $robotosans;
      &.client {
        height: 100%;
        width: 100%;
      }
      &.client-upload {
        height: 100%;
        width: 100%;
        @media (max-width: 768px) {
          font-size: 50px;
        }
      }
    }

    img {
      position: absolute;
      min-width: 100%;
      height: 130px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &:hover,
  &:focus {
    background-color: rgba(255, 255, 255, 0.12);
    .button-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      &.view {
        display: none;
      }
    }
    .image-thumb {
      opacity: 0.25;
      &.display-only {
        opacity: 1;
      }
    }
  }
}
