@import "eco_variable";

textarea {
  &.textarea-title {
    width: calc(100% - 30px);
    position: relative;
    border: 1px solid transparent;
    border-radius: 8px;
    font-size: 36px;
    font-family: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia,
      serif;
    color: #282828;
    min-height: 60px;
    resize: none;
    @media (max-width: 767px) {
      font-size: 24px;
      padding-top: 10px;
    }
    &:hover,
    &:focus,
    &:visited {
      border-color: transparent;
      outline: unset;
    }
  }
}

.textarea-title {
  width: calc(100% - 30px);
  position: relative;
  .ql-snow {
    &.ql-toolbar {
      button {
        color: #ffffff;
        &.ql-active {
          color: #000;
        }
      }
    }
  }
  .ql-toolbar {
    display: none;
    background-color: #c2c9d0;
    border-radius: 8px;
    color: #fff;
    padding: 4px;
    &:hover,
    &:focus,
    &:visited {
      .ql-toolbar {
        display: block;
      }
    }
    &.ql-snow {
      position: absolute;
      top: -33px;
      left: 0;
      border: none;
    }
  }
  .ql-container {
    &.ql-snow {
      border: none;
    }
  }
  .ql-editor {
    position: relative;
    left: -22px;
    border: 1px solid transparent;
    border-radius: 8px;
    font-size: 36px;
    font-family: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia,
      serif;
    color: #282828;
    &:hover,
    &:focus,
    &:visited {
      border-color: transparent;
      + .ql-toolbar {
        display: block;
      }
    }
    p {
      padding-left: 15px;
      border-left: 1px solid $border-card;
      @media (max-width: 767px) {
        padding-left: 9px !important;
      }
    }
    &.ql-blank {
      &:before {
        font-style: unset;
        color: #bfbfbf;
        padding-left: 15px;
      }
      p {
        padding-left: 15px;
      }
      @media (max-width: 767px) {
        &::before {
          padding-left: 15px;
          p {
            position: relative;
            top: -5px;
          }
        }
      }
    }
    @media (max-width: 767px) {
      font-size: 20px;
      padding: 8px 15px 14px 18px;
      left: -8px;
    }
  }
  &:hover,
  &:focus,
  &:visited {
    .ql-toolbar {
      display: block;
    }
  }
}

.textarea-content {
  width: 100%;
  position: relative;
  .ql-snow {
    &.ql-toolbar {
      button {
        color: #ffffff;
        &.ql-active {
          color: #000;
        }
      }
    }
    .ql-tooltip {
      left: unset !important;
    }
  }
  .ql-toolbar {
    width: 100%;
    background-color: #ffffff;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    border-left: unset;
    border-right: unset;
    color: #fff;
    padding: 10px 20px;
    @media (max-width: 767px) {
      padding: 10px;
    }
  }
  .ql-container {
    &.ql-snow {
      border: none;
    }
    @media (max-width: 767px) {
      max-width: calc(100vw - 25px);
    }
  }
  .ql-editor {
    border: 1px solid transparent;
    border-radius: 8px;
    font-size: 16px;
    font-family: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia,
      serif;
    color: #282828;
    min-height: 570px;
    max-height: 570px;
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
    &:hover,
    &:focus,
    &:visited {
      border-color: transparent;
    }
    &.ql-blank {
      &:before {
        font-style: unset;
        color: #bfbfbf;
        left: 25px;
        right: 25px;
      }
      @media (max-width: 767px) {
        padding-top: 10px;
        &::before {
          left: 15px;
          right: 15px;
          top: 15px;
        }
      }
    }
    p {
      margin-bottom: 10px;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 6px;
      font-weight: 600;
    }
    ul,
    ol {
      padding-left: 10px;
      margin-bottom: 10px;
      font-size: 16px;
    }
    h1 {
      font-size: 32px;
    }
    h2 {
      font-size: 28px;
    }
    h3 {
      font-size: 24px;
    }
    h4 {
      font-size: 20px;
    }
    h5 {
      font-size: 18px;
    }

    @media (max-width: 767px) {
      max-width: calc(100vw - 25px);
      padding: 15px;
      p {
        font-size: 14px;
      }
    }
  }
  &:hover,
  &:focus,
  &:visited {
    .ql-toolbar {
      display: block;
    }
  }
}

.textarea-image {
  display: flex;
  justify-content: center;
  width: 100%;
  height: fit-content;
  margin: 30px 0;
  .image-box {
    position: relative;
    width: fit-content;
    height: fit-content;
    img {
      height: auto;
      max-height: 350px;
      width: auto;
    }
    .close-image {
      height: 20px;
      width: 20px;
      background-color: #c4c4c4;

      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      position: absolute;
      top: -10px;
      right: -10px;
      z-index: 2;
      span {
        color: #282828;
        font-size: 10px;
        font-weight: 600;
      }
    }
  }
}

.error-image-cover {
  background-color: $rev-rgba;
  color: $text-rev;
  padding: 5px;
  border-radius: 5px;
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .close-alert {
    margin-left: 20px;
    padding: 0 7px;
    font-size: 16px;
    cursor: pointer;
    font-weight: 600;
  }
}

.headline-button-wrapper {
  display: inline-block;
}

.headline-button {
  background-color: #ddd;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  font-weight: 600;
  vertical-align: bottom;
  height: 27px;
  width: 36px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  &:hover {
    background-color: rgb(189, 189, 189);
  }
}

.draft-editor {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 2em;
  box-shadow: inset 0px 1px 8px -3px #ababab;
  background: white;
}

.tukdd6b {
  display: flex;
  position: absolute;
}

.bpsgbes {
  button {
    background-color: #ddd;
    border: 0px;
    &:hover {
      background-color: rgb(189, 189, 189);
    }
    &.a9immln {
      background-color: $primary;
    }
  }
}

.question-pr {
  background-color: #eaeaea !important;
  color: #979797 !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  border-color: #eaeaea !important;
  opacity: 1 !important;
  height: 120px;
  resize: unset;
  border-radius: 10px !important;
  padding: 0.5rem 0.75rem;
  overflow-y: scroll;
  p {
    margin-bottom: 7px;
  }
}

.textarea-question {
  .ql-container {
    &.ql-snow {
      border: unset !important;
    }
  }
  .ql-editor {
    font-size: 1rem;
    color: #495057;
    background: #ffffff;
    padding: 0.5rem 0.75rem;
    border: 1px solid #ced4da;
    min-height: 145px;
    border-radius: 10px !important;
  }
}

.textarea-without-toolbar {
  margin-bottom: 20px;
  .ql-container {
    &.ql-snow {
      border: unset !important;
    }
  }
  .ql-editor {
    font-size: 14px;
    color: #2d2d2d;
    background: #ffffff;
    padding: 0.5rem 0.75rem;
    border: 1px solid #ced4da;
    min-height: 145px;
    border-radius: 8px !important;
    &.ql-blank {
      &::before {
        font-style: unset;
        font-size: 14px;
        color: #495057;
      }
    }
    ol,
    ul {
      padding-left: 0;
    }
  }
}
