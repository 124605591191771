//
// Aside
//

.aside {
  width: get($aside-config, base, width);
  @media (max-width: 767px) {
    display: none !important;
  }
  // Aside Menu
  .aside-menu {
    @include perfect-scrollbar-ver-offset(4px);
    .menu-nav {
      > .menu-item {
        &.menu-item-active,
        &.menu-item-open {
          > .menu-link {
            background-color: $white;
            .menu-text {
              color: $grey-6;
            }
            .menu-icon {
              &.svg-icon {
                svg {
                  g {
                    [fill] {
                      fill: $grey-6;
                    }
                    [stroke] {
                      stroke: $grey-6;
                    }
                  }
                }
              }
            }
          }
        }
        > .menu-link {
          position: relative;
          background-color: $white;
          align-items: center;
          .menu-text {
            color: $grey-6;
            font-weight: 600;
          }
          .menu-icon {
            &.svg-icon {
              svg {
                g {
                  [fill] {
                    fill: $grey-light;
                  }
                  [stroke] {
                    stroke: $grey-light;
                  }
                }
              }
            }
          }
          .menu-show {
            color: #808080;
            // height: 18px;
            // width: 18px;
            svg {
              height: 20px;
              width: 20px;
            }
          }
          .menu-hide {
            color: #0099ff;
            // height: 18px;
            // width: 18px;
            svg {
              height: 20px;
              width: 20px;
            }
          }
          &.active,
          &:hover {
            background-color: $primary-rgba;
            .menu-text {
              color: $primary-color;
              font-weight: 600;
            }
            .menu-icon {
              &.svg-icon {
                svg {
                  g {
                    [fill] {
                      fill: $primary-color;
                    }
                    [stroke] {
                      stroke: $primary-color;
                    }
                  }
                }
              }
            }
            &::before {
              content: "";
              width: 3px;
              height: 24px;
              background-color: $primary-color;
              position: absolute;
              top: 50%;
              left: 0;
              margin-top: -12px;
            }
            .menu-show {
              color: #0099ff;
            }
          }
        }
        .menu-submenu {
          .menu-subnav {
            list-style: none !important;
          }
        }
      }
    }
  }
}

// Desktop Mode
@include media-breakpoint-up(lg) {
  .aside {
    // Fixed Aside Mode
    .aside-fixed & {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: get($aside-config, base, zindex);
    }

    // Static Aside Mode
    .aside-static & {
      position: relative;
      z-index: 1;
    }

    // Fixed Header & Static Aside Modes
    .header-fixed.aside-static & {
      padding-top: get($header-config, desktop, default, height);
    }

    // Minimized Aside Mode
    .aside-minimize:not(.aside-minimize-hover) & {
      width: get($aside-config, base, minimized-width);
    }

    // Scrollbar Width
    @include perfect-scrollbar-ver-size(
      get($aside-config, base, scrollbar-width)
    );
  }

  // Fixed Aside & Minimize Aside Hover Modes
  .aside-fixed.aside-minimize.aside-minimize-hover {
    .aside {
      width: get($aside-config, base, width);
    }

    .wrapper {
      padding-left: get($aside-config, base, minimized-width);
    }
  }

  // Fixed Aside, Fixed Header & Minimize Aside Hover Modes
  .aside-fixed.header-fixed.aside-minimize.aside-minimize-hover {
    .header {
      left: get($aside-config, base, minimized-width);
    }
  }
}

// Build Aside Menu
@include menu-ver-build-layout($aside-menu-config);

// Aside Offcanvas Panel For Mobile Mode
@include offcanvas-build(
  aside,
  tablet-and-mobile,
  get($aside-config, offcanvas-mobile)
);
