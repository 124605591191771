.myrelease-page,
.pic-page {
  background-color: #f4f4f4;
  padding-bottom: 0px;
  .myrelease-module {
    margin: 0px;
    padding-bottom: 40px;
    max-width: calc(100vw - 323px);
    border-radius: 10px;
    background-color: white;
    @media (max-width: 767px) {
      max-width: 100%;
      border-radius: 0px;
      background-color: $grey-3;
    }
    .title {
      color: $title-color;
      font-size: 16px;
      font-weight: 500;
      border-bottom: 1px solid $grey-3;
      margin-bottom: 25px;
      padding: 30px;
      &::before {
        display: none;
      }
      @media (max-width: 767px) {
        display: none;
      }
    }
    .p-datatable-body {
      .action-table {
        .btn-action {
          border-radius: 6px !important;
        }
      }
    }
    .table-list {
      display: flex;
      flex-wrap: wrap;
      margin-left: 10px;
      padding-left: 30px;
      padding-right: 30px;
      @media (max-width: 767px) {
        margin-left: 0px;
        padding-left: 0px;
        padding-right: 0px;
      }
      table {
        tbody {
          tr {
            td {
              &:first-child {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    .search-myrelease {
      padding-left: 30px;
      padding-right: 30px;
      margin-bottom: 40px;
    }
  }
  .admin-pagination {
    margin: 0px 0px 0px 30px;
  }
}

.table-large {
  @media (max-width: 767px) {
    display: none;
  }
}

.mobile-table-item {
  margin: 20px 0;
  border-radius: 10px;
  background-color: white;
  @media (min-width: 768px) {
    display: none;
  }
  h3 {
    padding: 20px;
    border-bottom: 1px solid $border-card;
  }
  .col-12 {
    padding: 0px;
    p {
      margin-bottom: 0px;
    }
  }
  .col-6 {
    padding: 12.5px;
    label {
      font-size: 12px;
      font-weight: 500;
    }
    div {
      font-size: 14px;
      font-weight: 500;
      color: $title-color;
    }
    p {
      margin-bottom: 0px;
    }
    margin-bottom: 0px;
  }
}

.popup-myrelease-filter,
.popup-project-filter,
.popup-project-sort,
.popup-release-sort {
  &.d-none {
    display: none;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  background-color: white;
  position: absolute;
  top: 302px;
  left: 309px;
  height: 346px;
  width: 300px;
  padding: 20px 0px 5px 0px;
  box-shadow: $shadow-3;
  border-radius: 6px;
  font-size: 14px;
  @media (max-width: 767px) {
    top: 130px;
    left: 16px;
    max-width: calc(100vw - 32px);
  }
  &.height-fit {
    height: fit-content;
  }
  .filter-body {
    height: 271px;
    overflow-y: scroll;
    padding: 0px 20px;
    &.height-fit {
      height: fit-content;
    }
  }
  .filter-footer {
    height: 65px;
    border-top: 1px solid $border-card;
  }
  .MuiFormLabel-root {
    font-size: 14px;
    font-family: $opensans !important;
    font-weight: 600;
    color: $title-color;
    margin-top: 20px;
    &.Mui-focused {
      color: $title-color;
    }
  }
  .MuiFormControlLabel-root {
    margin-bottom: -3px;
  }
  .MuiTypography-body1 {
    font-family: $opensans !important;
  }
  .button {
    display: flex;
    justify-content: flex-end;
    padding-right: 5px;
  }
  button {
    width: 90px;
    border-radius: 6px;
    font-family: $robotosans;
    font-weight: 500;
    text-align: center;
    font-size: 14px;
    height: 35px;
    padding: 5px;
    margin-top: 15px;
    margin-right: 10px;
    box-shadow: 0 0 0 0 !important;
    border: 0px !important;
    outline: 0px !important;
  }
  .reset {
    background-color: #e5eaee;
    color: #898989;
  }
}

.release {
  .mobile-table {
    margin-top: 80px;
  }
}

.popup-project-filter {
  top: 138px;
  left: 35px;
}

.popup-project-sort,
.popup-release-sort {
  height: unset;
  width: 234px;
  padding-top: 0px;
  .sort-body {
    padding: 8px 20px 20px 20px;
  }
  .button {
    display: flex;
    justify-content: center;
    padding: 0px;
    button {
      width: 194px;
      margin-right: 0px;
    }
  }
}

.search-myrelease {
  padding-left: 23px;
  @media (max-width: 767px) {
    padding: 15px 5px 15px 15px !important;
    background-color: white;
    position: fixed;
    left: 0;
    top: 66px;
    z-index: 1;
    width: 100%;
    p {
      display: none;
    }
    .p-inputtext {
      margin-bottom: 0px !important;
    }
  }
}

.popup-project-sort {
  left: 155px;
  top: 138px;
  &.invoice {
    left: 100px;
  }
}

.popup-release-sort {
  top: 302px;
  left: 435px;
  @media (max-width: 767px) {
    top: 130px;
    left: 16px;
    max-width: calc(100vw - 32px);
  }
}
.search-myrelease,
.search-list-project {
  font-size: 14px;
  .p-inputtext {
    width: 250px;
    height: 40px;
    margin-right: 20px;
    margin-bottom: 10px;
    color: $grey-5;
    border: 1px solid $grey-2;
    &:active,
    &:focus,
    &:visited,
    &:hover {
      border-color: #ced4da !important;
    }
  }
  button {
    outline: 0px;
    border: 1px solid $grey-2;
    background-color: white;
    border-radius: 10px;
    height: 40px;
    width: 40px;
    margin-right: 15px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  span {
    font-weight: 600;
  }
}

.pic-page {
  .card {
    &.card-custom {
      @media (min-width: 768px) {
        min-width: 460px;
      }
    }
  }
}
