@import "eco_variable";
* {
  box-sizing: border-box;
  @media (max-width: 767px) {
    font-size: 14px;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  font-smooth: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  color: $grey-5;
  @media (max-width: 767px) {
    font-size: 14px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $title-color;
}
.p-w-100 {
  width: 100%;
}
.p-grid {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  > [class*="p-col"],
  > .p-col {
    padding: 10px;
    @media (max-width: 767px) {
      padding: 15px;
    }
  }
  &.full-width {
    max-width: unset;
  }
}
.text-italic {
  font-style: italic;
}
$primaryColor: $primary;
.p-nogutter {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.main-content {
  padding: 50px 0 0 0;
  background-color: $grey-3;
}
.title {
  padding-bottom: 20px;
  border-bottom: 1px solid $grey-2;
  position: relative;
  &::before {
    content: "";
    width: 111px;
    height: 2px;
    background-color: $primary;
    position: absolute;
    left: 0;
    bottom: -1px;
  }
  h2 {
    font-weight: 400;
    color: $black;
    font-size: 24px;
    line-height: 20px;
    margin-bottom: 0;
    text-transform: capitalize;
  }
  &.plain {
    border-bottom: none;
    padding-bottom: 30px;
    &::before {
      display: none;
    }
  }
}

.social-media {
  margin: 20px 0px 0px 25px;
  @media (max-width: 767px) {
    margin: 10px 0 !important;
    width: 100%;
  }
  h4 {
    color: white;
    font-weight: 700;
    margin-bottom: 30px;
    @media (max-width: 767px) {
      text-align: center;
    }
  }
  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    width: 100%;
    @media (max-width: 767px) {
      display: flex;
      justify-content: center;
    }
    li {
      display: inline-block;
      margin-right: 36px;
      vertical-align: middle;
      &:last-child {
        margin-right: 0;
      }
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $white;
        border-radius: 100%;
        width: 41px;
        height: 41px;
        img {
          max-width: 21px;
          max-height: 21px;
        }
      }
    }
  }
}

.static-page-menu {
  font-family: $montserrat;
  margin-top: 50px !important;
  display: block;
  border-left: 1px solid white;
  border-right: 1px solid white;
  padding: 0px 50px;
  @media (max-width: 767px) {
    border-left: 0px;
    border-right: 0px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    margin: 13px 0px !important;
    padding: 15px 0px 5px 0px;
  }
  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    width: 100%;
    text-align: right;
    @media (max-width: 767px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    li {
      display: inline-block;
      margin-right: 15px;
      vertical-align: middle;
      @media (max-width: 767px) {
        margin: 0;
        padding: 0 0px;
        &:last-child,
        &:first-child {
          margin: 0;
          padding: 0;
        }
      }
      @media (max-width: 360px) {
        margin: 0;
        padding: 0 0px;
        text-align: center;
        &:last-child,
        &:first-child {
          margin: 0;
          padding: 0 0px;
        }
      }
      &:last-child {
        margin-right: 0;
      }
      a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: $white;
        font-size: 16px;
        margin-bottom: 10px;
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
    }
  }
}
.empty-state {
  display: flex;
  justify-content: center;
  padding: 80px 0;
  img {
    max-width: 100%;
  }
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #000000;
    margin-top: 20px;
  }
}

.slick-dots {
  li {
    button {
      &::before {
        font-size: 12px;
        color: $primary-color;
      }
    }
    &.slick-active {
      button {
        &::before {
          font-size: 12px;
          color: $primary-color;
        }
      }
    }
  }
}

.react-3d-carousel {
  .slider-container {
    .slider-content {
      .slider-single {
        .slider-left,
        .slider-right {
          > div {
            border: none;
            border-radius: 100%;
            background-color: $white;
            height: 34px;
            .fa {
              color: $title-color;
            }
          }
        }
      }
    }
  }
}

.box-content {
  background-color: $white;
  border-radius: 10px;
  min-height: 75px;
  &.table-wrapper {
    overflow: hidden;
    .end-data {
      @media (max-width: 767px) {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400 !important;
      }
    }
  }
  &.box-write {
    width: 100%;
    max-width: 850px;
    margin: 0 auto;
    .p-accordion-content {
      a {
        &.p-button {
          color: white;
        }
      }
    }
  }
}

.status {
  padding: 3px 8px;
  border-radius: 6px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  min-width: 83px;
  text-align: center;
  display: inline-block;
  text-transform: capitalize;
  &.weight-semibold {
    font-weight: 600;
  }
  &.sent,
  &.Terkirim {
    background-color: $sent-rgba;
    color: #0099ff !important;
  }
  &.on-going,
  &.new,
  &.ongoing,
  &.Berjalan {
    background-color: $status-new-02;
    color: $dark-blue-color !important;
  }
  &.done {
    background-color: $status-new;
    color: $primary-dark !important;
  }
  &.revision,
  &.Revisi {
    background-color: $rev-rgba;
    color: $text-rev !important;
  }
  &.winner,
  &.Approved,
  &.Diterima {
    background-color: #cfffec;
    color: $secondary !important;
  }
  &.published {
    background-color: #cfffec;
    color: $secondary !important;
  }
  &.Bergabung {
    background-color: $status-joined;
    color: $primary-color;
  }
  &.Baru,
  &.open {
    background-color: $status-new-03;
    color: $primary-dark;
  }
  &.Selesai,
  &.closed {
    background-color: $status-joined;
    color: $grey-6;
  }
  &.reject,
  &.Ditolak {
    background-color: $status-rejected;
    color: $text-rev !important;
  }
  &.removed {
    background-color: #ababab;
  }
  &.waiting {
    color: $waiting;
    background-color: $bg-waiting;
  }
}
.deadline {
  color: $grey-6;
  font-size: 14px;
  @media (max-width: 767px) {
    font-size: 12px !important;
  }
}

.action-table {
  .action-btn {
    width: 24px;
    height: 24px;
    border-radius: 6px;
    background-color: $action-table;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.title-section {
  h2 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    color: $title-color;
  }
  a {
    color: $primary-color;
    font-weight: 700;
  }
}

.empty-state {
  padding: 80px 0;
  display: flex;
  justify-content: center;
  img {
    margin-bottom: 10px;
  }
  p {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: $title-color;
    &.text-grey {
      color: $grey-6;
    }
  }
}
.step-add {
  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    text-align: center;
    li {
      display: inline-block;
      margin-right: 60px;
      vertical-align: middle;
      color: $grey-light;
      font-weight: 600;
      text-align: center;
      margin-bottom: 10px;
      @media (max-width: 767px) {
        margin-bottom: 0px;
      }
      &:last-child {
        margin-right: 0;
      }
      .step-number {
        width: 50px;
        height: 50px;
        margin: 0 auto 10px auto;
        border-radius: 100%;
        background-color: $border-card;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 767px) {
          background-color: $grey-1;
          height: 24px;
          width: 24px;
        }
        span {
          color: $white;
          font-size: 24px;
          @media (max-width: 767px) {
            font-size: 18px;
          }
        }
      }
      &.active {
        color: $primary;
        .step-number {
          background-color: $primary;
          span {
            color: $white;
          }
        }
      }
    }
  }
  &.preview {
    ul {
      li {
        display: inline-block;
        margin-right: 60px;
        vertical-align: middle;
        color: #c4c4c4;
        font-weight: 600;
        text-align: center;
        margin-bottom: 10px;
        position: relative;
        @media (max-width: 767px) {
          margin-right: 15px;
          max-width: 25%;
        }
        &:last-child {
          margin-right: 0;
        }
        .step-number {
          width: 50px;
          height: 50px;
          margin: 0 auto 10px auto;
          border-radius: 100%;
          background-color: #c4c4c4;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          @media (max-width: 767px) {
            width: 24px;
            height: 24px;
            font-size: 12px;
          }
          span {
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            height: 30px;
            width: 30px;
            @media (max-width: 767px) {
              width: 20px;
              height: 20px;
            }
          }
        }
        .step-date {
          font-size: 14px;
          font-weight: 400;
          @media (max-width: 767px) {
            font-size: 9px;
          }
        }
        .step-text {
          @media (max-width: 767px) {
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 9px;
          }
        }
        &.active {
          color: #2d2d2d;
          &::after {
            content: url("../../../app/assets/img/arrow-forward-blue.svg");
            top: 10px;
            right: -50%;
            position: absolute;
            @media (max-width: 767px) {
              top: 2px;
              right: -18px;
            }
          }
          &:last-child {
            &::after {
              content: "";
            }
          }
          .step-number {
            background-color: $primary;
            span {
              background-image: url("../../../app/assets/img/ic-done.svg");
            }
          }
        }
      }
    }
  }
}
.portfolio-list {
  border-top: 1px solid $border-card;
  &:last-child {
    border-top: none;
  }
  .p-button {
    height: 40px;
  }
  &.user {
    &:last-child {
      border-top: 1px solid $border-card;
    }
    a {
      font-weight: 600;
    }
    .count-folio {
      font-size: 18px;
      font-weight: 600;
      border-bottom: 1px solid $border-card;
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
    .p-button {
      border-radius: 40px !important;
    }
  }
}

.text-helper-italic {
  color: #808080;
  font-size: 12px;
  margin-top: 5px;
  font-style: italic;
}

.text-success {
  color: #17ad49 !important;
}

.p-accordion {
  .p-accordion-tab {
    .p-accordion-header {
      border-bottom: 1px solid $border-card;
      .p-accordion-header-link {
        border: none !important;
        border-radius: 0 !important;
        background-color: $white !important;
        padding: 20px !important;
        position: relative;
        font-size: 18px !important;
        &:focus {
          outline: 0 none !important;
          box-shadow: none !important;
        }
        .p-accordion-toggle-icon {
          position: absolute;
          top: 20px;
          right: 20px;
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          transform: rotate(90deg);
        }
      }
    }
    &.p-accordion-tab-active {
      .p-accordion-header-link {
        .p-accordion-toggle-icon {
          -webkit-transform: rotate(-180deg);
          -moz-transform: rotate(-180deg);
          transform: rotate(-180deg);
        }
      }
    }
  }
  .p-accordion-content {
    border: none !important;
  }
}
.dropdown-filter {
  &.btn {
    &.btn-primary {
      padding: 0;
      background-color: transparent !important;
      border: none;
      &:hover {
        background-color: transparent !important;
      }
      &::after {
        display: none;
      }
    }
  }
}
.detail-user {
  width: 40%;
  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 30px;
    li {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
      label {
        display: block;
        color: $grey-6;
        font-weight: 500;
        font-size: 18px;
      }
      span {
        color: $title-color;
        font-weight: 500;
        font-size: 18px;
        &.total {
          width: 245px;
        }
      }
    }
  }
}

.clear-box-inner {
  margin: 0 -29px;
  border-top: 1px solid $border-card;
  .p-accordion {
    .p-accordion-tab {
      .p-accordion-header {
        border-bottom: 1px solid $border-card;
        .p-accordion-header-link {
          padding-left: 29px !important;
          padding-right: 29px !important;
        }
      }
    }
  }
  .method-wrapper {
    padding: 20px 29px;
    label {
      font-size: 18px;
    }
    span {
      font-size: 18px;
    }
  }
  .button-wrapper {
    padding: 20px 29px;
  }
}

.decline-form {
  p {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: $grey-6;
  }
}

small {
  &.p-error {
    font-size: 12px !important;
    color: #dc3545 !important;
    font-weight: 500 !important;
  }
}

.box-input-file-error {
  font-size: 12px !important;
  &.danger {
    color: #dc3545 !important;
  }
}

.preview-image {
  width: 20%;
  img {
    height: 185px;
    width: 185px;
    border-radius: 6px;
    margin: 0px 0px 6px 0px;
    object-fit: cover;
    border: 1px solid $border-card;
  }
  span {
    margin-left: 5px;
    color: $grey-6;
  }
  h4 {
    font-size: 18px;
    font-weight: 600;
    color: $grey-6;
    margin-bottom: 10px;
  }
}

.content-dashboard {
  @media (min-width: 1019.9px) {
    min-height: calc(100vh - 120px);
  }
}

.status-project {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 5px 20px;
  font-size: 12px;
  font-weight: 600;
  width: fit-content;
  min-width: 120px;
  text-transform: capitalize;
  &.done {
    background-color: #cfffec;
    color: $secondary !important;
  }
  &.ongoing {
    background-color: $status-new-02 !important;
    color: $dark-blue-color !important;
  }
}

a.link {
  font-size: 14px;
  font-weight: 600;
  color: #0099ff;
  text-decoration: none;
  text-transform: capitalize;
  &:hover {
    opacity: 0.9;
  }
}
