.static-page,
.faq-page {
  background-color: white;
  .faq-module {
    min-height: calc(100vh - 433.5px);
    @media (max-width: 767px) {
      margin-top: 65px;
      padding-top: 15px;
    }
    .box-search {
      width: 50%;
      margin: 0 auto 40px;
      @media (max-width: 767px) {
        width: 100%;
        margin: 0 auto 20px;
        .p-input-icon-right {
          width: 100% !important;
          background-color: #fff;
        }
      }
    }
  }
  .tab-project {
    .p-tabview-nav {
      margin-bottom: 30px;
      @media (max-width: 320px) {
        li {
          padding-left: 0px;
          padding-right: 0px;
        }
      }
    }
  }
  h1 {
    font-size: 32px;
    font-weight: 600;
    margin: 50px 0px 50px 0px;
    text-align: center;
    @media (max-width: 320px) {
      margin: 0px 0px 20px 0px;
      font-size: 24px;
    }
  }
  .p-accordion {
    .p-accordion-tab {
      font-family: $opensans;
      border: 1px solid $border-card;
      border-radius: 12px;
      margin-bottom: 30px;
      padding: 30px !important;
      .p-accordion-header {
        border-bottom: 0px;
        .p-accordion-header-text {
          font-size: 18px;
          @media (max-width: 320px) {
            font-size: 16px;
          }
        }
        .p-accordion-header-link {
          border-radius: 10px !important;
          padding: 0px !important;
          .p-accordion-toggle-icon {
            top: 0px;
          }
        }
      }
      .p-accordion-content {
        padding: 30px 0px 0px 0px;
        font-size: 13px;
        color: $grey-6;
      }
    }
  }
}
