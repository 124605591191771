.empty-help-list {
  width: calc(100vw - 315px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-top: 75px;
  img {
    height: 210px;
    margin-bottom: 30px;
  }
  h3 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
  }
  p {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.ticket-item {
  cursor: pointer;
  margin-bottom: 40px;
  padding: 20px 30px 20px 25px;
  border: 1px solid $border-card;
  border-left: 5px solid;
  &.project {
    border-left: 5px solid $verified;
  }
  &.billing {
    border-left: 5px solid $status-new;
  }
  &.general {
    border-left: 5px solid $waiting;
  }
  h3 {
    font-size: 1.275rem;
    font-weight: 700;
    margin-bottom: 15px;
  }
  span {
    text-transform: capitalize;
  }
}

.hint {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: $primary-color;
  background-color: #e7fafd;
  width: 250px;
  border-radius: 6px;
  padding: 10px 7px 10px 6px;
  height: 45px;
  margin-bottom: 0px;
  &.large {
    height: unset;
  }
  @media (max-width: 767px) {
    display: none;
  }
  &.add-help {
    width: 380px;
    height: unset;
    flex-direction: column;
    .tail {
      top: -5px;
      right: 28px;
      left: unset;
    }
  }
  p {
    margin-bottom: 0px;
  }
  svg {
    margin-left: 20px;
    cursor: pointer;
  }
  .tail {
    height: 14px;
    width: 14px;
    background-color: #e7fafd;
    position: absolute;
    left: -7px;
    top: 15px;
    transform: rotate(45deg);
    &.top {
      top: -6px;
      left: 20px;
    }
  }
  &.hide {
    height: 0px;
  }
  &.d-block {
    display: block;
  }
}

.help-detail-header {
  .detail-title {
    font-weight: 500;
    font-size: 16px;
    margin-right: 33px;
    display: flex;
    align-items: center;
  }
  .p-button {
    margin-right: 20px;
    height: 40px;
  }
}

.help-detail-content {
  .help-detail-question {
    border: 1px solid $border-card;
    margin: 30px 0px;
    .ticket-item {
      margin-bottom: 0px;
    }
    .question {
      min-height: 115px;
      color: $title-color;
      padding: 20px 30px;
    }
  }
}

.form-add-help {
  .p-dropdown {
    height: 40px;
    .p-dropdown-label {
      height: 40px;
      display: flex;
      align-items: center;
      text-transform: capitalize;
    }
    .p-dropdown-item {
      text-transform: capitalize;
    }
  }
}

.card {
  &.card-custom {
    &.add-help {
      min-height: calc(100vh - 169px);
    }
    &.list-pic {
      @media (max-width: 767px) {
        border-radius: 0px;
        .card-header {
          display: none;
        }
      }
    }
  }
}
