.static-page {
  min-height: 100vh;
  background-color: white;
  .static-content-wrapper {
    background-color: $white;
    min-height: calc(100vh - 433.5px);
    width: 100%;
    max-width: 1024px;
    margin: 40px auto;
    padding: 30px 40px;
    @media (max-width: 767px) {
      padding: 15px 20px;
    }
    .title-static {
      width: 100%;
      text-align: center;
      margin-bottom: 40px;
      h1 {
        font-size: 24px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 0;
      }
    }
    .body {
      .image-banner {
        max-width: 100%;
        margin-bottom: 20px;
        max-height: 400px;
      }
      img {
        max-width: 100%;
        height: auto;
        margin-bottom: 10px;
      }
      p {
        margin-bottom: 7px;
        color: #2d2d2d;
        font-size: 14px;
      }
      ul,
      ol {
        li {
          color: #2d2d2d;
          font-size: 14px;
          margin-bottom: 5px;
        }
      }
    }
    @media (max-width: 767px) {
      margin-top: 40px;
      .title-static {
        margin-bottom: 25px;
        h1 {
          font-size: 18px;
        }
      }
      .body {
        p {
          font-size: 14px;
        }
      }
    }
  }

  .about-us {
    background-color: $white !important;
    width: 60vw;
    margin: 5vw 20vw 5vw 20vw;
    padding: 2vw 5vw 10vw 5vw;
    text-align: center;
    h1 {
      margin-bottom: 20px;
    }
  }

  .privacy-policy {
    background-color: $white !important;
    width: 60vw;
    margin: 5vw 20vw 5vw 20vw;
    padding: 2vw 5vw 10vw 5vw;
    text-align: center;
    h1 {
      margin-bottom: 20px;
    }
  }

  .terms {
    background-color: $white !important;
    width: 60vw;
    margin: 5vw 20vw 5vw 20vw;
    padding: 2vw 5vw 10vw 5vw;
    text-align: center;
    h1 {
      margin-bottom: 20px;
    }
  }
}
.loading-text {
  margin-top: 30px;
  @media (max-width: 767px) {
    margin-top: 65px;
    padding: 20px 15px;
  }
}
