.header-landing {
  &.main {
    padding: 20px 63px;
  }
  .p-button {
    font-weight: 600 !important;
    padding: 8px 22px !important;
    font-size: 16px !important;
    &.btn-wide {
      @media (max-width: 767px) {
        width: calc((100vw - 30px) / 2 - 6px);
        margin-right: 0px;
      }
    }
  }
  @media (max-width: 767px) {
    width: 100vw;
    height: 50px;
    padding: 0px 9px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    border-bottom: 1px solid #f4f4f4;
    .p-button {
      display: none;
    }
    .items {
      max-width: calc(100vw - 50px);
    }
  }
  background-color: $white;
  padding: 20px 0;
  .p-grid {
    padding-left: 12px;
    padding-right: 12px;
  }
  .header-user {
    max-width: 1360px !important;
    @media (max-width: 1281px) {
      max-width: 1024px !important;
    }
    @media (max-width: 767px) {
      padding: 0;
      display: flex;
      align-items: center;
    }
  }
  .menu {
    margin-left: 40px;
    .active {
      color: $primary-color;
    }
    a {
      padding: 10px 10px;
      margin-right: 55px;
      display: inline-block;
      color: #555555;
      font-weight: 600;
      font-size: 16px;
      @media (max-width: 767px) {
        font-size: 14px !important;
      }
      &:last-child {
        margin-right: 0;
      }
      &.active {
        color: $primary;
      }
    }
  }
  .notif {
    margin-right: 20px;
  }
  .user-pict {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 20px;
    position: relative;
    @media (max-width: 767px) {
      position: relative;
      top: 3px;
      height: 26px;
      width: 26px;
    }
    img {
      min-width: 100%;
      height: 32px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      @media (max-width: 767px) {
        position: relative;
        top: 0.5px;
        height: 26px;
        width: 26px;
      }
    }
    .letter-pics {
      width: 32px;
      height: 32px;
      background-color: $primary;
      color: #fff;
      font-size: 24px;
      font-weight: 600;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $robotosans;
      @media (max-width: 767px) {
        width: 27px;
        height: 27px;
        font-size: 16px;
      }
    }
  }
  .saldo-insentive {
    font-size: 14px;
    line-height: 19px;
    border-radius: 6px;
    padding: 5px 8px;
    color: $primary;
    border: 1px solid $primary;
    @media (max-width: 767px) {
      display: none;
    }
  }
  .navbar {
    padding: 0px;
    &.navbar-expand-lg {
      @media (max-width: 767px) {
        max-width: 50vw;
        display: none;
      }
    }
    .nav-link {
      padding: 0px;
    }
    a {
      color: $grey-6;
    }
    .navbar-brand {
      position: relative;
      top: -3px;
      padding: 0px;
      margin: 0px;
    }
    @media (max-width: 767px) {
      display: flex;
      flex-direction: row-reverse;
      position: relative;
      top: 2px;
      justify-content: flex-end;
      .navbar-brand {
        top: 0px;
        .header-logo {
          img {
            position: relative;
            top: 0;
            left: 0;
          }
        }
      }
      .nav-link {
        position: relative;
        left: -43px;
      }
    }
  }
  .p-ai-center {
    @media (max-width: 767px) {
      .icon-notif {
        position: relative;
        top: 8px;
      }
    }
  }
  .dropdown {
    @media (max-width: 767px) {
      margin-right: 0px !important;
    }
    .dropdown-toggle {
      &.btn {
        &.btn-success {
          background-color: transparent;
          padding: 0;
          border: none;
          &::after {
            display: none;
          }
          &:hover,
          &:focus {
            background-color: transparent;
            border: none;
          }
        }
      }
    }

    .dropdown-menu {
      min-width: 241px;
      @media (max-width: 767px) {
        &.show {
          position: absolute !important;
          left: 0 !important;
          top: 10px !important;
        }
      }
      &.dropdown-user {
        @media (max-width: 767px) {
          transform: translate3d(-195px, 27.6667px, 0px) !important;
        }
      }
      &.dropdown-noty {
        @media (max-width: 767px) {
          transform: translate3d(-195px, 27.6667px, 0px) !important;
        }
        height: 320px;
        overflow-y: auto;
        .empty-notification {
          height: 230px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-weight: 700;
          img {
            height: 100px;
            margin-bottom: 15px;
          }
          span {
            margin-left: 10px !important;
          }
        }
      }
      .dropdown-item {
        padding-left: 30px;
        padding-right: 30px;
        .user-box {
          &::after {
            content: "";
            display: block;
            clear: both;
          }
          .user-pict {
            float: left;
            margin-right: 8px;
          }
          .summary {
            overflow: hidden;
            width: 140px;
            .user-name {
              font-size: 14px;
              font-weight: 600;
              color: $title-color;
              text-overflow: ellipsis;
            }
            .user-role {
              color: $grey-6;
              font-size: 12px;
            }
          }
        }
        > span {
          margin-right: 10px;
        }
      }
    }
  }
  .dropdown {
    margin-right: 20px;
    .btn.btn-success:not(:disabled):not(.disabled):active:not(.btn-text),
    .btn.btn-success:not(:disabled):not(.disabled).active,
    .show > .btn.btn-success.dropdown-toggle,
    .show .btn.btn-success.btn-dropdown {
      background-color: transparent;
      border-color: transparent;
    }
    .dropdown-toggle {
      background-color: transparent;
      border-color: transparent;
      position: relative;
      &:hover {
        background-color: transparent !important;
        border-color: transparent !important;
      }
    }
    &.has-noty {
      &::before {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: $danger;
        position: absolute;
        top: -1px;
        right: 21px;
        z-index: 1;
        @media (max-width: 767px) {
          top: 10px;
          right: 17px;
        }
      }
    }
    .dropdown-toggle.nav-link:after,
    .dropdown-toggle.btn:after {
      display: none;
    }
    .dropdown-menu {
      min-width: inherit;
      width: 241px;
      right: 21px !important;
      .title-menu {
        padding: 10px;
        font-size: 12px;
      }
      .dropdown-item {
        white-space: inherit;
        &.active {
          background-color: $primary-rgba;
        }
        .notif-box {
          .date {
            color: $grey-6;
            margin-bottom: 10px;
            font-size: 12px;
            display: block;
          }
          .title-box {
            font-size: 12px;
            font-weight: 600;
            color: $title-color;
            display: block;
          }
          .desc {
            color: $title-color;
            font-size: 12px;
          }
        }
      }
      &.dropdown-noty {
        height: 320px;
        overflow-y: auto;
      }
    }
  }
}

.header-logo {
  display: block;
  width: 135px;
  height: 45px;
  overflow: hidden;
  margin: 0 0;
  position: relative;
  img {
    width: unset;
    height: 45px;
    position: absolute;
    left: 0;
  }
  @media (max-width: 767px) {
    height: 35px;
    width: 76px;
    left: 12px;
    top: 2px;
    img {
      height: 35px;
    }
  }
}
.header-user-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .topbar-hr {
    content: "";
    height: 30px;
    width: 2px;
    background-color: #dee2e6;
    margin: 0 8px;
  }
  .dropdown {
    position: relative;
    &.notification {
      width: 40px;
      position: relative;
      text-align: center;
      margin-right: 0;
      .dropdown-menu {
        right: 0 !important;
      }
    }
  }
}

// Only Mobile Header Style
.header-mobile-wrapp {
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.header-mobile-content {
  width: 100%;
  background-color: #fff;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f4f4f4;
  padding: 10px 15px;
  .menu-toggler {
    display: flex;
    width: 40px;
    justify-content: flex-start;
    align-items: center;
  }
  .btn-icon-outline {
    height: 40px;
    width: 40px;
    position: relative;
    @media (max-width: 767px) {
      height: 50px;
      width: 50px;
    }
    .icon-menu {
      height: 30px;
      width: 45px;
    }
    .icon-notif {
      height: 24px;
      width: 24px;
      @media (max-width: 767px) {
        height: 34px;
        width: 38px;
      }
    }
    .dot-noty {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: red;
      position: absolute;
      top: 5px;
      right: 5px;
      @media (max-width: 767px) {
        top: 9px;
        right: 13px;
      }
    }
  }
  .header-mobile-logo {
    height: 50px;
    width: 133px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    img {
      width: auto;
      height: 50px;
    }
  }
  .header-mobile-right {
    min-width: 40px;
  }
}
.header-mobile-menu {
  width: 100%;
  background-color: #fff;
  height: calc(100vh - 65px);
  position: relative;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 2;
  .auth-menu-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-top: 1px solid #eaeaea;
    padding-top: 15px;
    margin-top: 15px;
    a {
      text-decoration: none;
      display: block;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        font-size: 14px;
        font-weight: 600;
        color: #555555;
        &.active {
          color: $primary-color;
        }
      }
    }
  }
  .main-menu-mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    a {
      text-decoration: none;
      display: block;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        font-size: 14px;
        font-weight: 600;
        color: #555555;
        &.active {
          color: $primary-color;
        }
      }
    }
  }
  .auth-box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid #f4f4f4;
    margin-bottom: 20px;
    .auth-img {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      overflow: hidden;
      margin-right: 10px;
      position: relative;
      img {
        min-width: 100%;
        height: 40px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .auth-profile {
      width: calc(100% - 60px);
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .auth-name {
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        color: #2d2d2d;
        margin-bottom: 0;
        > span {
          color: #9c9c9c;
          font-weight: 400;
          margin-left: 15px;
        }
      }
      .auth-link {
        font-size: 14px;
        font-weight: 600;
        padding-right: 10px;
        &.logout {
          color: #ea4946;
          padding-left: 10px;
        }
      }
      .dot {
        height: 15px;
        width: 1px;
        border-radius: 50%;
        background-color: #808080;
      }
    }
  }
}
.notification-mobile {
  width: 100%;
  height: calc(100vh - 66px);
  position: fixed;
  background-color: #fff;
  top: 66px;
  left: 0;
  padding: 20px 0;
  h4 {
    font-size: 16px;
    color: #2d2d2d;
    font-weight: 600;
    padding: 0 15px;
  }
  .notification-content {
    width: 100%;
    padding-top: 20px;
    height: calc(100vh - 120px);
    overflow-y: auto;
  }
  .notification-box {
    border-bottom: 1px solid #eaeaea;
    background-color: #fff;
    padding: 20px 15px;
    &.active {
      background-color: $primary-rgba;
    }
    &:last-child {
      border-bottom: unset;
    }
    .date {
      font-size: 12px;
      color: #808080;
      margin-bottom: 0;
      font-weight: 400;
      width: 100%;
      text-align: end;
    }
    .notif-title {
      font-size: 14px;
      font-weight: 600;
      color: #2d2d2d;
      margin-bottom: 5px;
    }
    .notif-text {
      font-size: 12px;
      color: #2d2d2d;
      margin-bottom: 0;
      font-weight: 400;
    }
  }
  .empty-notification {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    height: 400px;
    img {
      height: 150px;
      margin-bottom: 15px;
    }
    span {
      margin-left: 10px !important;
      font-size: 12px;
    }
  }
}
