@import "eco_variable";

.p-datatable {
  table {
    .p-datatable-thead {
      tr {
        th {
          border-top: none;
          border-bottom: 0;
          .p-column-title {
            color: $grey-6;
          }
        }
        td {
          border-color: $border-card;
        }
      }
    }
  }
}

.table-invoice {
  padding: 20px 0;
  .table-row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eaeaea;
    &.table-header {
      border-top: 1px solid #eaeaea;
      border-bottom: 2px solid #eaeaea;
      .table-data {
        height: 45px;
        color: #2d2d2d;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 16px;
      }
    }
    &.table-footer {
      border-top: unset;
      border-bottom: unset;
      .table-data {
        color: #2d2d2d;
        font-weight: 500;
        font-size: 14px;
      }
    }
    .table-data {
      height: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      color: #808080;
      font-weight: 500;
      &.jc-end {
        justify-content: flex-end !important;
      }
      &:nth-child(1) {
        width: 40%;
      }
      &:nth-child(2) {
        width: 20%;
      }
      &:nth-child(3) {
        width: 20%;
      }
      &:nth-child(4) {
        width: 20%;
      }
    }
  }
}
